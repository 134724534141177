import { MouseEventHandler } from 'react';
import { Link, Toggle } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';

import { LikesTooltip } from 'Editor/components';
import { LikeParent } from '_common/modals/LikesModal/LikesModal';

import styles from './LikeToggle.module.scss';
import { openModal } from 'App/ModalContext/utils';

export type LikeToggleProps = {
  votes: Card.Vote[];
  replyId?: Card.Reply['id'];
  target: LikeParent;
  onVote: (payload: { replyId?: string; currentUserLiked: boolean }) => void;
  disabled?: boolean;
  legacyModal?: boolean;
  testId: string;
};

const LikeToggle = ({
  votes,
  replyId,
  target,
  onVote,
  disabled,
  legacyModal,
  testId,
}: LikeToggleProps) => {
  const currentUserId = useSelector((state) => state.auth.userId);

  const likedVotes: Card.Vote[] = votes
    .filter((vote) => vote.value)
    .map((vote) => ({
      time: '',
      user: vote.user,
      value: vote.value,
    }));

  const currentUserLiked = !!likedVotes.find((vote) => vote.user === currentUserId);

  const handleLikeReply: MouseEventHandler<HTMLSpanElement> = (e) => {
    e.stopPropagation();

    onVote({ replyId, currentUserLiked });
  };

  return (
    <div className={styles.likeCounter}>
      <Toggle
        size="medium"
        variant="link"
        icon={currentUserLiked ? 'LikeBlue' : 'LikeGrey'}
        isToggled={currentUserLiked}
        testId={`${testId}-like-toggle`}
        onClick={handleLikeReply}
        disabled={disabled}
      />
      {likedVotes.length > 0 && (
        <LikesTooltip votes={likedVotes} testId={`${testId}-likes-tooltip`}>
          <span className={styles.counter}>
            <Link
              onClick={() =>
                openModal({
                  modal: 'TotalUsersModal',
                  data: {
                    users: likedVotes
                      .sort((a, b) => new Date(a.time).getDate() - new Date(b.time).getDate())
                      .map((vote) => vote.user),
                    elementType: target,
                    action: 'likes',
                    legacy: legacyModal,
                  },
                })
              }
              variant="neutral"
              testId={`${testId}-likes-link`}
            >
              {likedVotes.length}
            </Link>
          </span>
        </LikesTooltip>
      )}
    </div>
  );
};

export default LikeToggle;
