import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown, Tooltip } from 'dodoc-design-system';
import type { DropdownProps } from 'dodoc-design-system/build/types/Components/Dropdown/Dropdown';
import { useDispatch, useSelector } from '_common/hooks';
import { setCreating } from 'Presentation/PresentationSlice';
import { useSuitePermissions } from '_common/suite';
import { InteractionController } from '_common/components';
import { useEffect } from 'react';
import { completeAction, setPulseData } from 'App/redux/onboardingSlice';
import { usePresentationManager } from 'Presentation/PresentationManager';
import { useSlideId } from 'Presentation/hooks';

type SlideContextMenuProps = { popperProps: Omit<DropdownProps, 'children' | 'width' | 'testId'> };

const SlideContextMenu = ({ popperProps }: SlideContextMenuProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { canComment, canCreateTask } = useSuitePermissions();
  const manager = usePresentationManager();
  const currentSlideId = useSlideId();

  const selectedShape = useSelector((state) => state.presentation.general.selectedShape);

  const handleNewCard = (type: 'task' | 'comment') => {
    manager.restoreSelection();

    if (selectedShape) {
      const baseAnchor: Presentation.Common.PresentationAnchor = selectedShape
        ? [{ id: currentSlideId }, { id: selectedShape }]
        : [{ id: currentSlideId }];
      //If returned anchor is null, means there is no range, so generating a shape anchor
      const anchor = manager.getPresentationAnchor() ?? baseAnchor;

      dispatch(
        setCreating({
          slide: false,
          type,
          anchor,
        }),
      );

      if (type === 'task') {
        dispatch(completeAction('presentation_tasks_openTask'));
      }
    } else {
      dispatch(
        setCreating({
          slide: true,
          type,
        }),
      );
    }
  };

  const handleNewTask = () => handleNewCard('task');
  const handleNewComment = () => handleNewCard('comment');

  useEffect(() => {
    dispatch(setPulseData({ contextMenuIsOpen: popperProps.isOpen }));
  }, [popperProps.isOpen]);

  return (
    //@ts-expect-error Dropdown type doesnt accept "id"
    <Dropdown {...popperProps} id="slide-context-menu" testId="slide-context-menu">
      <InteractionController environment="presentation" style={{ width: '100%' }}>
        <Tooltip
          content={intl.formatMessage({ id: 'NO_PERMISSION_TO_PERFORM_ACTION' })}
          placement="right"
          disabled={canComment}
          testId="comment-dropdown-item-tooltip"
        >
          <div style={{ width: '100%' }}>
            <Dropdown.Item
              disabled={!canComment}
              size="medium"
              onClick={handleNewComment}
              testId="comment-dropdown-item"
            >
              <FormattedMessage id="NEW_COMMENT" />
            </Dropdown.Item>
          </div>
        </Tooltip>
      </InteractionController>
      <InteractionController
        environment="presentation"
        style={{ width: '100%' }}
        rules={[
          {
            interaction: 'presentation_slide_contextMenu_task',
            preconditions: ['presentation_tasks_selection'],
          },
        ]}
      >
        <Tooltip
          content={intl.formatMessage({ id: 'NO_PERMISSION_TO_PERFORM_ACTION' })}
          placement="right"
          disabled={canCreateTask}
          testId="task-dropdown-item-tooltip"
        >
          <div style={{ width: '100%' }} id="slide-contextMenu-new-task-item">
            <Dropdown.Item
              disabled={!canCreateTask}
              size="medium"
              onClick={handleNewTask}
              testId="task-dropdown-item"
            >
              <FormattedMessage id="NEW_TASK" />
            </Dropdown.Item>
          </div>
        </Tooltip>
      </InteractionController>
    </Dropdown>
  );
};

export default SlideContextMenu;
