import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ColumnProps } from 'dodoc-design-system/build/types/Components/Table/Table';

import styles from './SessionTable.module.scss';
import Cell from '_common/components/Table/Cells';
import { Table } from '_common/components/Table';
import { useListSessionsQuery } from '../../UserSettingsApi';
import { Label } from 'dodoc-design-system';
import MoreOptionsCell from '../../Cells/MoreOptionsCell';
import { selectAccounts } from 'Auth/redux/localStorageSlice';
import { useSelector } from '_common/hooks';
import UAParser from 'ua-parser-js';

const SessionTable = () => {
  const intl = useIntl();
  const userId = useSelector((state) => state.auth.userId);
  const accounts = useSelector(selectAccounts);

  const { data: sessions, isFetching } = useListSessionsQuery();

  const columns = useMemo<ColumnProps[]>(() => {
    return [
      {
        id: 'platform',
        header: intl.formatMessage({
          id: 'settings.security.platform',
        }),
        width: 240,
      },
      {
        id: 'ipAdress',
        header: intl.formatMessage({
          id: 'settings.security.ipAdress',
        }),
        width: 240,
      },
      {
        id: 'expire',
        header: intl.formatMessage({
          id: 'settings.security.expires',
        }),
        width: 240,
      },
      {
        id: 'current',
        width: 200,
      },
    ];
  }, []);

  const parsePlatformValue = (platform: string | undefined) => {
    const parser = new UAParser();
    if (platform) {
      parser.setUA(platform);
    }
    const result = parser.getResult();
    const str = intl.formatMessage(
      { id: 'settings.security.on' },
      {
        operatingSystem: result.os.name,
        browser: result.browser.name,
        version: result.browser.version,
      },
    );

    return str;
  };

  const value = useMemo(() => {
    return sessions?.map((session) => {
      return {
        id: session.token,
        platform: (
          <Cell testId={`${session.token}-platform-column`} ellipsis>
            {parsePlatformValue(session.last_user_agent)}
          </Cell>
        ),
        ipAdress: (
          <Cell testId={`${session.token}-ip-adress-column`}>
            <div>{session.last_ip_address}</div>
          </Cell>
        ),
        expire: (
          <Cell testId={`${session.token}-expire-column`}>
            <Cell.DateTime date={{ date: session.expires }} time={{ time: session.expires }} />
          </Cell>
        ),
        current: session.token === accounts[userId].token && (
          <Cell testId={`${session.token}-current-column`}>
            <Label size="small" fullWidth color="blue" testId="current-label">
              <span style={{ textTransform: 'uppercase' }}>
                <FormattedMessage id="global.current" />
              </span>
            </Label>
          </Cell>
        ),
        suffix: (
          <Cell testId={`${session.token}-suffix-column`}>
            <MoreOptionsCell group="session" data={session} />
          </Cell>
        ),
      };
    });
  }, [sessions]);

  return (
    <div className={styles.root}>
      <Table
        identity="userPreferences/security_session"
        columns={columns}
        value={value}
        renderFooter={() => null}
        isLoading={isFetching}
        loadingLabel={intl.formatMessage({ id: 'LOADING_ELEMENTS' })}
        testId="userPreferences_security_session"
        selectable={false}
        virtualized={false}
      />
    </div>
  );
};

export default SessionTable;
