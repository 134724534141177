import React, { useState, useRef, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useDispatch, useSelector } from '_common/hooks';
import { closeModal } from '_common/modals/ModalsSlice';

import { Button, Modal, Input, Divider } from 'dodoc-design-system';
import styles from './KeyboardShortcutsModal.module.scss';
import { ColumnProps, TableProps } from 'dodoc-design-system/build/types/Components/Table/Table';
import { ThunksTable } from '_common/components/Table';
import Cell from '_common/components/Table/Cells/Cell';

/**
 * COMMON_ACTIONS
 * Copy [Ctrl + C] [⌘ + C]
 * Cut [Ctrl + X] [⌘ + X]
 * Paste [Ctrl + V] [⌘ + V]
 * Paste without formatting [Ctrl + Shift + V] [⌘ + Shift + V]
 * Undo [Ctrl + Z] [⌘ + Z]
 * Redo [Ctrl + Shift + Z] [⌘ + Shift + Z]
 * Save as [Ctrl + Shift + S] [⌘ + Shift + S]
 * Edit link [Ctrl + K] [⌘ + K]
 * Open Link [Alt + Enter] [Option + Enter]
 * Find [Ctrl + F] [⌘ + F]
 * Find and replace [Ctrl + H] [⌘ + H]
 * Find again [Ctrl + G] [⌘ + G]
 * Find previous [Ctrl + Shift + G] [⌘ + Shift + G]
 * Repeat last action [Ctrl + Y] [⌘ + Y]
 * Select all [Ctrl + A] [⌘ + A]
 */

/**
 * Bold [Ctrl + B] [⌘ + B]
 * Italic [Ctrl + I] [⌘ + I]
 * Underline [Ctrl + U] [⌘ + U]
 * Strikethrough [Alt + Shift + 5] [⌘ + Shift + X]
 * Superscript [Ctrl + Shift + =] [⌘ + Shift + =]
 * Subscript [Ctrl + =] [⌘ + =]
 * Copy text formatting [Ctrl + Alt + C] [⌘ + Option + C]
 * Paste text formatting [Ctrl + Alt + V] [⌘ + Option + V]
 * Clear text formatting [Ctrl + \] [⌘ + \]
 */

/**
 * Apply heading style 1-6 [Ctrl + Alt + (1-6)] [⌘ + Option + (1-6)]
 * Left align [Ctrl + L] [⌘ + L]
 * Center align [Ctrl + E] [⌘ + E]
 * Right align [Ctrl + R] [⌘ + R]
 * Justify [Ctrl + J] [⌘ + J]
 * Numbered list [Ctrl + Shift + 7] [⌘ + Shift + 7]
 * Bullet List [Ctrl + Shift + 8] [⌘ + Shift + 8]
 */

/**
 * Insert footnote [Ctrl + Alt + F] [⌘ + Option + F]
 * Insert link [Ctrl + K] [⌘ + K]
 * Insert page break [Ctrl + Enter] [⌘ + Enter]
 */

const SHORTCUT_GROUPS = [
  {
    label: 'COMMON_ACTIONS',
    shortcuts: [
      { label: 'editor.menu.edit.copy', win: 'Ctrl + C', mac: '⌘ + C' },
      { label: 'editor.menu.edit.cut', win: 'Ctrl + X', mac: '⌘ + X' },
      { label: 'editor.menu.edit.paste', win: 'Ctrl + V', mac: '⌘ + V' },
      /* {
        label: 'editor.menu.edit.pasteWithoutFormatting',
        win: 'Ctrl + Shift + V',
        mac: '⌘ + Shift + V',
      }, */
      { label: 'editor.menu.edit.undo', win: 'Ctrl + Z', mac: '⌘ + Z' },
      { label: 'editor.menu.edit.redo', win: 'Ctrl + Shift + Z', mac: '⌘ + Shift + Z' },
      { label: 'SAVE_VERSION', win: 'Ctrl + S', mac: '⌘ + S' },
      /* { label: 'EDIT_LINK', win: 'Ctrl + K', mac: '⌘ + K' }, */
      { label: 'FIND', win: 'Ctrl + F', mac: '⌘ + F' },
      { label: 'FIND_NEXT', win: 'Ctrl + G', mac: '⌘ + G' },
      { label: 'FIND_PREVIOUS', win: 'Ctrl + Shift + G', mac: '⌘ + Shift + G' },
      /* { label: 'REPEAT_LAST_ACTION', win: 'Ctrl + Y', mac: '⌘ + Y' }, */
      { label: 'editor.menu.edit.selectAll', win: 'Ctrl + A', mac: '⌘ + A' },
    ],
  },
  {
    label: 'TEXT_FORMATTING',
    shortcuts: [
      { label: 'editor.menu.format.bold', win: 'Ctrl + B', mac: '⌘ + B' },
      { label: 'editor.menu.format.italic', win: 'Ctrl + I', mac: '⌘ + I' },
      { label: 'editor.menu.format.underline', win: 'Ctrl + U', mac: '⌘ + U' },
      /* { label: 'editor.menu.format.strikethrough', win: 'Alt + Shift + 5', mac: '⌘ + Shift + X' },
      { label: 'editor.menu.format.superscript', win: 'Ctrl + Shift + =', mac: '⌘ + Shift + =' },
      { label: 'editor.menu.format.subscript', win: 'Ctrl + =', mac: '⌘ + =' },
      { label: 'COPY_TEXT_FORMATTING', win: 'Ctrl + Alt + C', mac: '⌘ + Option + C' },
      { label: 'PASTE_TEXT_FORMATTING', win: 'Ctrl + Alt + V', mac: '⌘ + Option + V' },
      { label: 'CLEAR_TEXT_FORMATTING', win: 'Ctrl + \\', mac: '⌘ + \\' }, */
    ],
  },
  {
    label: 'PARAGRAPH_FORMATTING',
    shortcuts: [
      { label: 'APPLY_HEADING_1_6', win: 'Ctrl + Alt + (1-6)', mac: '⌘ + Option + (1-6)' },
      /* { label: 'LEFT_ALIGN', win: 'Ctrl + L', mac: '⌘ + L' }, */
      { label: 'CENTER_ALIGN', win: 'Ctrl + E', mac: '⌘ + E' },
      /* { label: 'RIGHT_ALIGN', win: 'Ctrl + R', mac: '⌘ + R' }, */
      { label: 'editor.menu.format.justifyFull', win: 'Ctrl + J', mac: '⌘ + J' },
      /* { label: 'NUMBERED_LIST', win: 'Ctrl + Shift + 7', mac: '⌘ + Shift + 7' },
      { label: 'BULLET_LIST', win: 'Ctrl + Shift + 8', mac: '⌘ + Shift + 8' }, */
    ],
  },
  {
    label: 'INSERT',
    shortcuts: [
      /* { label: 'INSERT_FOOTNOTE', win: 'Ctrl + Alt + F', mac: '⌘ + Option + F' },
      { label: 'INSERT_LINK', win: 'Ctrl + K', mac: '⌘ + K' }, */
      { label: 'INSERT_PAGE_BREAK', win: 'Ctrl + Enter', mac: '⌘ + Enter' },
    ],
  },
];

const INPUT_STYLE = { marginBottom: '2rem', width: '27rem' };
const MODAL_BODY_STYLE = { maxHeight: '60rem' };

const MODAL = 'KeyboardShortcutsModal';

const KeyboardShortcutsModal = () => {
  const intl = useIntl();
  const { platform } = window.navigator;

  // redux
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.modals.open[MODAL]);

  // local
  const [searchValue, setSearchValue] = useState('');
  const [activeHeader, setActiveHeader] = useState('COMMON_ACTIONS');
  const headerRefs: { [key: string]: React.MutableRefObject<HTMLInputElement | null> } = {
    COMMON_ACTIONS: useRef(null),
    TEXT_FORMATTING: useRef(null),
    PARAGRAPH_FORMATTING: useRef(null),
    INSERT: useRef(null),
  };

  const shortcutGroups = useMemo(() => {
    if (searchValue === '') {
      return SHORTCUT_GROUPS;
    }
    return SHORTCUT_GROUPS.map((group) => {
      return {
        label: group.label,
        shortcuts: group.shortcuts.filter((shortcut) =>
          intl
            .formatMessage({ id: shortcut.label })
            .toLowerCase()
            .includes(searchValue.toLowerCase()),
        ),
      };
    }).filter((group) => group.shortcuts.length > 0);
  }, [searchValue]);

  const columns = useMemo<ColumnProps[]>(() => {
    return [
      {
        id: 'description',
        header: intl.formatMessage({ id: 'SHORTCUT_DESCRIPTION' }),
        width: 296,
        flex: true,
      },
      {
        id: 'shortcut',
        header: intl.formatMessage({ id: 'SHORTCUT' }),
        width: 168,
      },
    ];
  }, []);

  const handleOnHeaderClick = (group: string) => {
    if (headerRefs[group].current) {
      headerRefs[group]?.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value);

  const close = () => {
    setSearchValue('');
    setActiveHeader('COMMON_ACTIONS');
    dispatch(closeModal(MODAL));
  };
  const handleOnScroll: React.UIEventHandler<HTMLDivElement> = (e) => {
    // Starting from the bottom group
    // Is it at the start?
    const container = e.target as HTMLElement;
    if (container.scrollTop === 0) {
      setActiveHeader(SHORTCUT_GROUPS[0].label);
    } else {
      for (let i = SHORTCUT_GROUPS.length - 1; i >= 0; i--) {
        const group = SHORTCUT_GROUPS[i].label;
        const header = headerRefs[group].current;

        // Is the element currently in the container view or scrolled up?
        if (
          header &&
          container.scrollTop >
            header.offsetTop +
              (header?.clientHeight - 32) -
              container.clientHeight -
              container.offsetTop
        ) {
          setActiveHeader(group);
          break;
        }
      }
    }
  };

  const values = useMemo(() => {
    const ITEM_SIZE = 48;

    const groups: Record<
      (typeof shortcutGroups)[number]['label'],
      { value: TableProps<{ id: string }[]>['value']; height: number }
    > = {};

    shortcutGroups.forEach((group) => {
      groups[group.label] = {
        value: group.shortcuts.map((shortcut) => {
          return {
            id: shortcut.label,
            description: (
              <Cell ellipsis>
                <FormattedMessage id={shortcut.label} />
              </Cell>
            ),
            shortcut: (
              <Cell ellipsis>
                <div className={styles.shortcut}>
                  {platform.toLowerCase().indexOf('mac') > -1 ? shortcut.mac : shortcut.win}
                </div>
              </Cell>
            ),
          };
        }),
        height: group.shortcuts.length * ITEM_SIZE + ITEM_SIZE, // + header table size,
      };
    });
    return groups;
  }, [shortcutGroups]);

  return (
    <Modal width="102rem" open={!!isOpen} onClose={close} testId="keyboard-shortcuts">
      <Modal.Header onClose={close}>
        <FormattedMessage id="KEYBOARD_SHORTCUTS" />
      </Modal.Header>
      <Modal.Body maxHeight={MODAL_BODY_STYLE.maxHeight}>
        <div className={styles.body}>
          <div>
            <div style={INPUT_STYLE}>
              <Input
                prefix="NavSearchGrey"
                value={searchValue}
                onChange={handleOnChange}
                placeholder="Search"
                size="large"
                testId="keyboard-shortcusts-search"
              />
            </div>
            {SHORTCUT_GROUPS.map((group) => (
              <div
                key={group.label}
                className={`${styles.header} ${
                  searchValue === '' && activeHeader === group.label && styles.active
                }`}
                onClick={() => handleOnHeaderClick(group.label)}
              >
                <FormattedMessage id={group.label} />
              </div>
            ))}
          </div>
          <Divider vertical margin="0 2rem" />
          {shortcutGroups.length > 0 ? (
            <div className={styles.list} onScroll={handleOnScroll}>
              {shortcutGroups.map((group, i) => {
                return (
                  <div
                    key={group.label}
                    ref={headerRefs[group.label]}
                    style={i !== 0 ? { marginTop: '3rem' } : undefined}
                  >
                    <div className={styles.group}>
                      <FormattedMessage id={group.label} />
                    </div>

                    <div
                      className={styles.table}
                      style={{ height: `${values[group.label].height}px` }}
                    >
                      <ThunksTable
                        columns={columns}
                        value={values[group.label].value}
                        selectable={false}
                        loadingLabel={intl.formatMessage({ id: 'LOADING_ELEMENTS' })}
                        renderFooter={() => null}
                        testId="shortcuts"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={styles.empty_list}>
              <div className={styles.title}>
                <FormattedMessage id="NO_SHORTCUTS_FOUND" />
              </div>
              <div className={styles.description}>
                <FormattedMessage
                  id="NO_SHORTCUTS_FOUND_DESCRIPTION"
                  values={{ key: searchValue }}
                />
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="keyboard-shortcuts-modal-close-button">
          <FormattedMessage id="global.close" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default KeyboardShortcutsModal;
