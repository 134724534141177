import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Toggle, Tooltip } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import {
  navigateToMyFiles,
  navigateToObject,
  navigateToSettings,
  navigateToShared,
  navigateToSpaces,
} from 'router/history';

import { setInfoPanelOpen } from 'Search/redux/SearchPageSlice';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';

import { ActionBar, ActionsToggle } from '_common/components';
import { PathElement } from '_common/components/ActionBar/ActionBarBreadcrumb/ActionBarBreadcrumb';

import SearchInput from '../SearchInput/SearchInput';

import styles from './SearchBreadcrumb.module.scss';

const SearchBreadcrumb = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const data = useSelector((state) => state.app.data);
  const appLoading = useSelector((state) => state.app.loading.isOpen);
  const selectedState = useSelector((state) => state.table.selected);
  const error = useSelector((state) => state.app.error);

  const parent = useSelector((state) => state.search.parent);
  const activeTab = useSelector((state) => state.search.activeTab);
  const searchLoading = useSelector((state) => state.search.loading);
  const infoPanelOpen = useSelector((state) => state.search.infoPanelOpen);

  const object = data[Object.keys(selectedState)[0]];
  const hasError = error.status === 400 || error.status === 403 || error.status === 404;

  const getBreadcrumbPath = () => {
    // build path
    const path = [];

    if (parent && parent.id && activeTab === 'parent') {
      const parentName = data[parent.id] ? data[parent.id].name : parent.name;
      const parentType = data[parent.id] ? data[parent.id].type : parent.type;
      const parentPersonal = data[parent.id] ? data[parent.id].personal : parent.personal;

      path.push({
        id: parent.id,
        name:
          parentType === 'space' && parentPersonal
            ? intl.formatMessage({ id: 'spaces.personalSpace' })
            : parentName,
      });
    } else if (activeTab === 'spaces') {
      path.push({
        id: 'spaces',
        name: intl.formatMessage({ id: 'storage.sidebar.spaces' }),
      });
    } else {
      path.push({
        id: 'shared',
        name: intl.formatMessage({ id: 'shared.sharedTitle' }),
      });
    }

    path.push({
      id: 'search',
      name: intl.formatMessage({ id: 'global.search' }),
    });

    return path;
  };

  const handleOnClickBreadcrumb = (element: PathElement) => {
    if (parent && parent.id === element.id) {
      const parentType = data[parent.id] ? data[parent.id].type : parent.type;
      const parentPersonal = data[parent.id] ? data[parent.id].personal : parent.personal;

      if (parentPersonal && parentType === 'space') {
        navigateToMyFiles();
      } else {
        navigateToObject(parentType as NavigableObject, element.id);
      }
    } else if (element.id === 'spaces') {
      navigateToSpaces();
    } else {
      // element.id === 'shared'
      navigateToShared();
    }
  };

  const handleOnClickSettings = () => {
    if (Object.keys(selectedState).length === 1) {
      const type = object.type;
      const id = object.id;
      navigateToSettings(type, id);
    }
  };

  const handleOnClickShare = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ShareModal',
        data: {
          view: 'users',
          objectId: Object.keys(selectedState),
          objectType: object.type,
          editor: false,
        },
      }),
    );
  };

  const handleDisabledShareButton = () => {
    const conditionsToEnableShare = ['admin', 'owner', 'add_permission', 'remove_permission'];

    if (
      searchLoading ||
      Object.keys(data).length === 0 ||
      Object.keys(selectedState).length !== 1 ||
      (object && object.status === 'processing') ||
      hasError ||
      !conditionsToEnableShare.some(
        (permission) => object && object.user_permissions.includes(permission),
      )
    ) {
      return true;
    }

    return false;
  };

  const handleToggleInfoPanel = () => {
    dispatch(setInfoPanelOpen(!infoPanelOpen));
  };

  return (
    <div className={styles.header}>
      <ActionBar>
        <ActionBar.Breadcrumb
          onBreadcrumbElementClick={handleOnClickBreadcrumb}
          path={getBreadcrumbPath()}
          pageLoading={appLoading}
        />
        <div className={styles.actionsContainer}>
          <Button
            size="medium"
            onClick={handleOnClickSettings}
            icon="Settings"
            disabled={Object.keys(selectedState).length !== 1 || searchLoading || hasError}
            testId="settings-button"
          >
            <FormattedMessage id="global.settings" />
          </Button>
          <Button
            size="medium"
            onClick={handleOnClickShare}
            disabled={handleDisabledShareButton()}
            style={{ marginLeft: '0.5rem' }}
            icon="Share"
            testId="share-button"
          >
            <FormattedMessage id="storage.actionBar.actions.share" />
          </Button>
          {parent && (
            <ActionsToggle
              disabled={Object.keys(selectedState).length === 0 || hasError}
              selected={Object.keys(selectedState)}
              identity="search"
              current={parent}
            />
          )}
        </div>
        <div className={styles.togglesContainer}>
          <Tooltip
            placement="bottom"
            content={intl.formatMessage({ id: 'global.information' })}
            testId="search-page-information-tooltip"
          >
            <Toggle
              size="medium"
              variant="standard"
              icon="Information"
              onClick={handleToggleInfoPanel}
              isToggled={infoPanelOpen}
              disabled={Object.keys(data).length === 1 || searchLoading}
              testId="search-page-information-toggle"
            />
          </Tooltip>
        </div>
        <SearchInput disabled style={{ marginLeft: '2rem' }} />
      </ActionBar>
    </div>
  );
};

export default SearchBreadcrumb;
