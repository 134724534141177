import TaskWatchersModal from '_common/suite/modals/TaskWatchersModal';

import { useModalsOpen } from 'App/ModalContext/ModalContext';

/**
 * Modal provider for suite applications
 * All modals that are shared between two or more suite applications should be placed here
 * For example, the SaveVersionModal is used in Editor, PDF and Presentation
 * If a modal is only used in one application, it should be placed in the respective application ModalProvider
 */
const SuiteModalProvider = () => {
  const openModal = useModalsOpen();

  return <>{openModal.TaskWatchersModal && <TaskWatchersModal />}</>;
};

export default SuiteModalProvider;
