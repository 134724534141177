import { useMemo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';

import { RichTextEditor, InteractionController } from '_common/components';
import { SuiteAvatar } from '_common/suite/components';
import { RichTextEditorHandler } from '_common/components/RichTextEditor/RichTextEditor';
import { InteractionControllerProps } from '_common/components/OnboardingOverlay/InteractionController';

import ReplyCard, { ReplyCardProps } from './ReplyCard/ReplyCard';

import styles from './ReplyList.module.scss';
import { useVirtualizedList } from '../VirtualizedList/VirtualizedListContext';

type ReplyListProps = {
  replies: Card.Reply[];
  commentId: PDF.Annotation['id'];
  collaborators: UserId[];
  canComment: boolean;
  newReplyInteraction: Omit<InteractionControllerProps, 'children'>;
  createReply: (reply: string) => void;
  editReply: (payload: { replyId: string; newContent: string }) => void;
  /** ID to be used in unit or automated tests.
   * Will result in 4 different attributes:
   * * ${testId}-card-${index}-root
   * * ${testId}-card-${index}-editable
   * * ${testId}-card-${index}-view
   * * ${testId}-new-reply-container
   */
  testId: string;
} & Pick<
  ReplyCardProps,
  | 'voteReply'
  | 'deleteReply'
  | 'canEditReply'
  | 'canDeleteReply'
  | 'canVoteReply'
  | 'optionsToggleTooltip'
  | 'likeToggleTooltip'
  | 'legacyModal'
>;

const ReplyList = ({
  replies,
  commentId,
  collaborators,
  canComment,
  newReplyInteraction,
  optionsToggleTooltip,
  likeToggleTooltip,
  createReply,
  editReply,
  deleteReply,
  voteReply,
  canEditReply,
  canDeleteReply,
  canVoteReply,
  legacyModal,
  testId,
}: ReplyListProps) => {
  const intl = useIntl();
  const { openReplies, setReplyState, virtualized } = useVirtualizedList();

  const currentUserId = useSelector((state) => state.auth.userId);

  const replyRef = useRef<RichTextEditorHandler>(null);
  const [reply, setReply] = useState('');

  const [localShowFooter, setLocalShowFooter] = useState(false);
  const showFooter = useMemo(
    () => (virtualized ? openReplies[commentId] === 'footer' : localShowFooter),
    [virtualized, openReplies, commentId, localShowFooter],
  );

  const mutableReplies = useMemo(() => [...replies].reverse(), [replies]);

  const handleCancelReply = () => {
    replyRef.current?.clear();

    if (virtualized) {
      setReplyState(commentId, 'open');
    } else {
      setLocalShowFooter(false);
    }
  };

  const handleCreateReply = () => {
    createReply(reply);
    replyRef.current?.clear();

    if (virtualized) {
      setReplyState(commentId, 'open');
    } else {
      setLocalShowFooter(false);
    }
  };

  const handleFocus = () => {
    if (virtualized) {
      setReplyState(commentId, 'footer');
    } else {
      setLocalShowFooter(true);
    }
  };

  return (
    <div className={styles.root}>
      {mutableReplies.length > 0 && (
        <div className={styles.list}>
          {mutableReplies.map((reply, index) => (
            <ReplyCard
              key={`replycard-${reply.id}-of-${commentId}`}
              reply={reply}
              collaborators={collaborators}
              testId={`${testId}-card-${index}`}
              optionsToggleTooltip={optionsToggleTooltip}
              likeToggleTooltip={likeToggleTooltip}
              editReply={editReply}
              deleteReply={deleteReply}
              voteReply={voteReply}
              canEditReply={canEditReply}
              canDeleteReply={canDeleteReply}
              canVoteReply={canVoteReply}
              legacyModal={legacyModal}
            />
          ))}
        </div>
      )}
      {canComment && (
        <InteractionController style={{ display: 'block' }} {...newReplyInteraction}>
          <div className={styles.newReply} data-testid={`${testId}-new-reply-container`}>
            <div className={styles.body}>
              <SuiteAvatar userId={currentUserId} />
              <RichTextEditor
                ref={replyRef}
                placeholder={intl.formatMessage({ id: 'REPLY_PLACEHOLDER' })}
                onChange={setReply}
                onFocus={handleFocus}
                mentionableUsers={collaborators}
                skipFocus
                singleLine
                testId={`${testId}-rich-text-editor`}
              />
            </div>
            {showFooter && (
              <div className={styles.footer}>
                <Button
                  variant="link"
                  size="small"
                  onClick={handleCancelReply}
                  testId="cancel-button"
                >
                  <FormattedMessage id="global.cancel" />
                </Button>
                <Button
                  variant="primary"
                  size="small"
                  onClick={handleCreateReply}
                  disabled={reply === ''}
                  testId="reply-button"
                >
                  <FormattedMessage id="editor.comments.reply" />
                </Button>
              </div>
            )}
          </div>
        </InteractionController>
      )}
    </div>
  );
};

export default ReplyList;
