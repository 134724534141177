import qs from 'qs';
import getConfig from 'dodoc.config';

import BaseService, { type BaseServiceConstructor } from './BaseService';
import { paths } from '_types/api';

export default class EditorService extends BaseService {
  // -----------------------------------------------------------------
  // editor endpoints
  readonly SAVE_IMAGE = '/api/object/document/image/upload';
  readonly LIST_TEMPLATES = '/api/object/document/template/list';
  readonly EXPORT = (type: string, id: string) => `/api/object/${type}/${id}/export`;
  readonly IMPORT = (id: string) => `/api/object/document/${id}/import/version`;
  readonly DOWNLOAD = (id: string) => `/api/object/document/${id}/export/get`;
  readonly IMPORT_CITATIONS = '/api/object/document/citation/import';
  readonly CITATIONS_SEARCH = (id: string) => `/api/object/document/${id}/citation/search`;
  readonly SCOPUS_AUTHORS_SEARCH = (id: string) =>
    `/api/object/document/${id}/citation/authors/search`;
  readonly SYNONYMS = () => `/api/synonyms`;

  readonly TEMPLATE_STYLES_DEFINITION = (id: string) =>
    `/api/object/document/${id}/template/definitions`;

  // -------------------------------------------------------------------
  // keywords endpoints
  readonly KEYWORD_ADD = (documentId: string) => `/api/object/document/${documentId}/keyword/add`;
  readonly KEYWORD_REMOVE = (documentId: string) =>
    `/api/object/document/${documentId}/keyword/remove`;

  // ------------------------------------------------------
  // ------------------ Document Details ------------------
  readonly ADD_AUTHOR = (id: string) => `/api/object/document/${id}/author/add`;
  readonly REMOVE_AUTHOR = (id: string) => `/api/object/document/${id}/author/remove`;
  readonly UPDATE_META_INFORMATION = (id: string) => `/api/object/document/${id}/author/update`;
  readonly ADD_META_INFORMATION = (id: string) =>
    `/api/object/document/${id}/author/information/add`;
  readonly REMOVE_META_INFORMATION = (id: string) =>
    `/api/object/document/${id}/author/information/remove`;
  readonly REORDER_AUTHORS = (id: string) => `/api/object/document/${id}/author/reorder`;
  readonly SET_DOCUMENT_TEMPLATE = (id: string) => `/api/object/document/${id}/template/save`;

  // ------------------------------------------------------
  // Document Indexation
  // ------------------------------------------------------
  readonly INDEX_DOCUMENT = (id: string) => `/api/object/document/${id}/index`;
  readonly FORCE_INDEX = '/api/object/document/index/force';

  // ------------------------------------------------------
  // Equations
  // ------------------------------------------------------
  readonly CONVERT_EQUATION = '/api/equation/convert';
  // ------------------------------------------------------
  // Citations
  // ------------------------------------------------------
  readonly EXPORT_REFERENCE = (id: string) => `/api/object/document/${id}/citation/export`;
  readonly LIST_REFERENCE_STYLES = '/api/object/document/citationsstyle/list';

  // -----------------------------------------------------
  // Images
  // -----------------------------------------------------
  readonly GET_IMAGE = (docId: string, imageId: string) =>
    `/api/object/document/${docId}/image/${imageId}/get`;

  constructor(params?: Omit<BaseServiceConstructor, 'baseURL'>) {
    super({ baseURL: getConfig().api, ...params });
  }

  saveImage(data: { id: string; image: File }, config: Request.Config) {
    const formData = new FormData();
    formData.append('object_id', data.id);
    formData.append('attachment', data.image, 'attachment.png');

    return this.post<
      paths['/api/object/document/image/upload']['post']['responses']['200']['content']['application/json']
    >(this.SAVE_IMAGE, formData, config);
  }

  getImage(documentId: string, imageId: string, config?: Request.Config) {
    return this.get<
      paths['/api/object/document/{document_id}/image/{reference_id}/get']['get']['responses']['200']
    >(this.GET_IMAGE(documentId, imageId), config);
  }

  findSynonyms(query: string) {
    return this.get<paths['/api/synonyms']['get']['responses']['200']>(this.SYNONYMS(), {
      params: {
        word: query,
        language: 'en_US',
      },
    });
  }

  listInstallledTemplates() {
    return this.get<DocumentTemplate[]>(this.LIST_TEMPLATES);
  }

  exportDocument(type: string, document: string, options: MyAny) {
    if (options.path) {
      const data = {
        path: JSON.stringify([...options.path]),
        name: options.name,
        destination: options.destination,
        type: options.type,
        template: options.template,
        format: options.format,
        keep_comments: options.keep_comments,
        keep_suggestions: options.keep_suggestions,
        nodes: options.nodes,
        connector: 'netdocuments',
      };
      return this.post<
        paths['/api/object/document/{document_id}/export']['post']['responses']['200']
      >(this.EXPORT(type, document), qs.stringify(data, { arrayFormat: 'brackets' }));
    }
    return this.post<
      paths['/api/object/document/{document_id}/export']['post']['responses']['200']
    >(this.EXPORT(type, document), qs.stringify(options, { arrayFormat: 'brackets' }));
  }

  importDocument(document: string, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    return this.post<paths['/api/object/document/import']['post']['responses']['200']>(
      this.IMPORT(document),
      formData,
    );
  }

  download(documentId: string) {
    return this.get<Blob>(this.DOWNLOAD(documentId), { responseType: 'blob' });
  }

  importCitations(
    params: paths['/api/object/document/citation/import']['post']['requestBody']['content']['multipart/form-data'],
  ) {
    const formData = new FormData();
    if (params.file) {
      formData.append('file', params.file);
    }
    return this.post(this.IMPORT_CITATIONS, formData);
  }

  searchCitations(documentId: string, query: string, source: string) {
    return this.post<
      paths['/api/object/document/{document_id}/citation/search']['post']['responses']['200']['content']['application/json']
    >(this.CITATIONS_SEARCH(documentId), qs.stringify({ query, source }));
  }

  getScopusCitationAuthors(documentId: string, scopusId: string) {
    return this.post<
      paths['/api/object/document/{document_id}/citation/authors/search']['post']['responses']['200']['content']['application/json']
    >(this.SCOPUS_AUTHORS_SEARCH(documentId), qs.stringify({ scopus_id: scopusId }));
  }

  // -------------------------------------------------------------------
  // keywords
  /**
   * add a keywrod to a document
   * @param {*} documentId document id
   * @param {*} keywordValue keyword value
   */
  addKeyword(documentId: string, keywordValue: string) {
    return this.post<
      paths['/api/object/document/{document_id}/keyword/add']['post']['responses']['200']
    >(this.KEYWORD_ADD(documentId), qs.stringify({ value: keywordValue }));
  }

  /**
   * remove a keywrod to a document
   * @param {*} documentId document id
   * @param {*} keywordIndex keyword index
   */
  removeKeyword(documentId: string, keywordIndex: number) {
    return this.post<
      paths['/api/object/document/{document_id}/keyword/remove']['post']['responses']['200']
    >(this.KEYWORD_REMOVE(documentId), qs.stringify({ index: keywordIndex }));
  }

  // ------------------------------------------------------
  // ------------------ Document Details ------------------

  addAuthor(id: string) {
    return this.post<
      paths['/api/object/document/{document_id}/author/add']['post']['responses']['200']
    >(this.ADD_AUTHOR(id));
  }

  removeAuthor(id: string, author: number) {
    return this.post<
      paths['/api/object/document/{document_id}/author/remove']['post']['responses']['200']
    >(this.REMOVE_AUTHOR(id), qs.stringify({ author }));
  }

  reorderAuthors(id: string, params: { current: number; new: number }) {
    return this.post<
      paths['/api/object/document/{document_id}/author/reorder']['post']['responses']['200']
    >(this.REORDER_AUTHORS(id), qs.stringify({ ...params }));
  }

  updateMetaInformation(
    id: string,
    params: paths['/api/object/document/{document_id}/author/update']['post']['requestBody']['content']['multipart/form-data'],
  ) {
    return this.post<
      paths['/api/object/document/{document_id}/author/update']['post']['responses']['200']
    >(this.UPDATE_META_INFORMATION(id), qs.stringify({ ...params }));
  }

  addMetaInformation(
    id: string,
    params: paths['/api/object/document/{document_id}/author/information/add']['post']['requestBody']['content']['multipart/form-data'],
  ) {
    return this.post<
      paths['/api/object/document/{document_id}/author/information/add']['post']['responses']['200']
    >(this.ADD_META_INFORMATION(id), qs.stringify({ ...params }));
  }

  removeMetaInformation(
    id: string,
    params: paths['/api/object/document/{document_id}/author/information/remove']['post']['requestBody']['content']['multipart/form-data'],
  ) {
    return this.post<
      paths['/api/object/document/{document_id}/author/information/remove']['post']['responses']['200']
    >(this.REMOVE_META_INFORMATION(id), qs.stringify({ ...params }));
  }

  convertEquation(equation: string) {
    return this.post(this.CONVERT_EQUATION, qs.stringify({ equation }), {
      responseType: 'text',
    });
  }

  indexDocument(id: string) {
    return this.post(this.INDEX_DOCUMENT(id));
  }

  forceIndex() {
    return this.post(this.FORCE_INDEX);
  }

  setDocumentTemplate(documentId: string, templateId: string) {
    return this.post(
      this.SET_DOCUMENT_TEMPLATE(documentId),
      qs.stringify({ template: templateId }),
    );
  }

  getTemplateStylesDefinition(documentId: string) {
    return this.get(this.TEMPLATE_STYLES_DEFINITION(documentId));
  }

  exportCitations(
    documentId: string,
    parameters: paths['/api/object/document/{document_id}/citation/export']['post']['requestBody']['content']['multipart/form-data'],
  ) {
    return this.post<Blob>(
      this.EXPORT_REFERENCE(documentId),
      qs.stringify(parameters, { arrayFormat: 'brackets' }),
      {
        responseType: 'blob',
      },
    );
  }

  listReferenceStyles() {
    return this.get(this.LIST_REFERENCE_STYLES);
  }
}
