import { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import appEvents, { AppEvents } from '../appEvents';
import { Modal, ModalData } from './ModalContextTypes';
import {
  ExportModal,
  GenericConfirmationModal,
  TOCModal,
  DocumentAuditLogModal,
  AutomaticMetadataModal,
  ManualMetadataModal,
  DeleteMetadataModal,
  TotalUsersModal,
  AdvancedSearchModal,
  SaveFiltersModal,
  AccessSavedFiltersModal,
  WarningModal,
} from '_common/modals';

type ModalContextType = {
  modalData: Partial<ModalData>;
  openModal: Partial<Record<Modal, boolean>>;
};

const ModalContext = createContext<ModalContextType>({
  modalData: {},
  openModal: {},
});

const ModalProvider = ({ children }: { children: ReactNode }) => {
  const isComponentMounted = useRef<boolean>();
  const [openModal, setOpenModal] = useState<ModalContextType['openModal']>({});
  const [modalData, setModalData] = useState<ModalContextType['modalData']>({});

  //#region Main handles
  const handleOpen: AppEvents['MODAL_OPEN'] = ({ modal, data }) => {
    if (!isComponentMounted.current) {
      return;
    }

    setOpenModal((prevOpenModals) => ({ ...prevOpenModals, [modal]: true }));
    setModalData((prevData) => ({ ...prevData, [modal]: data }));
  };

  const handleUpdate: AppEvents['MODAL_UPDATE'] = ({ modal, data, overwrite = true }) => {
    if (!isComponentMounted.current) {
      return;
    }

    if (overwrite) {
      setModalData((prevData) => ({ ...prevData, [modal]: data }));
    } else {
      setModalData((prevData) => ({ ...prevData, [modal]: { ...prevData[modal], ...data } }));
    }
  };

  const handleClose: AppEvents['MODAL_CLOSE'] = (modal) => {
    if (!isComponentMounted.current) {
      return;
    }

    setOpenModal((prevOpenModals) => ({ ...prevOpenModals, [modal]: false }));
    setModalData((prevData) => {
      const _prevData = { ...prevData };
      delete _prevData[modal];
      return _prevData;
    });
  };

  const handleCloseAll: AppEvents['MODAL_CLOSE_ALL'] = () => {
    if (!isComponentMounted.current) {
      return;
    }

    setOpenModal({});
  };
  //#endregion

  useEffect(() => {
    appEvents.on('MODAL_OPEN', handleOpen);
    appEvents.on('MODAL_UPDATE', handleUpdate);
    appEvents.on('MODAL_CLOSE', handleClose);
    appEvents.on('MODAL_CLOSE_ALL', handleCloseAll);

    isComponentMounted.current = true;

    return () => {
      appEvents.off('MODAL_OPEN', handleOpen);
      appEvents.off('MODAL_UPDATE', handleUpdate);
      appEvents.off('MODAL_CLOSE', handleClose);
      appEvents.off('MODAL_CLOSE_ALL', handleCloseAll);

      isComponentMounted.current = false;
    };
  }, []);

  return (
    <ModalContext.Provider value={{ modalData, openModal }}>
      {children}
      {openModal.ConfirmationModal && <GenericConfirmationModal />}
      {openModal.ExportModal && <ExportModal />}
      {openModal.TOCModal && <TOCModal />}
      {openModal.DocumentAuditLogModal && <DocumentAuditLogModal />}
      {openModal.AutomaticMetadataModal && <AutomaticMetadataModal />}
      {openModal.ManualMetadataModal && <ManualMetadataModal />}
      {openModal.DeleteMetadataModal && <DeleteMetadataModal />}
      {openModal.TotalUsersModal && <TotalUsersModal />}
      {openModal.AdvancedSearchModal && <AdvancedSearchModal />}
      {openModal.SaveFiltersModal && <SaveFiltersModal />}
      {openModal.AccessSavedFiltersModal && <AccessSavedFiltersModal />}
      {openModal.WarningModal && <WarningModal />}
    </ModalContext.Provider>
  );
};

const useModal = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('Component not wrapped with ModalProvider');
  }
  return context;
};

export const useModalData = <T extends Modal>(modal: T) => {
  return useModal().modalData[modal];
};
export const useModalsOpen = () => {
  return useModal().openModal;
};

export default ModalProvider;
