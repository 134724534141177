import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Button, Dropdown } from 'dodoc-design-system';
import { type ColumnProps } from 'dodoc-design-system/build/types/Components/Table/Table';
import { AxiosResponse } from 'axios';
import { useParams } from 'react-router';

import { giveGroupPermission } from 'App/redux/appSlice';
import { useDispatch, usePublicGroup, usePublicProfiles } from '_common/hooks';
import { listObjects } from '_common/components/Table/TableSlice';
import { SearchUser, UserAvatar } from '_common/components';
import type { UserOption } from '_common/components/SearchUser/SearchUser';
import { ThunksTable } from '_common/components/Table';
import Cell from '_common/components/Table/Cells';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { SettingGroupHeader } from 'Settings/components';

import styles from '../GroupSettingsPage.module.scss';

const PAGE_IDENTITY = 'groups_admins';

const AdminsView = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { id: groupId }: { id: ObjectId } = useParams();

  const { group } = usePublicGroup(groupId);

  const usersList = useMemo(
    () => [
      group.owner,
      ...Object.keys(group.permissions.users).filter((user) =>
        group.permissions.users[user].includes('owner'),
      ),
    ],
    [group],
  );

  const { profiles } = usePublicProfiles(usersList);

  const [adminSelected, setAdminSelected] = useState<UserOption | null>(null);
  const userIsOwner =
    group.user_permissions.includes('admin') || group.user_permissions.includes('owner');

  const columns = useMemo<ColumnProps[]>(
    () => [
      {
        id: 'avatar',
        header: '',
        width: 6.5 * 8,
      },
      {
        id: 'name',
        header: intl.formatMessage({ id: 'groups.header.name' }),
        width: 11 * 8,
        flex: true,
      },
      {
        id: 'email',
        header: intl.formatMessage({ id: 'storage.modals.user.email' }),
        width: 40 * 8,
      },
      {
        id: 'permissions',
        header: intl.formatMessage({ id: 'groups.header.permissions' }),
        width: 20 * 8,
      },
    ],
    [],
  );

  const value = useMemo(() => {
    return usersList
      .filter((userId) => profiles[userId])
      .map((objectId) => {
        const object = profiles[objectId];
        const userIsOwner =
          group.user_permissions.includes('admin') || group.user_permissions.includes('owner');
        const subjectIsOwner = group.owner === objectId;
        const renderPermission = () => {
          let permission = intl.formatMessage({ id: 'groups.header.member' }, { membersCount: 1 });

          if (group.permissions.users[objectId]) {
            if (group.permissions.users[objectId].includes('owner')) {
              permission = intl.formatMessage({ id: 'groups.admin' });
            }
          }

          if (group.owner === objectId) {
            permission = intl.formatMessage({ id: 'groups.header.owner' });
          }

          return permission;
        };
        return {
          prefix: null,
          id: object.id,
          avatar: (
            <Cell testId={`${objectId}-avatar-column`}>
              <UserAvatar userId={objectId} size="medium" />
            </Cell>
          ),
          name: (
            <Cell testId={`${objectId}-name-column`} ellipsis>
              {object.name}
            </Cell>
          ),
          email: <Cell testId={`${objectId}-email-column`}>{object.email}</Cell>,
          permissions: <Cell testId={`${objectId}-permission-column`}>{renderPermission()}</Cell>,
          suffix: userIsOwner && !subjectIsOwner && (
            <Cell testId={`${objectId}-suffix-column`}>
              <Cell.Options
                items={
                  <Dropdown.Item
                    onClick={() => {
                      dispatch(
                        openAndUpdateModal({
                          modal: 'ConfirmationModal',
                          data: {
                            title: 'REMOVE_SOMETHING_QUOTED',
                            titleValues: { label: object.name },
                            message:
                              'REMOVING_THIS_USER__FROM_GROUP_WILL_REVOKE_PERMISSIONS_CONFIRM',
                            confirmButtonTextId: 'global.remove',
                            confirmButtonType: 'danger',
                            cancelButtonTextId: 'global.cancel',
                            actionCode: 'removeGroupPermission',
                            actionValue: { groupId, userId: objectId },
                            headerType: 'error',
                            cancelButtonShow: true,
                          },
                        }),
                      );
                    }}
                    testId="remove-user-dropdown-item"
                  >
                    <FormattedMessage id="groups.removeUser" />
                  </Dropdown.Item>
                }
                testId={`group-admins-${object.id}`}
              />
            </Cell>
          ),
        };
      });
  }, [profiles, usersList]);

  //#region Table object listing handlers
  const fetchObjects = useCallback(async () => {
    return new Promise<AxiosResponse<unknown>>((resolve) => {
      resolve({
        data: { nodes: Object.values(profiles) },
        status: 200,
        statusText: '',
        headers: { placeholder: '' },
        config: {},
      });
    });
  }, [profiles]);

  useEffect(() => {
    dispatch(
      listObjects({
        identity: PAGE_IDENTITY,
        fetch: fetchObjects,
        cause: 'INITIAL',
        request: { offset: 0 },
      }),
    );
  }, [fetchObjects]);
  //#endregion

  const handleOnClickAddAdmin = () => {
    if (adminSelected) {
      dispatch(giveGroupPermission({ groupId: group.id, userId: adminSelected.value }));
      setAdminSelected(null);
    }
  };

  return (
    <>
      {/* Admins */}
      <SettingGroupHeader
        style={{ padding: '16px', marginBottom: '2rem' }}
        actions={
          userIsOwner && (
            <div className={styles.autocompleteContainer}>
              <SearchUser
                value={adminSelected}
                valuesToFilter={[group.owner, ...Object.keys(group.permissions.users)]}
                onChange={setAdminSelected}
                placeholder={intl.formatMessage({
                  id: 'settings.group.placeholderAutocomplete',
                })}
                escapeClearsValue
                width="37rem"
                testId="admins"
                menuLabel={intl.formatMessage({ id: 'START_TYPING_TO_SEE_THE_LIST_OF_USERS' })}
              />
              <Button
                size="medium"
                variant="primary"
                style={{ marginLeft: '2rem' }}
                onClick={handleOnClickAddAdmin}
                disabled={!adminSelected}
                testId="admins-add-button"
              >
                <FormattedMessage id="global.add" />
              </Button>
            </div>
          )
        }
      >
        <div className={styles.userPermissionsText}>
          <span className={styles.title}>
            {intl.formatMessage({
              id: 'settings.group.groupAdministratorsTitle',
            })}
          </span>
          <span className={styles.subtitle}>
            {intl.formatMessage({
              id: 'settings.group.groupAdministratorsSubtitle',
            })}
          </span>
        </div>
      </SettingGroupHeader>

      <ThunksTable
        identity={PAGE_IDENTITY}
        columns={columns}
        value={value}
        loadingLabel={intl.formatMessage({ id: 'LOADING_ELEMENTS' })}
        fetchObjects={fetchObjects}
        testId="admins-tab"
        selectable={false}
        renderFooter={undefined}
      />
    </>
  );
};

export default AdminsView;
