/* eslint-disable camelcase */
import qs from 'qs';
import getConfig from 'dodoc.config';

import BaseService from './BaseService';
import type { BaseServiceConstructor } from './BaseService';
import { paths } from '_types/api';

export default class InstanceService extends BaseService {
  // -----------------------------------------------------------------
  // generic endpoints
  readonly LIST = (type: ListableObjectType) => `/api/object/${type}/list`;
  readonly LIST_ID = (type: string, id: ObjectId) => `/api/object/${type}/${id}/list`;
  readonly GET_DATA = (type: string, id: ObjectId, open?: boolean) =>
    `/api/object/${type}/get${id ? `/${id}` : ''}${open ? '?open=true' : ''}`;
  readonly GET_OBJECT_STATUS = (type: string, id: ObjectId) =>
    `/api/object/${type}/get/${id}/status`;
  readonly CREATE = (type: string) => `/api/object/${type}/create`;
  readonly EDIT = (type: string, id: ObjectId) => `/api/object/${type}/${id}/edit`;
  readonly DELETE = (type: string, id: ObjectId) => `/api/object/${type}/${id}/delete`;
  readonly PERSONAL_SPACE = '/api/object/space/get';
  readonly OBJECT_PATH = (id: ObjectId) => `/api/object/${id}/path`;
  readonly MOVE = (source: ObjectId) => `/api/object/${source}/move`;
  readonly COPY = '/api/object/copy';
  readonly GET_USER_GROUPS = '/api/object/user/groups';
  readonly IMPORT_CONTENT = ({
    objectType,
  }: {
    objectType: 'document' | 'dopdf' | 'presentation';
  }) => `/api/object/${objectType}/import`;
  readonly CHANGE_MANAGER = (type: string, id: ObjectId) =>
    `/api/object/${type}/${id}/change_ownership`;
  readonly USER_ACTIVE = `/api/user/active`;

  // -----------------------------------------------------------------
  // api jobs
  readonly GET_JOB_STATUS = `/api/jobs/get`;

  // -----------------------------------------------------------------
  // recycle_endpoints endpoints
  readonly LIST_RECYCLE = '/api/recycle/list';
  readonly REMOVE_RECYCLE = (id: ObjectId) => `/api/recycle/${id}/remove`;
  readonly RESTORE_RECYCLE = (id: ObjectId) => `/api/recycle/${id}/restore`;

  // -----------------------------------------------------------------
  // tags endpoints
  readonly ADD_TAG = (type: string, id: ObjectId) => `/api/object/${type}/${id}/tag/add`;
  readonly REMOVE_TAG = (type: string, id: ObjectId) => `/api/object/${type}/${id}/tag/remove`;
  readonly LIST_TAG = '/api/tags';

  // -----------------------------------------------------------------
  // checkOut , checkIn and update endpoints
  readonly CHECK_OUT = (id: ObjectId) => `/api/object/file/${id}/checkout`;
  readonly CHECK_OUT_CLOSE = (id: ObjectId) => `/api/object/file/${id}/checkout/close`;
  readonly UPLOAD_NEW_FILE_VERSION = (operation: string) =>
    `/api/object/file/${operation === 'checkIn' ? 'checkin/' : ''}version/add`;

  readonly CONFIRM_NEW_FILE_VERSION = (id: ObjectId, operation: string) =>
    `/api/object/file/${id}/${operation === 'checkIn' ? 'checkin/' : ''}version/confirm`;

  // -----------------------------------------------------------------
  // permissions endpoints
  readonly ADD_PERMISSION = (type: string, id: ObjectId) =>
    `/api/object/${type}/${id}/permission/add`;
  readonly REMOVE_PERMISSION = (type: string, id: ObjectId) =>
    `/api/object/${type}/${id}/permission/remove`;

  // -----------------------------------------------------------------
  // date endpoints
  readonly ADD_WARNING = (id: ObjectId) => `/api/object/${id}/warning/add`;
  readonly REMOVE_WARNING = (id: ObjectId) => `/api/object/${id}/warning/remove`;
  readonly EDIT_OVERDUE = (id: ObjectId) => `/api/object/${id}/overdue/edit`;

  // -----------------------------------------------------------------
  // approve and reopen endpoints
  readonly APPROVE = (type: string, id: ObjectId) => `/api/object/${type}/${id}/approve`;
  readonly REOPEN = (type: string, id: ObjectId) => `/api/object/${type}/${id}/reopen`;

  // -----------------------------------------------------------------
  // download endpoints
  readonly DOWNLOAD = (type: 'dopdf' | 'file', id: ObjectId) =>
    `/api/object/${type}/${id}/download`;
  readonly DOWNLOAD_SOURCE = (id: ObjectId) => `/api/object/document/${id}/source/download`;
  readonly DOWNLOAD_PRESENTATION_SOURCE = (id: ObjectId) =>
    `/api/object/presentation/${id}/source/download`;
  readonly DOWNLOAD_PDF = (id: ObjectId) => `/api/object/dopdf/${id}/annotator`;

  // -----------------------------------------------------------------
  // role
  readonly ADD_OBJECT_PERMISSION_TO_ROLE = (id: ObjectId) => `/api/object/role/${id}/add`;
  readonly REMOVE_OBJECT_PERMISSION_FROM_ROLE = (id: ObjectId) => `/api/object/role/${id}/remove`;
  readonly ADD_ROLES = (type: string, id: ObjectId) => `/api/object/${type}/${id}/role/add`;
  readonly REMOVE_ROLES = (type: string, id: ObjectId) => `/api/object/${type}/${id}/role/remove`;
  readonly PUBLIC_GROUPS = '/api/object/group/get';

  // -----------------------------------------------------------------
  // search
  readonly SEARCH_OBJECT = '/api/object/search';

  // wopi
  readonly OTT_CREATE = '/api/ott/create';

  // -----------------------------------------------------------------
  // tenant tags
  readonly CREATE_TAG = '/api/tag/add';
  readonly DELETE_TAG = '/api/tag/remove';
  readonly UPLOAD_TAG = '/api/tag/import';

  // -----------------------------------------------------------------
  // tenant affiliations
  readonly AFFILIATIONS_LIST = '/api/object/affiliations/list';
  readonly ADD_AFFILIATION_FIELD = '/api/object/affiliations/field/add';
  readonly EDIT_AFFILIATION_FIELD = '/api/object/affiliations/field/edit';
  readonly DELETE_AFFILIATION_FIELD = '/api/object/affiliations/field/delete';

  // -----------------------------------------------------------------
  // element status
  readonly ELEMENT_STATUS_CHANGE = (type: string, id: ObjectId) =>
    `/api/object/${type}/${id}/change_status`;
  readonly ELEMENT_STATUS_CHANGE_ALLOWED = (type: string, id: ObjectId) =>
    `/api/object/${type}/${id}/change_status/allowed`;

  // -----------------------------------------------------------------
  // metadata
  readonly EDIT_METADATA = (id: ObjectId) => `/api/object/document/${id}/metadata/edit`;
  readonly METADATA_LIST = '/api/object/metadata/list';

  // -----------------------------------------------------------------
  // saved filters
  PERSONAL_SAVED_FILTERS_LIST = '/api/object/searchfilter/list';
  PERSONAL_SAVED_FILTERS_CREATE = '/api/object/searchfilter/create';
  PERSONAL_SAVED_FILTERS_DELETE = (savedFilterId: string) =>
    `/api/object/searchfilter/${savedFilterId}/delete`;

  // -----------------------------------------------------------------
  // notifications
  readonly NOTIFICATION_SETTINGS_USER = '/api/user/settings';

  // -----------------------------------------------------------------
  // notifications
  NOTIFICATIONS_LIST = '/api/object/notification/list';
  EDIT_NOTIFICATION = (id: ObjectId) => `/api/object/notification/${id}/edit`;
  MARK_ALL_NOTIFICATIONS_AS_READ = '/api/object/notification/read';
  GET_ALL_UNREAD_NOTIFICATIONS = '/api/object/notification/unread';
  // audit actions log
  readonly LIST_AUDIT_ACTIONS_LOG = (params: { objectType: string; objectId: ObjectId }) =>
    `/api/object/${params.objectType}/${params.objectId}/actions`;
  readonly EXPORT_AUDIT_LOG = (params: { objectType: string; objectId: ObjectId }) =>
    `/api/object/${params.objectType}/${params.objectId}/actions/export`;

  // -----------------------------------------------------------------
  // pdf
  EXPORT_PDF = (id: ObjectId) => `/api/object/dopdf/${id}/export`;
  GET_EXPORT_PDF = (id: ObjectId) => `/api/object/dopdf/${id}/export/get`;
  GET_FILE_ONBOARDING = (type: string) => `/api/object/${type}/onboarding/get`;

  // -----------------------------------------------------------------

  constructor(params?: Omit<BaseServiceConstructor, 'baseURL'>) {
    super({ baseURL: getConfig().api, ...params });
  }

  list(type: ListableObjectType, params: MyAny) {
    return this.get(this.LIST(type), { params });
  }

  listSpaces(params: MyAny) {
    return this.get<
      paths['/api/object/space/list']['get']['responses']['200']['content']['application/json']
    >(this.LIST('space'), { params });
  }

  listObject(id: ObjectId, type: ListableObjectType | ObjectType, params: MyAny) {
    return this.get(this.LIST_ID(type, id), { params });
  }

  loadSpace(id: ObjectId, params: MyAny) {
    return this.get(this.LIST_ID('space', id), { params });
  }

  loadFolder(id: ObjectId, params: MyAny) {
    return this.get(this.LIST_ID('folder', id), { params });
  }

  createSpace(name: string, description: string) {
    return this.post<paths['/api/object/space/create']['post']['responses']['200']>(
      this.CREATE('space'),
      qs.stringify({ name, description }),
    );
  }

  deleteSpace(id: ObjectId) {
    return this.post(this.DELETE('space', id));
  }

  delete(parameters: MyAny) {
    return this.post(
      this.DELETE(parameters.objectType, parameters.objectId),
      qs.stringify(parameters),
    );
  }

  createObject(parameters: MyAny, type: ObjectType) {
    return this.post(this.CREATE(type), qs.stringify(parameters));
  }

  getPersonalSpaceInfo() {
    return this.get(this.PERSONAL_SPACE);
  }

  uploadFile(parameters: MyAny, progressCallback: Request.AxiosConfig['onUploadProgress']) {
    const formData = new FormData();
    formData.append('binary', parameters.file);
    formData.append('description', parameters.description);
    if (parameters.space) {
      formData.append('space', parameters.space);
    }
    if (parameters.parent) {
      formData.append('parent', parameters.parent);
    }

    return this.post(this.CREATE('file'), formData, {
      onUploadProgress: progressCallback,
    });
  }

  importFileContent(
    parameters: MyAny,
    progressCallback: Request.AxiosConfig['onUploadProgress'],
    objectType: 'document' | 'dopdf' | 'presentation',
  ) {
    const onUploadProgress = (progressEvent: any) => {
      const progress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);

      if (progressCallback) {
        progressCallback({
          percentage: progress,
        });
      }
    };

    const formData = new FormData();
    formData.append('file', parameters.file);
    formData.append('name', parameters.name);
    formData.append('description', parameters.description);

    if (parameters.space) {
      formData.append('space', parameters.space);
    }
    if (parameters.parent) {
      formData.append('parent', parameters.parent);
    }

    return this.post<
      | paths['/api/object/document/import']['post']['responses']['200']
      | paths['/api/object/dopdf/import']['get']['responses']['200']
    >(this.IMPORT_CONTENT({ objectType }), formData, {
      onUploadProgress,
    });
  }

  addPermission(parameters: MyAny) {
    return this.post(
      this.ADD_PERMISSION(parameters.objectType, parameters.objectId),
      qs.stringify({
        code: parameters.code,
        user: parameters.user,
        group: parameters.group,
        recursive: parameters.recursive,
      }),
    );
  }

  removePermission(parameters: Permission.Operation) {
    return this.post(
      this.REMOVE_PERMISSION(parameters.objectType, parameters.objectId),
      qs.stringify({
        code: parameters.code,
        user: parameters.user,
        group: parameters.group,
        recursive: parameters.recursive,
      }),
    );
  }

  listRecycle(params: MyAny) {
    return this.get(this.LIST_RECYCLE, { params });
  }

  restoreRecycle(id: ObjectId, params?: MyAny) {
    return this.post(this.RESTORE_RECYCLE(id), params);
  }

  getUserGroups(params: { users: UserId[] }) {
    return this.get<
      paths['/api/object/user/groups']['get']['responses']['200']['content']['multipart/form-data']
    >(this.GET_USER_GROUPS, { params });
  }

  getObjectData(
    parameters: { objectId: ObjectId; objectType: ObjectType; open?: boolean },
    config?: Request.Config,
  ) {
    return this.get(
      this.GET_DATA(parameters.objectType, parameters.objectId, parameters.open),
      config,
    );
  }

  rename(id: ObjectId, type: ObjectType, name: string) {
    return this.post(this.EDIT(type, id), qs.stringify({ name }));
  }

  editDescription(id: ObjectId, type: ObjectType, description: string) {
    return this.post(this.EDIT(type, id), qs.stringify({ description }));
  }

  addTag(type: ObjectType, id: ObjectId, tags: MyAny) {
    return this.post(this.ADD_TAG(type, id), qs.stringify({ id, tag: tags }));
  }

  removeTag(type: ObjectType, id: ObjectId, tags: MyAny) {
    return this.post(this.REMOVE_TAG(type, id), qs.stringify({ id, tag: tags }));
  }

  listTag() {
    return this.get(this.LIST_TAG);
  }

  listObjectPath(id: ObjectId) {
    return this.get(this.OBJECT_PATH(id));
  }

  editOverdue(id: ObjectId, overdue: MyAny) {
    return this.post(this.EDIT_OVERDUE(id), qs.stringify({ overdue }));
  }

  deleteWarningDate(id: ObjectId, warning: MyAny) {
    return this.post(this.REMOVE_WARNING(id), qs.stringify({ warning }));
  }

  saveWarningDate(id: ObjectId, warning: MyAny) {
    return this.post(this.ADD_WARNING(id), qs.stringify({ warning }));
  }

  move({ source, space, destination }: MyAny) {
    const data: MyAny = { source };
    if (space) {
      data.space = space;
    }
    if (destination) {
      data.destination = destination;
    }

    return this.post(this.MOVE(source), qs.stringify(data));
  }

  copy({
    sources,
    space,
    destination,
    name,
    keep_reviews,
  }: {
    sources: ObjectId[];
    space?: ObjectId;
    destination?: ObjectId;
    name?: string;
    keep_reviews: boolean;
  }) {
    const data: MyAny = { sources };
    if (space) {
      data.space = space;
    }
    if (destination) {
      data.destination = destination;
    }
    if (name) {
      data.name = name;
    }
    if (keep_reviews) {
      data.keep_reviews = keep_reviews;
    }

    return this.post(this.COPY, qs.stringify(data, { arrayFormat: 'brackets' }));
  }

  checkOutFile(id: ObjectId) {
    return this.post<paths['/api/object/file/{object_id}/checkout']['post']['responses']['200']>(
      this.CHECK_OUT(id),
      qs.stringify(id),
    );
  }

  checkOutFileClose(id: ObjectId) {
    return this.post<
      paths['/api/object/file/{object_id}/checkout/close']['post']['responses']['200']
    >(this.CHECK_OUT_CLOSE(id), qs.stringify(id));
  }

  // checkInFileConfirmation(id, { comment }, config) {
  //   return this.post(this.CHECK_IN_CONFIRM(id), qs.stringify({ comment }), config);
  // }

  uploadNewFileVersion(parameters: MyAny, operation: MyAny, config: Request.Config) {
    const formData = new FormData();
    formData.append('binary', parameters.file);
    formData.append('object_id', parameters.objectId);

    return this.post(this.UPLOAD_NEW_FILE_VERSION(operation), formData, config);
  }

  confirmNewFileVersion(id: ObjectId, { name, version, comment, close }: MyAny, operation: MyAny) {
    return this.post(
      this.CONFIRM_NEW_FILE_VERSION(id, operation),
      qs.stringify({ version, name, comment, close }),
    );
  }

  createRole(name: string, description: string) {
    return this.post(this.CREATE('role'), qs.stringify({ name, description }));
  }

  listRoles() {
    return this.get(this.LIST('role'));
  }

  deleteRole(id: ObjectId) {
    return this.post(this.DELETE('role', id));
  }

  getRole(id: ObjectId) {
    return this.get(this.GET_DATA('role', id));
  }

  createTag(tag: string) {
    return this.post(this.CREATE_TAG, qs.stringify({ tag }));
  }

  deleteTag(tag: string) {
    return this.post(this.DELETE_TAG, qs.stringify({ tag }));
  }

  uploadTag(parameters: MyAny, config: Request.Config) {
    const formData = new FormData();
    formData.append('file', parameters.file);
    formData.append('force', parameters.force);
    return this.post(this.UPLOAD_TAG, formData, config);
  }

  addObjectPermissionToRole(role: string, permissions: MyAny) {
    return this.post(this.ADD_OBJECT_PERMISSION_TO_ROLE(role), qs.stringify({ permissions }));
  }

  removeObjectPermissionFromRole(role: string, permissions: MyAny) {
    return this.post(this.REMOVE_OBJECT_PERMISSION_FROM_ROLE(role), qs.stringify({ permissions }));
  }

  getPublicGroups(params: MyAny) {
    return this.get<
      paths['/api/object/group/get']['get']['responses']['200']['content']['multipart/form-data']
    >(this.PUBLIC_GROUPS, { params });
  }

  addRoles(type: ObjectType, id: ObjectId, parameters: MyAny) {
    return this.post(
      this.ADD_ROLES(type, id),
      qs.stringify(parameters, { arrayFormat: 'brackets' }),
    );
  }

  removeRoles(type: ObjectType, id: ObjectId, parameters: MyAny) {
    return this.post(
      this.REMOVE_ROLES(type, id),
      qs.stringify(parameters, { arrayFormat: 'brackets' }),
    );
  }

  changeManager(type: ObjectType, id: ObjectId, parameters: { user: UserId; recursive: boolean }) {
    return this.post(this.CHANGE_MANAGER(type, id), qs.stringify(parameters));
  }

  checkJobStatus(params: MyAny) {
    return this.get(this.GET_JOB_STATUS, { params });
  }

  downloadFile(
    id: paths['/api/object/file/{object_id}/download']['get']['parameters']['path']['object_id'],
    type: 'file' | 'dopdf',
  ) {
    return this.get<Blob>(this.DOWNLOAD(type, id), { responseType: 'blob' });
  }

  downloadSource(
    id: paths['/api/object/document/{object_id}/source/download']['get']['parameters']['path']['object_id'],
  ) {
    return this.get<Blob>(this.DOWNLOAD_SOURCE(id), { responseType: 'blob' });
  }
  downloadPresentationSource(
    id: paths['/api/object/presentation/{object_id}/source/download']['get']['parameters']['path']['object_id'],
  ) {
    return this.get<Blob>(this.DOWNLOAD_PRESENTATION_SOURCE(id), { responseType: 'blob' });
  }

  downloadPDF(id: ObjectId) {
    return this.get<Blob>(this.DOWNLOAD_PDF(id), { responseType: 'blob' });
  }

  getObjectStatus(type: ObjectType, id: ObjectId) {
    return this.get<
      paths['/api/object/{object_type}/get/{object_id}/status']['get']['responses']['200']['content']['application/json']
    >(this.GET_OBJECT_STATUS(type, id));
  }

  // -----------------------------------------------------------------
  // ------------------------- Search --------------------------------

  searchObject(
    conditions: MyAny,
    params: Request.OrderListParams,
    query?: string,
    config?: Request.Config,
  ) {
    const queryOnly = { conditions: `query: ${query}` };
    const data = { conditions: JSON.stringify(conditions) };
    return this.post(this.SEARCH_OBJECT, qs.stringify(query ? queryOnly : data), {
      params,
      ...config,
    }); // TODO: this params is most likely wrong
  }

  // wopi
  createOtt() {
    return this.get(this.OTT_CREATE);
  }

  listAffiliations() {
    return this.get(this.AFFILIATIONS_LIST);
  }

  addAffiliationField(parameters: MyAny) {
    return this.post<paths['/api/object/affiliations/field/add']['post']['responses']['200']>(
      this.ADD_AFFILIATION_FIELD,
      qs.stringify({ field: parameters.field, value: parameters.value }),
    );
  }

  editAffiliationField(parameters: MyAny) {
    return this.post<paths['/api/object/affiliations/field/edit']['post']['responses']['200']>(
      this.EDIT_AFFILIATION_FIELD,
      qs.stringify({
        field: parameters.field,
        old: parameters.old,
        new: parameters.newValue,
      }),
    );
  }

  deleteAffiliationField(parameters: MyAny) {
    return this.post<paths['/api/object/affiliations/field/delete']['post']['responses']['200']>(
      this.DELETE_AFFILIATION_FIELD,
      qs.stringify({ field: parameters.field, value: parameters.value }),
    );
  }

  sendUserPing() {
    return this.post(this.USER_ACTIVE, null, {
      responseType: 'text',
    });
  }

  changeElementStatus({ objectId, objectType, status, comment, recursive }: MyAny) {
    return this.post(
      this.ELEMENT_STATUS_CHANGE(objectType, objectId),
      qs.stringify({ status, comment, recursive }),
    );
  }

  changeElementStatusAllowed({ id, type }: MyAny) {
    return this.get<
      paths['/api/object/{object_type}/{object_id}/change_status/allowed']['get']['responses']['200']['content']['application/json']
    >(this.ELEMENT_STATUS_CHANGE_ALLOWED(type, id));
  }

  editMetadata(id: ObjectId, parameters: MyAny) {
    return this.post<
      paths['/api/object/{element_type}/{document_id}/metadata/edit']['post']['responses']['200']
    >(this.EDIT_METADATA(id), qs.stringify(parameters));
  }

  listMetadata() {
    return this.get(this.METADATA_LIST);
  }

  listPersonalSavedFilter() {
    return this.get(this.PERSONAL_SAVED_FILTERS_LIST);
  }

  createSavedFilter({ name, filters }: MyAny) {
    return this.post(
      this.PERSONAL_SAVED_FILTERS_CREATE,
      qs.stringify({
        name,
        conditions: Array.isArray(filters) ? JSON.stringify(filters) : filters,
      }),
    );
  }

  deletePersonalSavedFilter({ savedFilterId }: MyAny) {
    return this.post(this.PERSONAL_SAVED_FILTERS_DELETE(savedFilterId));
  }

  // #region Notifications

  listNotifications(request: Partial<Request.ListParams & Request.FilterParams>) {
    return this.get<paths['/api/object/notification/list']['get']['responses']['200']>(
      this.NOTIFICATIONS_LIST,
      { params: request },
    );
  }

  editNotification(id: ObjectId, parameters: MyAny) {
    return this.post<
      paths['/api/object/notification/{notification_id}/edit']['post']['responses']['200']
    >(this.EDIT_NOTIFICATION(id), qs.stringify(parameters));
  }

  markAllNotificationsAsRead() {
    return this.post<paths['/api/object/notification/read']['post']['responses']['200']>(
      this.MARK_ALL_NOTIFICATIONS_AS_READ,
    );
  }

  getUnreadNotificationsCount(request: Request.FilterParams | null) {
    return this.get<
      paths['/api/object/notification/unread']['post']['responses']['200']['content']['application/json']
    >(this.GET_ALL_UNREAD_NOTIFICATIONS, { params: request });
  }
  // #endregion
  listAuditActionsLog({
    objectId,
    objectType,
    params,
  }: {
    objectId: paths['/api/object/{object_type}/{object_id}/actions']['get']['parameters']['path']['object_id'];
    objectType: paths['/api/object/{object_type}/{object_id}/actions']['get']['parameters']['path']['object_type'];
    params: Request.AllListParams;
  }) {
    return this.get<{
      nodes: paths['/api/object/{object_type}/{object_id}/actions']['get']['responses']['200'];
    }>(this.LIST_AUDIT_ACTIONS_LOG({ objectId, objectType }), { params });
  }

  async exportAuditLog({
    objectId,
    objectType,
    params,
  }: {
    objectId: paths['/api/object/{object_type}/{object_id}/actions/export']['get']['parameters']['path']['object_id'];
    objectType: paths['/api/object/{object_type}/{object_id}/actions/export']['get']['parameters']['path']['object_type'];
    params: Request.FilterParams;
  }) {
    const response = await this.get<Blob>(this.EXPORT_AUDIT_LOG({ objectId, objectType }), {
      params,
      responseType: 'blob',
    });

    const name = decodeURIComponent(response.headers['x-download-filename']);
    const url = window.URL.createObjectURL(response.data);
    var link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', name);
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the data file named "my_data.csv".
    link.remove(); // Clean up by removing element
  }

  exportPDF(id: ObjectId, options: MyAny) {
    return this.post<paths['/api/object/dopdf/{object_id}/export']['post']['responses']['200']>(
      this.EXPORT_PDF(id),
      qs.stringify(options, { arrayFormat: 'brackets' }),
    );
  }

  getExportPDF(id: ObjectId) {
    return this.get<Blob>(this.GET_EXPORT_PDF(id), { responseType: 'blob' });
  }

  convertFileTo(
    objectId:
      | paths['/api/object/dopdf/{object_id}/convert']['post']['parameters']['path']['object_id']
      | paths['/api/object/document/{object_id}/convert']['post']['parameters']['path']['object_id'],
    deleteSource:
      | paths['/api/object/dopdf/{object_id}/convert']['post']['requestBody']['content']['multipart/form-data']['delete_source']
      | paths['/api/object/document/{object_id}/convert']['post']['requestBody']['content']['multipart/form-data']['delete_source'],
    objectType: 'document' | 'dopdf' | 'presentation',
  ) {
    return this.post<
      | paths['/api/object/dopdf/{object_id}/convert']['post']['responses']['200']
      | paths['/api/object/document/{object_id}/convert']['post']['responses']['200']
    >(
      `/api/object/${objectType}/${objectId}/convert`,
      qs.stringify({ delete_source: deleteSource }),
    );
  }

  getPDFOnboarding() {
    return this.get<paths['/api/object/dopdf/onboarding/get']['get']['responses']['200']>(
      this.GET_FILE_ONBOARDING('dopdf'),
    );
  }

  getDocumentOnboarding() {
    return this.get<paths['/api/object/document/onboarding/get']['get']['responses']['200']>(
      this.GET_FILE_ONBOARDING('document'),
    );
  }
  getPresentationOnboarding() {
    return this.get<paths['/api/object/presentation/onboarding/get']['get']['responses']['200']>(
      this.GET_FILE_ONBOARDING('presentation'),
    );
  }
}
