import { useMemo } from 'react';
import { useTasks } from 'Presentation/SyncStore';
import { usePresentationCollaborators } from 'Presentation/hooks';

import TaskWatchersModal from '../TaskWatchersModal';

const PresentationData = () => {
  const data = useTasks()?.data;
  const collaborators = usePresentationCollaborators();

  const tasks = useMemo(() => {
    return Object.values(data ?? {});
  }, [data]);

  return <TaskWatchersModal tasks={tasks} collaborators={collaborators} />;
};

export default PresentationData;
