import { FormattedMessage } from 'react-intl';
import { Dropdown } from 'dodoc-design-system';

import { useDispatch } from '_common/hooks';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import Cell from '_common/components/Table/Cells';

type MoreOptionsProps = {
  object: Objekt;
};

const MoreOptions = ({ object }: MoreOptionsProps) => {
  const dispatch = useDispatch();

  const handleOnSettingsClick = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'PublicLinkModal',
        data: {
          linkId: object.id,
          mode: 'edit',
        },
      }),
    );
  };

  const handleOnDeleteClick = () => {
    dispatch(
      openAndUpdateModal({
        modal: 'ConfirmationModal',
        data: {
          title: 'DELETE_PUBLIC_LINK',
          message: 'DELETE_PUBLIC_LINK_MESSAGE',
          messageValues: { link: object.name },
          actionCode: 'deletePublicLink',
          actionValue: {
            objectId: object.object,
            linkId: object.id,
            linkName: object.name,
          },
          cancelButtonShow: true,
          cancelButtonTextId: 'global.cancel',
          confirmButtonTextId: 'DELETE_PUBLIC_LINK',
          headerType: 'error',
          confirmButtonType: 'danger',
          width: 60,
        },
      }),
    );
  };
  return (
    <Cell.Options
      items={
        <>
          <Dropdown.Item
            size="large"
            prefixIcon="Settings"
            onClick={handleOnSettingsClick}
            testId="public-link-row-actions-settings"
          >
            <FormattedMessage id="global.settings" />
          </Dropdown.Item>
          <Dropdown.Item
            size="large"
            prefixIcon="Delete"
            key="delete"
            onClick={handleOnDeleteClick}
            testId="public-link-row-actions-delete"
          >
            <FormattedMessage id="DELETE_PUBLIC_LINK" />
          </Dropdown.Item>
        </>
      }
      testId={`public-link-row-${object.id}-actions-toggle`}
    />
  );
};

export default MoreOptions;
