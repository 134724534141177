import { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Spinner } from 'dodoc-design-system';

import { InstanceService } from '_common/services';
import { useDispatch } from '_common/hooks';
import { updateData } from 'App/redux/appSlice';

import { NodeIcon } from '_common/components';

import styles from './Cell.module.scss';

type ObjectNameCellProps = {
  object: Objekt;
  testId: string;
};

const ObjectNameCell = ({ object, testId }: ObjectNameCellProps) => {
  const dispatch = useDispatch();

  const localObject = object?.object || object;
  const timeout = useRef<number>();

  useEffect(() => {
    handleObjectProcessing();
    return () => {
      if (timeout && timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, [object.id]);

  const handleObjectProcessing = () => {
    const { status, id, type } = object;

    if (status === 'processing') {
      timeout.current = window.setTimeout(() => {
        new InstanceService().getObjectStatus(type, id).then(({ data }) => {
          if (data.status !== 'processing') {
            dispatch(updateData({ objectId: id, data }));
          } else {
            handleObjectProcessing();
          }
        });
      }, 5000);
    }
  };

  const renderObjectName = () =>
    localObject.type === 'space' && localObject.personal ? (
      <FormattedMessage id="spaces.personalSpace" />
    ) : (
      localObject.name
    );

  return (
    <div className={styles.objectName}>
      <div className={styles.nodeIconWrapper}>
        {object.status === 'processing' ? (
          <Spinner variant="primary" size="medium" testId={`${testId}-processing`} />
        ) : (
          <NodeIcon object={localObject} />
        )}
      </div>
      <div className={styles.name}>
        {object.status === 'processing' ? (
          <FormattedMessage id="PROCESSING_ELEMENT" />
        ) : (
          renderObjectName()
        )}
      </div>
    </div>
  );
};

export default ObjectNameCell;
