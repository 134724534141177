import { Dispatch, FC, SetStateAction, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown, EmptyState, SectionHeader, Toggle, Tooltip } from 'dodoc-design-system';
import type { ColumnProps } from 'dodoc-design-system/build/types/Components/Table/Table';
import cn from 'classnames';
import { Table } from '_common/components/Table';

import { openModal } from 'App/ModalContext/utils';
import { useOrderMetadataMutation } from 'App/redux/MetadataApi';
import in_ from '_common/utils/in';
import { AUTOMATION_TRIGGER_TRANSLATIONS } from 'Settings/pages/TenantSettingsPage/Metadata/const';

import Cell from '_common/components/Table/Cells';

import styles from './Metadata.module.scss';

type AutomaticMetadataProps = {
  metadata: ApiSchemas['MetadataObjectSchema'][];
  selected: number;
  select: Dispatch<SetStateAction<number>>;
  deleteMetadata: (id: string, name: string) => void;
};

const AutomaticMetadata: FC<AutomaticMetadataProps> = ({
  metadata,
  selected,
  select,
  deleteMetadata,
}) => {
  const intl = useIntl();
  const selectedMetadata = metadata[selected];

  const [orderMetadata] = useOrderMetadataMutation();

  const columns = useMemo<ColumnProps[]>(() => {
    return [
      {
        id: 'name',
        width: 30 * 8,
        minWidth: 30 * 8,
        header: intl.formatMessage({ id: 'global.name' }),
        flex: true,
      },
      {
        id: 'automationTrigger',
        width: 26 * 8,
        header: intl.formatMessage({ id: 'AUTOMATION_TRIGGER' }),
      },
      {
        id: 'required',
        width: 10 * 8,
        header: intl.formatMessage({ id: 'REQUIRED' }),
      },
      {
        id: 'move',
        width: 9 * 8,
      },
    ];
  }, []);

  const edit = (metadata: ApiSchemas['MetadataObjectSchema']) => {
    openModal({
      modal: 'AutomaticMetadataModal',
      data: { metadata },
    });
  };

  const value = useMemo(() => {
    return metadata.map((m, index) => ({
      id: m.id,
      name: (
        <Cell testId={`${m.id}-name-column`} ellipsis>
          {m.name}
        </Cell>
      ),
      automationTrigger: (
        <Cell testId={`${m.id}-trigger-column`} ellipsis>
          {/* @ts-expect-error Metadata specs issue - trigger not a proprerty */}
          {in_(m.trigger, AUTOMATION_TRIGGER_TRANSLATIONS)
            ? // @ts-expect-error Metadata specs issue - trigger not a proprerty
              AUTOMATION_TRIGGER_TRANSLATIONS[m.trigger]
            : // @ts-expect-error Metadata specs issue - trigger not a proprerty
              m.trigger}
        </Cell>
      ),
      required: (
        <Cell testId={`${m.id}-required-column`} ellipsis>
          <div className={styles.capitalize}>{m.required.toString()}</div>
        </Cell>
      ),
      move: (
        <Cell testId={`${m.id}-move-column`}>
          <div style={{ display: 'flex' }}>
            <Tooltip
              testId={`${m.id}-move-up-tooltip`}
              content={<FormattedMessage id="MOVE_METADATA_UP" />}
              disabled={index === 0}
            >
              <Toggle
                testId={`${m.id}-move-up-toggle`}
                variant="link"
                size="small"
                icon="ElementListUpGrey"
                disabled={index === 0}
                onClick={(e) => handleMoveUp(e, m.id)}
              />
            </Tooltip>
            <Tooltip
              testId={`${m.id}-move-down-tooltip`}
              content={<FormattedMessage id="MOVE_METADATA_DOWN" />}
              disabled={index === metadata.length - 1}
            >
              <Toggle
                testId={`${m.id}-move-down-toggle`}
                variant="link"
                size="small"
                icon="ElementListDownGrey"
                disabled={index === metadata.length - 1}
                margin="0 0 0 1rem"
                onClick={(e) => handleMoveDown(e, m.id)}
              />
            </Tooltip>
          </div>
        </Cell>
      ),
      suffix: (
        <Cell testId={`${m.id}-options-column`}>
          <Cell.Options
            items={
              <>
                <Dropdown.Item
                  size="large"
                  onClick={(e) => {
                    e.stopPropagation();
                    edit(m);
                  }}
                  testId="edit-metadata-dropdown-item"
                >
                  <FormattedMessage id="EDIT_METADATA" />
                </Dropdown.Item>
                <Dropdown.Item
                  size="large"
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteMetadata(m.id, m.name);
                  }}
                  testId="delete-metadata-dropdown-item"
                >
                  <FormattedMessage id="DELETE_METADATA" />
                </Dropdown.Item>
              </>
            }
            placement="bottom-start"
            testId={m.id}
            stopEventPropagation
          />
        </Cell>
      ),
    }));
  }, [metadata]);

  const handleMoveUp = (e: React.MouseEvent<HTMLSpanElement>, metadataId: string) => {
    e.stopPropagation();
    orderMetadata({ object_id: metadataId, direction: 'up' });
  };
  const handleMoveDown = (e: React.MouseEvent<HTMLSpanElement>, metadataId: string) => {
    e.stopPropagation();
    orderMetadata({ object_id: metadataId, direction: 'down' });
  };

  return (
    <div className={styles.section}>
      {metadata.length === 0 ? (
        <EmptyState
          size="medium"
          testId="automatic-metadata-empty-state"
          title={intl.formatMessage({ id: 'NO_METADATA_IN_TENANT_TITLE' })}
        >
          <FormattedMessage id="NO_METADATA_IN_TENANT_BODY" />
        </EmptyState>
      ) : (
        <>
          <div className={styles.table}>
            <Table
              testId="automatic-metadata-table"
              columns={columns}
              value={value}
              onRowClick={({ index }) => {
                select(index);
              }}
              flex={false}
              maxHeight="75rem"
              selection={[{ id: selectedMetadata?.id }]}
              identity="automatic_metadata"
              isLoading={false}
              loadingLabel={intl.formatMessage({ id: 'LOADING_ELEMENTS' })}
              renderFooter={() => null}
              selectable={false}
              virtualized={false}
            />
          </div>
          {selectedMetadata && (
            <div className={styles.details}>
              <SectionHeader
                suffixButtonProps={{
                  testId: 'automatic-details-close-button',
                  icon: 'CloseGrey',
                  size: 'medium',
                  variant: 'link',
                  onClick: () => {
                    select(-1);
                  },
                }}
                margin="0 0 1rem 0"
              >
                {selectedMetadata.name}
              </SectionHeader>
              <div className={styles.content}>
                <div className={styles.field} data-testid="automatic-name-field">
                  <FormattedMessage id="global.name" />
                  <div className={styles.value}>{selectedMetadata.name}</div>
                </div>
                <div className={styles.field} data-testid="automatic-trigger-field">
                  <FormattedMessage id="AUTOMATION_TRIGGER" />
                  <div className={styles.value}>
                    {/* @ts-expect-error Metadata specs issue - trigger not a proprerty */}
                    {in_(selectedMetadata.trigger, AUTOMATION_TRIGGER_TRANSLATIONS)
                      ? // @ts-expect-error Metadata specs issue - trigger not a proprerty
                        AUTOMATION_TRIGGER_TRANSLATIONS[selectedMetadata.trigger]
                      : // @ts-expect-error Metadata specs issue - trigger not a proprerty
                        selectedMetadata.trigger}
                  </div>
                </div>
                <div className={styles.field} data-testid="automatic-required-field">
                  <FormattedMessage id="REQUIRED" />
                  <div className={cn(styles.value, styles.capitalize)}>
                    {selectedMetadata.required.toString()}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AutomaticMetadata;
