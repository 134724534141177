import { selectIsIEnvision } from 'App/redux/appSlice';
import { Toggle, Tooltip } from 'dodoc-design-system';
import { memo } from 'react';
import { useIntl } from 'react-intl';
import { InteractionController } from '_common/components';
import { useDispatch, useSelector } from '_common/hooks';
import { setRightPanel } from '../PresentationSlice';
import { useSuitePermissions } from '_common/suite';

const PresentationSidebar = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const rightPanel = useSelector((state) => state.presentation.general.rightPanel);
  const isIEnvision = useSelector(selectIsIEnvision);
  const { canAccessVersionHistory, canAccessTasks } = useSuitePermissions();

  const toggleRightPanel = (panel: typeof rightPanel) => {
    dispatch(setRightPanel(rightPanel === panel ? null : panel));
  };

  return (
    <InteractionController environment="presentation" style={{ flexDirection: 'column' }}>
      <Tooltip
        placement="left"
        content={intl.formatMessage({ id: 'DOCUMENT_DETAILS' })}
        testId="sidebar-details-tooltip"
      >
        <Toggle
          variant="ghost"
          size="medium"
          icon={rightPanel === 'details' ? 'InformationBlue' : 'InformationGrey'}
          isToggled={rightPanel === 'details'}
          onClick={() => toggleRightPanel('details')}
          margin="0 0 1rem 0"
          testId="sidebar-details-toggle"
        />
      </Tooltip>
      <Tooltip
        placement="left"
        content={intl.formatMessage({ id: 'COMMENTS' })}
        testId="sidebar-comments-tooltip"
      >
        <Toggle
          variant="ghost"
          size="medium"
          icon={rightPanel === 'comments' ? 'PDFCommentBlue' : 'PDFCommentGrey'}
          isToggled={rightPanel === 'comments'}
          onClick={() => toggleRightPanel('comments')}
          margin="0 0 1rem 0"
          testId="sidebar-comments-toggle"
        />
      </Tooltip>
      <Tooltip
        placement="left"
        content={
          canAccessTasks
            ? intl.formatMessage({ id: 'TASKS' })
            : intl.formatMessage({ id: 'NO_PERMISSION_TO_PERFORM_ACTION' })
        }
        testId="sidebar-tasks-tooltip"
      >
        <Toggle
          variant="ghost"
          size="medium"
          icon={rightPanel === 'tasks' ? 'TaskBlue' : 'TaskGrey'}
          isToggled={rightPanel === 'tasks'}
          onClick={() => toggleRightPanel('tasks')}
          margin="0 0 1rem 0"
          disabled={!canAccessTasks}
          testId="sidebar-tasks-toggle"
        />
      </Tooltip>
      <Tooltip
        content={
          !isIEnvision && canAccessVersionHistory
            ? intl.formatMessage({ id: 'VERSION_HISTORY' })
            : intl.formatMessage({ id: 'NO_PERMISSION_TO_PERFORM_ACTION' })
        }
        placement="left"
        testId="sidebar-version-history-tooltip"
      >
        <Toggle
          variant="ghost"
          size="medium"
          icon={rightPanel === 'versionhistory' ? 'VersionHistoryBlue' : 'VersionHistoryGrey'}
          isToggled={rightPanel === 'versionhistory'}
          onClick={() => toggleRightPanel('versionhistory')}
          margin="0 0 1rem 0"
          disabled={isIEnvision || !canAccessVersionHistory}
          testId="sidebar-version-history-toggle"
        />
      </Tooltip>
    </InteractionController>
  );
};

export default memo(PresentationSidebar);
