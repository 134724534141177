import { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'dodoc-design-system';

import { useSelector } from '_common/hooks';
import { DEFAULT_CARD_STYLES_PANEL } from 'PDF/redux/PDFAnnotationsSlice';

import RichTextEditor, {
  RichTextEditorHandler,
} from '_common/components/RichTextEditor/RichTextEditor';
import { SuiteAvatar } from '_common/suite/components';

import styles from './EditableReplyCard.module.scss';

type EditableReplyCardProps = {
  currentContent: string;
  collaborators: UserId[];
  onCancel: () => void;
  onApply: (newContent: string) => void;
  testId: string;
};

const EditableReplyCard = ({
  currentContent,
  collaborators,
  onCancel,
  onApply,
  testId,
}: EditableReplyCardProps) => {
  const intl = useIntl();

  const currentUserId = useSelector((state) => state.auth.userId);

  const replyRef = useRef<RichTextEditorHandler>(null);
  const [replyContent, setReplyContent] = useState(currentContent);

  const handleEditReply = () => {
    onApply(replyContent);
  };

  return (
    <div className={styles.root} data-testid={testId ? `${testId}-editable` : undefined}>
      <div className={styles.body}>
        <SuiteAvatar userId={currentUserId} />
        <RichTextEditor
          ref={replyRef}
          initialValue={currentContent}
          mentionableUsers={collaborators}
          placeholder={intl.formatMessage({ id: 'REPLY_PLACEHOLDER' })}
          overwrittenStyles={DEFAULT_CARD_STYLES_PANEL}
          onChange={setReplyContent}
          singleLine
          testId={testId}
        />
      </div>
      <div className={styles.footer}>
        <Button variant="link" size="small" onClick={onCancel} testId={`${testId}-cancel`}>
          <FormattedMessage id="global.cancel" />
        </Button>
        <Button
          variant="primary"
          size="small"
          onClick={handleEditReply}
          disabled={replyContent === ''}
          testId={`${testId}-apply`}
        >
          <FormattedMessage id={currentContent === '' ? 'global.create' : 'global.edit'} />
        </Button>
      </div>
    </div>
  );
};

export default EditableReplyCard;
