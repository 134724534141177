import { useGetElementStatusListQuery } from '_common/services/api/elementStatusApi';

import { Status } from '_common/components';

type ObjectStatusCellProps = {
  object: Objekt;
  testId: string;
};

const ObjectStatusCell = ({ object, testId }: ObjectStatusCellProps) => {
  const { status } = useGetElementStatusListQuery(undefined, {
    skip: !object,
    selectFromResult: ({ data }) => ({ status: data?.entities[object.status] }),
  });

  if (status?.visible) {
    return <Status id={object.status} fullWidth testId={`${testId}-label`} />;
  }
  return null;
};

export default ObjectStatusCell;
