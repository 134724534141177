import { useState, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Modal, Divider, EmptyState, Input } from 'dodoc-design-system';
import type { InputProps } from 'dodoc-design-system/build/types/Components/Input/Input';
import cx from 'classnames';

import { useDebounce, usePublicProfiles } from '_common/hooks';
import { useModalData } from 'App/ModalContext/ModalContext';
import { closeModal } from 'App/ModalContext/utils';

import UserCard from '_common/components/UserCard/UserCard';
import styles from './TotalUsersModal.module.scss';

type Message = {
  title: TranslationMessage;
  input: { placeholder: TranslationMessage };
  emptyState: { title: TranslationMessage; body: TranslationMessage };
};

/**
 * This modal is supporting 2 different designs versions: the legacy design and the "latest" design.
 * The ideia is to eventually have only the latest design
 * After talking with Mariana about this, she decided that the mockups that use the legacy design
 * will only be updated after the integration of the EnvisionOne DesignSystem.
 * TODO: Remove the legacy design after update of the mockups.
 * Just have to remove the "legacy" conditions and styles.legacy (also remove from css file)
 */

const TotalUsersModal = () => {
  const intl = useIntl();

  const { users, elementType, action, editorAvatar, legacy } = useModalData('TotalUsersModal') ?? {
    users: [],
  };

  const messages = useMemo<Message | undefined>(() => {
    if (!action) {
      return undefined;
    }
    let messages: { [x in NonNullable<typeof action>]?: Message } = {};

    switch (elementType) {
      case 'comment': {
        messages = {
          likes: {
            title: { id: legacy ? 'LIKES_ON_CARD' : 'LIKES' },
            input: {
              placeholder: { id: 'SEARCH_USERS_WHO_LIKED_THIS_COMMENT' },
            },
            emptyState: {
              title: { id: 'NO_USERS_FOUND' },
              body: { id: 'NO_USERS_WHO_LIKED_MATCH_FILTER' },
            },
          },
        };
        break;
      }
      case 'annotation': {
        messages = {
          likes: {
            title: { id: legacy ? 'LIKES_ON_CARD' : 'LIKES' },
            input: {
              placeholder: { id: 'SEARCH_USERS_WHO_LIKED_THIS_COMMENT' },
            },
            emptyState: {
              title: { id: 'NO_USERS_FOUND' },
              body: { id: 'NO_USERS_WHO_LIKED_MATCH_FILTER' },
            },
          },
        };
        break;
      }
      case 'change': {
        messages = {
          likes: {
            title: { id: 'LIKES_ON_CARD' },
            input: {
              placeholder: { id: 'SEARCH_USERS_WHO_LIKED_THIS_CHANGE' },
            },
            emptyState: {
              title: { id: 'NO_USERS_FOUND' },
              body: { id: 'NO_USERS_WHO_LIKED_MATCH_FILTER' },
            },
          },
        };
        break;
      }
      case 'reply': {
        messages = {
          likes: {
            title: { id: legacy ? 'LIKES_ON_CARD' : 'LIKES' },
            input: {
              placeholder: { id: 'SEARCH_USERS_WHO_LIKED_THIS_REPLY' },
            },
            emptyState: {
              title: { id: 'NO_USERS_FOUND' },
              body: { id: 'NO_USERS_WHO_LIKED_MATCH_FILTER' },
            },
          },
        };
        break;
      }
      case 'document':
      case 'dopdf':
      case 'presentation':
      case 'file':
      case 'folder':
      case 'group':
      case 'space': {
        messages = {
          collaborators: {
            title: { id: 'COLLABORATORS' },
            input: { placeholder: { id: 'SEARCH_USERS_IN_THIS_ELEMENT' } },
            emptyState: {
              title: { id: 'NO_COLLABORATORS_FOUND' },
              body: { id: 'SORRY_THERE_ARE_NO_COLLABORATORS_FOUND', values: { br: <br /> } },
            },
          },
        };
        break;
      }
    }

    return messages[action];
  }, [elementType, action, legacy]);

  const { profiles } = usePublicProfiles(users.map((userId) => userId));

  const [search, setSearch] = useState('');
  const [filteredUsers, setFilteredUsers] = useState<typeof users>([]);
  const debouncedSearch = useDebounce(search, 250);

  useMemo(() => {
    const filteredUsers = users?.filter((userId) =>
      profiles[userId]?.name.toLowerCase().includes(search.toLowerCase()),
    );
    if (filteredUsers) {
      setFilteredUsers(filteredUsers);
    }
  }, [debouncedSearch]);

  const handleSearchChange: InputProps['onChange'] = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  const close = () => {
    closeModal('TotalUsersModal');
  };

  if (!messages) {
    return null;
  }

  return (
    <Modal width="60rem" open onClose={close} testId="total-users">
      <Modal.Header onClose={close}>
        <FormattedMessage id={messages.title.id} values={messages.title.values} />
      </Modal.Header>
      <Modal.Body>
        <div className={styles.search}>
          <Input
            prefix="NavSearchGrey"
            size="medium"
            placeholder={intl.formatMessage({ id: messages.input.placeholder.id })}
            value={search}
            onChange={handleSearchChange}
            testId="search"
          />
        </div>

        {filteredUsers.length < 1 && search !== '' && (
          // TODO: EmptyState component is missing top spacing, remove this div when it's fixed
          <div style={{ paddingTop: '2rem' }}>
            <EmptyState
              size="large"
              icon="NoSearchResults"
              title={intl.formatMessage(
                {
                  id: messages.emptyState.title.id,
                },
                messages.emptyState.title.values,
              )}
              testId="no-users-found"
            >
              <FormattedMessage
                id={messages.emptyState.body.id}
                values={messages.emptyState.body.values}
              />
            </EmptyState>
          </div>
        )}
        {filteredUsers.length > 0 && (
          <div className={styles.list}>
            {filteredUsers.map((userId) => (
              <div key={userId} className={cx(styles.row, { [styles.legacy]: legacy })}>
                <div className={styles.item}>
                  <UserCard
                    userId={userId}
                    editor={!!editorAvatar}
                    size="large"
                    titleStyle={legacy ? undefined : { fontSize: '1.5rem' }}
                  />
                </div>
                {(legacy || filteredUsers.length > 1) && <Divider />}
              </div>
            ))}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="close-button">
          <FormattedMessage id="global.close" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TotalUsersModal;
