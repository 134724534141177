import { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useDispatch, useSelector } from '_common/hooks';
import { InstanceService } from '_common/services';
import { viewPersonalSpaces } from 'Storage/pages/SpacesListPage/redux/spacesListPageSlice';
import { listObjects } from '_common/components/Table/TableSlice';
import { navigateToObject } from 'router/history';

import { IntlErrorBoundary, NodeIcon, UsernameLabel } from '_common/components';

import Header from './Header';
import { ThunksTable } from '_common/components/Table';
import { Dropdown } from 'dodoc-design-system';
import Cell from '_common/components/Table/Cells';
import Footer from '_common/components/Table/Footer/Footer';
import SelectionCounter from '_common/components/Table/Footer/SelectionCounter';
import { ColumnProps, TableProps } from 'dodoc-design-system/build/types/Components/Table/Table';

import styles from './PersonalSpacesListPage.module.scss';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
const PAGE_IDENTITY = 'personal_spaces';

const PersonalSpacesListPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const appData = useSelector((state) => state.app.data);
  const list = useSelector((state) => state.table.identity.personal_spaces.list);

  //#region Table object listing handlers
  const fetchObjects = useCallback(async (parameters: any) => {
    const res = await new InstanceService().listSpaces({ ...parameters, personals: true });
    return res;
  }, []);

  useEffect(() => {
    dispatch(
      listObjects({
        identity: PAGE_IDENTITY,
        fetch: fetchObjects,
        cause: 'INITIAL',
        request: { offset: 0 },
      }),
    );
  }, []);

  // No useEffectOnUpdate since fetchObjects doesn't have any dependencies
  // Add if dependencies are added to fetchObjects
  //#endregion

  useEffect(() => {
    return () => {
      dispatch(viewPersonalSpaces(false));
    };
  }, []);

  const handleOpenDeleteSpaceModal = (object: Objekt) => {
    dispatch(
      openAndUpdateModal({
        modal: 'ConfirmationModal',
        data: {
          title: 'DELETE_ITEM_QUANTITY',
          titleValues: { total: 1 },
          message: 'DELETING_THE_SELECTED_ITEM_QUANTITY_WILL_PERMANENTLY_REMOVE_CONFIRM',
          messageValues: { total: 1 },
          confirmButtonTextId: 'global.delete',
          confirmButtonType: 'danger',
          cancelButtonTextId: 'global.cancel',
          actionCode: 'deleteObjects',
          actionValue: { objectList: [object], identity: 'spaces' },
          headerType: 'error',
          cancelButtonShow: true,
        },
      }),
    );
  };

  const columns = useMemo<ColumnProps[]>(() => {
    return [
      {
        id: 'name',
        header: intl.formatMessage({ id: 'storage.browserHeader.name' }),
        orderable: true,
        width: 296,
        flex: true,
      },
      {
        id: 'time.modification',
        header: intl.formatMessage({ id: 'storage.browserHeader.dateModified' }),
        orderable: true,
        width: 192,
        body: (data) => {
          return data['time.modification'];
        },
      },
      {
        id: 'sharedWith',
        header: intl.formatMessage({ id: 'storage.browserHeader.sharedWith' }),
        width: 160,
      },
    ];
  }, []);

  const value = useMemo(() => {
    return list?.map((objectId: string | number) => {
      const object = appData[objectId];
      return {
        id: object.id,
        name: (
          <Cell testId={`${objectId}-name-column`} ellipsis>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
              {object.personal ? (
                <>
                  <NodeIcon object={object} />
                  <div
                    style={{
                      paddingLeft: '2rem',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <UsernameLabel userId={object?.owner} />
                  </div>
                </>
              ) : (
                <Cell.ObjectName
                  object={object}
                  testId={`personal-spaces-${object.id}-object-name`}
                />
              )}
            </div>
          </Cell>
        ),
        'time.modification': (
          <Cell testId={`${objectId}-date-modified-column`}>
            <Cell.DateTime
              date={{ date: object.time.modification }}
              time={{ time: object.time.modification }}
            />
          </Cell>
        ),
        sharedWith: (
          <Cell testId={`${objectId}-shared-column`}>
            <Cell.Collaborators object={object} personalLabel />
          </Cell>
        ),
        suffix: (
          <Cell testId={`${objectId}-suffix-column`}>
            <Cell.Options
              disabled={object.personal}
              items={
                <Dropdown.Item
                  size="large"
                  prefixIcon="Delete"
                  key="delete"
                  onClick={() => {
                    handleOpenDeleteSpaceModal(object);
                  }}
                  testId="delete"
                >
                  <FormattedMessage id="spaces.deleteSpace" />
                </Dropdown.Item>
              }
              testId={`personal-spaces-${object.id}`}
            />
          </Cell>
        ),
      };
    });
  }, [list, appData]);

  const handleRowDoubleClick: TableProps<{ id: string }[]>['onRowDoubleClick'] = ({ data }) => {
    navigateToObject('space', data?.id);
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <IntlErrorBoundary size="small" margin="0 0 0 7rem">
          <Header />
        </IntlErrorBoundary>
      </div>
      <div className={styles.content}>
        <ThunksTable
          identity={PAGE_IDENTITY}
          columns={columns}
          value={value}
          fetchObjects={fetchObjects}
          onRowDoubleClick={handleRowDoubleClick}
          loadingLabel={intl.formatMessage({ id: 'LOADING_ELEMENTS' })}
          renderFooter={() => (
            <Footer>
              <SelectionCounter identity={PAGE_IDENTITY} />
            </Footer>
          )}
          testId="personal-spaces-table"
        />
      </div>
    </div>
  );
};

export default PersonalSpacesListPage;
