import { memo, Suspense, useMemo } from 'react';
import { usePopper } from 'dodoc-design-system';
import cx from 'classnames';

import { useSelector, useDispatch } from '_common/hooks';
import { setCreating } from 'Presentation/PresentationSlice';
import { usePresentationData } from 'Presentation/PresentationData';
import SlideDataProvider from './Slide/SlideData';
import { useFilteredComments, useFilteredTasks } from 'Presentation/hooks';

import { InteractionController } from '_common/components';

import Slide from './Slide/Slide';
import SlideContextMenu from './Slide/SlideContextMenu';
import Comments from './Comments/Comments';
import SlideOverlay from './Slide/SlideOverlay/SlideOverlay';

import styles from './Slides.module.scss';

const Slides = () => {
  const dispatch = useDispatch();
  const { structure, size } = usePresentationData();

  const zoom = useSelector((state) => state.presentation.general.zoom);
  const currentSlide = useSelector((state) => state.presentation.general.currentSlide) - 1;
  const creating = useSelector((state) => state.presentation.general.creating);
  const rightPanelIsOpen = useSelector((state) => state.presentation.general.rightPanel);
  const selectedCard = useSelector((state) => state.presentation.general.selectedCard);

  const currentSlideId = useMemo(() => structure.sldIdLst[currentSlide], [currentSlide, structure]);

  const slideComments = useFilteredComments(currentSlideId);
  const slideTasks = useFilteredTasks(currentSlideId);

  const anySlideCardIsActive = useMemo(() => {
    return (
      slideComments.find((comment) => comment.id === selectedCard) ||
      slideTasks.find((task) => task.id === selectedCard)
    );
  }, [slideComments, slideTasks, selectedCard]);

  const { popperProps, referenceProps } = usePopper({ trigger: 'contextmenu' });

  const slideOnClick = () => {
    if (creating && !rightPanelIsOpen) {
      dispatch(setCreating(null));
    }
  };

  return (
    <Suspense fallback={<div>loading slide…</div>}>
      {currentSlideId && (
        <SlideDataProvider slideId={currentSlideId}>
          <div
            className={cx(styles.slideContainer, {
              [styles.border]: creating?.slide || anySlideCardIsActive,
            })}
          >
            <InteractionController
              environment="presentation"
              rules={[{ interaction: 'presentation_slide_layer' }]}
            >
              <div
                ref={referenceProps.ref}
                onContextMenuCapture={referenceProps.onContextMenuCapture}
                onClick={slideOnClick}
                style={{ height: size.height * zoom, width: size.width * zoom }}
                id={currentSlideId}
                data-type="slide"
              >
                <Slide key={currentSlideId} />
                <SlideOverlay />
              </div>
            </InteractionController>
            <InteractionController
              environment="presentation"
              rules={[{ interaction: 'presentation_slide_comments_layer' }]}
            >
              <Comments slideId={currentSlideId} />
            </InteractionController>
          </div>
          <InteractionController environment="presentation" style={{ width: '100%' }}>
            <SlideContextMenu popperProps={popperProps} />
          </InteractionController>
        </SlideDataProvider>
      )}
    </Suspense>
  );
};

export default memo(Slides);
