import { Button, Tooltip } from 'dodoc-design-system';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useIEnvision } from '_common/hooks';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';
import { openModal } from 'App/ModalContext/utils';
import { setAppLoading } from 'App/redux/appSlice';
import { notify } from '_common/components/ToastSystem';
import { useJobs } from '_common/components/JobsContext/JobsContext';
import { useDownloadPresentationMutation, useExportPresentationMutation } from 'Presentation/api';
import downloadFileFromBlob from '_common/utils/downloadFileFromBlob';

type ExportButtonProps = {
  object: doDOC.SuiteObject;
};

const ExportButton = ({ object }: ExportButtonProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isIEnvision = useIEnvision();
  const { addJob } = useJobs();
  const [exportPresentation] = useExportPresentationMutation();
  const [downloadPresentation] = useDownloadPresentationMutation();

  const handleExport = () => {
    switch (object.type) {
      case 'dopdf':
        dispatch(
          openAndUpdateModal({
            modal: 'ExportPDFModal',
            data: {
              id: object.id,
            },
          }),
        );
        break;
      case 'document':
        dispatch(
          openAndUpdateModal({
            modal: 'ExportDocumentModal',
            data: {
              exportType: 'simple',
              objectId: object.id,
            },
          }),
        );
        break;
      case 'presentation':
        openModal({
          modal: 'ExportModal',
          data: {
            objectName: object.name,
            message: 'YOU_ARE_ABOUT_TO_EXPORT_DOPPT',
            checkboxMessage: 'YES_INCLUDE_TASKS_AS_COMMENTS',
            onExport: async (withTasks) => {
              dispatch(
                setAppLoading({
                  isOpen: true,
                  message: 'EXPORTING_DOCUMENT',
                }),
              );
              const result = await exportPresentation({
                object_id: object.id,
                keep_tasks: withTasks,
              }).unwrap();
              addJob(result.id, async () => {
                const blobData = await downloadPresentation({
                  object_id: object.id,
                }).unwrap();

                downloadFileFromBlob(blobData, `${object.name}.pptx`);

                notify({
                  type: 'success',
                  title: 'DOPPT_EXPORTED',
                  message: 'DOPPT_WAS_EXPORTED_AND_DOWNLOADED_EXPORT_MANUALLY',
                  messageValues: {
                    name: object.name,
                  },
                  persist: true,
                  footerContent: (
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button
                        size="small"
                        onClick={() => {
                          downloadFileFromBlob(blobData, `${object.name}.pptx`);
                        }}
                        testId="download-manually-button"
                      >
                        <FormattedMessage id="DOWNLOAD_MANUALLY" />
                      </Button>
                    </div>
                  ),
                });
                dispatch(setAppLoading({ isOpen: false }));
              });
            },
          },
        });
        break;
    }
  };

  return (
    <Tooltip
      content={intl.formatMessage({ id: 'NO_PERMISSION_TO_PERFORM_ACTION' })}
      placement="bottom"
      disabled={!isIEnvision}
      testId="export-no-permission-tooltip"
    >
      <Button
        variant="neutral"
        size="medium"
        margin="0 0 0 1rem"
        onClick={handleExport}
        disabled={isIEnvision}
        testId="export-button"
      >
        <FormattedMessage id="global.export" />
      </Button>
    </Tooltip>
  );
};

export default ExportButton;
