import { Tooltip } from 'dodoc-design-system';
import UsernameLabel from '_common/components/Labels/UsernameLabel/UsernameLabel';
import styles from './MultipleUsersAvatar.module.scss';
import { FormattedMessage } from 'react-intl';
import { openModal } from 'App/ModalContext/utils';

type MultipleUsersAvatarProps = {
  usersId: UserId[];
  overflownUsersId: UserId[];
  elementType: doDOC.BaseObject['type'] | doDOC.Space['type'] | doDOC.Group['type'];
  testId: string;
};

const MultipleUsersAvatar = ({
  usersId,
  overflownUsersId,
  testId,
  elementType,
}: MultipleUsersAvatarProps) => {
  const openTotalUsersModal = () => {
    openModal({
      modal: 'TotalUsersModal',
      data: { users: usersId, elementType, action: 'collaborators', legacy: true },
    });
  };

  if (!overflownUsersId.length) {
    return null;
  }

  return (
    <Tooltip
      content={
        <div>
          <UsernameLabel userId={overflownUsersId[0]} />
          {overflownUsersId.length > 1 && (
            <>
              {' '}
              <FormattedMessage
                id={'AND_X_OTHER_USERS'}
                values={{ total: overflownUsersId.length - 1 }}
              />
            </>
          )}
        </div>
      }
      testId={`${testId}-tooltip`}
    >
      <div className={styles.root} tabIndex={0} onClick={openTotalUsersModal}>
        +{overflownUsersId.length}
      </div>
    </Tooltip>
  );
};

export default MultipleUsersAvatar;
