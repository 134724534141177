import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, Button, Icon, Divider } from 'dodoc-design-system';

import { useModalData } from 'App/ModalContext/ModalContext';
import { closeModal } from 'App/ModalContext/utils';

import { SearchUser, UserCard } from '_common/components';

import styles from './TaskWatchersModal.module.scss';

type TaskWatchersModalProps = { tasks: Suite.Task.Base[]; collaborators: string[] };

const TaskWatchersModal = ({ tasks, collaborators }: TaskWatchersModalProps) => {
  const intl = useIntl();

  const [selectedWatchers, setSelectedWatchers] = useState<
    {
      value: string;
      label: string;
      type: string;
    }[]
  >([]);

  const modalData = useModalData('TaskWatchersModal');

  const task = useMemo(() => {
    return tasks.find((task) => task.id === modalData?.taskId) ?? null;
  }, [modalData, tasks]);

  const possibleWatchers = useMemo(() => {
    if (!task) {
      return [];
    }

    const { watchers, assignee, authorId } = task;

    return collaborators
      .filter(
        (id) => id !== assignee && id !== authorId && !watchers.some((watcher) => watcher === id),
      )
      .map((id) => ({ id, type: 'user' }));
  }, [task]);

  const taskWatchers = useMemo<
    Array<{ userId: string; role: 'author' | 'assignee' | 'watcher' }>
  >(() => {
    if (!task) {
      return [];
    }

    const { watchers, assignee, authorId } = task;

    const taskWatchers: Array<{ userId: string; role: 'author' | 'assignee' | 'watcher' }> = [
      { userId: authorId, role: 'author' },
    ];

    if (assignee) {
      taskWatchers.push({ userId: assignee, role: 'assignee' });
    }

    if (watchers) {
      taskWatchers.push(
        ...watchers
          .filter((id: UserId) => id !== assignee && id !== authorId)
          .map<{ userId: string; role: 'watcher' }>((userId) => ({
            userId,
            role: 'watcher',
          })),
      );
    }

    return taskWatchers;
  }, [task]);

  const getRoleTranslation = (role: 'author' | 'assignee') => {
    switch (role) {
      case 'author':
        return intl.formatMessage({ id: 'TASK_CREATOR' });
      case 'assignee':
        return intl.formatMessage({ id: 'TASK_ASSIGNEE' });
    }
  };

  if (!modalData) {
    return null;
  }

  const { removeWatcher, addWatcher, canEditWatch } = modalData;

  const close = () => {
    closeModal('TaskWatchersModal');
  };

  return (
    <Modal open onClose={close} width="60rem" testId="task-watch">
      <Modal.Header onClose={close}>
        <FormattedMessage id="WATCH_OPTION" />
      </Modal.Header>
      <Modal.Body overflow="visible">
        <SearchUser
          escapeClearsValue
          value={selectedWatchers}
          onChange={(users, actionMeta) => {
            setSelectedWatchers(users);
            if (actionMeta?.action === 'select-option' && actionMeta?.option) {
              addWatcher(actionMeta.option.value);
            } else if (actionMeta?.action === 'deselect-option' && actionMeta.option) {
              removeWatcher(actionMeta.option.value);
            } else if (actionMeta?.action === 'remove-value' && actionMeta.removedValue) {
              removeWatcher(actionMeta.removedValue.value);
            } else if (actionMeta?.action === 'clear' && actionMeta.removedValues) {
              actionMeta.removedValues.forEach((removedWatcher) => {
                removeWatcher(removedWatcher.value);
              });
            }
          }}
          size="medium"
          placeholder={intl.formatMessage({ id: 'SEARCH_USERS_WHO_ARE_WATCHING_THIS_TASK' })}
          width="100%"
          menuPosition="fixed"
          options={possibleWatchers}
          editor
          isMulti
          disabled={!canEditWatch}
          searchable
          onMenuClose={() => {
            setSelectedWatchers([]);
          }}
          testId="task-watch"
        />
        <div className={styles.list}>
          {taskWatchers.map(({ userId, role }) => (
            <div key={`${userId + role}`} className={styles.row}>
              <div className={styles.watcher}>
                <UserCard userId={userId} size="large" titleStyle={{ fontSize: '1.5rem' }} />
                {role === 'author' || role === 'assignee' ? (
                  <div className={styles.user_label}>{getRoleTranslation(role)}</div>
                ) : (
                  <div
                    style={{ cursor: canEditWatch ? 'pointer' : 'inherit' }}
                    onClick={() => {
                      if (canEditWatch) {
                        removeWatcher(userId);
                      }
                    }}
                  >
                    <Icon icon="CloseGrey" size={24} disabled={!canEditWatch} />
                  </div>
                )}
              </div>
              {taskWatchers.length > 1 && <Divider />}
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="medium" onClick={close} testId="task-watch-modal-done-button">
          <FormattedMessage id="global.close" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TaskWatchersModal;
