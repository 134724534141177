import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, EmptyState, SectionHeading } from 'dodoc-design-system';

import {
  useDeleteConnectionMutation,
  useListConnectionsQuery,
  useListServicesQuery,
} from '../UserSettingsApi';

import { Table } from '_common/components/Table';

import styles from './Authentication.module.scss';
import Cell from '_common/components/Table/Cells';
import { ColumnProps } from 'dodoc-design-system/build/types/Components/Table/Table';
import IntlErrorBoundary from '_common/components/IntlErrorBoundary/IntlErrorBoundary';

const Authentication = () => {
  const intl = useIntl();

  const { data: providerList } = useListServicesQuery();
  const createAndDelete = providerList?.createAndDelete || false;
  const totalProviders = providerList?.providers.length || 0;
  const { data: connections } = useListConnectionsQuery();
  const [deleteConnection] = useDeleteConnectionMutation();

  const columns = useMemo<ColumnProps[]>(() => {
    return [
      {
        id: 'id',
        header: intl.formatMessage({ id: 'settings.authentication.id' }),
        width: 488,
      },
      {
        id: 'service',
        header: intl.formatMessage({ id: 'settings.authentication.service' }),
        width: 184,
      },
      {
        id: 'creation',
        header: intl.formatMessage({ id: 'settings.authentication.creation' }),
        width: 192,
      },
      {
        id: 'remove',
        width: 95,
      },
    ];
  }, []);

  const value = useMemo(() => {
    return (
      connections &&
      connections.map((connection) => {
        return {
          id: connection.uuid,
          service: (
            <Cell testId={`${connection.uuid}-service-column`} ellipsis>
              {connection.provider}
            </Cell>
          ),
          creation: (
            <Cell testId={`${connection.uuid}-creation-column`}>
              <Cell.DateTime
                date={{ date: connection.creation }}
                time={{ time: connection.creation }}
              />
            </Cell>
          ),
          remove: createAndDelete ? (
            <Cell testId={`${connection.uuid}-remove-column`}>
              <Button
                size="small"
                onClick={() => {
                  deleteConnection(connection.pk);
                }}
                testId={`${connection.uuid}-remove-button`}
              >
                <FormattedMessage id="global.remove" />
              </Button>
            </Cell>
          ) : null,
        };
      })
    );
  }, [connections, createAndDelete]);

  const renderNoAuthenticators = () => {
    return (
      <div className={styles.noAuthenticators}>
        <EmptyState
          size="medium"
          title={intl.formatMessage({ id: 'settings.authentication.paragraph1' })}
          testId="no-authenticators"
        >
          <FormattedMessage id="settings.authentication.paragraph2" />
        </EmptyState>
      </div>
    );
  };

  const renderEmptyMessage = () => {
    return (
      <div className={styles.emptyMessage}>
        <FormattedMessage id="settings.authentication.empty" />
      </div>
    );
  };

  if (totalProviders === 0) {
    return renderNoAuthenticators();
  }

  return (
    <>
      <div className={styles.container}>
        <IntlErrorBoundary fallbackType="sectionHeading" mockProps={{ sticky: true }}>
          <SectionHeading
            sticky
            title={intl.formatMessage({ id: 'settings.authentication.header' })}
            testId="active-connections-header"
          />
        </IntlErrorBoundary>
        {connections?.length === 0 ? (
          renderEmptyMessage()
        ) : (
          <IntlErrorBoundary size="large" margin="10rem 0 0 0">
            <div className={styles.root}>
              <Table
                identity="userPreferences/authentication"
                columns={columns}
                value={value}
                renderFooter={() => null}
                isLoading={false}
                loadingLabel={intl.formatMessage({ id: 'LOADING_ELEMENTS' })}
                testId="userPreferences_authentication"
                selectable={false}
                virtualized={false}
              />
            </div>
          </IntlErrorBoundary>
        )}
      </div>
    </>
  );
};

export default Authentication;
