import { FormattedMessage } from 'react-intl';
import { Toggle, Card, Dropdown, usePopper, Tooltip } from 'dodoc-design-system';

import { DEFAULT_CARD_STYLES_PANEL } from 'PDF/redux/PDFAnnotationsSlice';

import { UserPresentation } from '_common/components';

import styles from './ViewReplyCard.module.scss';
import LikeToggle from '_common/suite/components/Card/LikeToggle/LikeToggle';
import ViewRichTextEditor from '../../../ViewRichTextEditor/ViewRichTextEditor';
import { TooltipProps } from 'dodoc-design-system/build/types/Components/Tooltip/Tooltip';

export type ViewReplyCardProps = {
  reply: Card.Reply;
  optionsToggleTooltip?: Omit<TooltipProps, 'children'>;
  likeToggleTooltip?: Omit<TooltipProps, 'children'>;
  onStartEdit: () => void;
  voteReply: (payload: { replyId?: string | undefined; currentUserLiked: boolean }) => void;
  deleteReply: (replyId: string) => void;
  canEditReply: (replyId: string) => boolean;
  canDeleteReply: (replyId: string) => boolean;
  canVoteReply: (replyId: string) => boolean;
  legacyModal?: boolean;
  /** ID to be used in unit or automated tests.
   * Will result in 4 different attributes:
   * * ${testId}-view
   * * ${testId}-moreOptions
   * * ${testId}-moreOptions-edit
   * * ${testId}-moreOptions-delete
   */
  testId: string;
};

const ViewReplyCard = ({
  reply,
  optionsToggleTooltip = { disabled: true, content: '', testId: 'options-tooltip' },
  likeToggleTooltip = { disabled: true, content: '', testId: 'like-tooltip' },
  voteReply,
  onStartEdit,
  deleteReply,
  canEditReply,
  canDeleteReply,
  canVoteReply,
  legacyModal,
  testId,
}: ViewReplyCardProps) => {
  const { referenceProps, popperProps } = usePopper({ closeOnReferenceHidden: true });

  const handleDeleteReply = async () => {
    deleteReply(reply.id);
  };

  return (
    <div className={styles.replyCard} data-testid={`${testId}-view`}>
      <Card.Header size="medium">
        <div className={styles.header}>
          <UserPresentation
            userId={reply.authorId}
            // @ts-expect-error Uniformize Annotations with Presentation comment type (missing authorName)
            fixedName={!reply.authorId ? reply.title ?? reply.authorName : undefined}
            // @ts-expect-error Uniformize Annotations with Presentation comment type (has 'creationDate' instead of 'time')
            creationDate={reply.creationDate ?? reply.time}
          />
          <Tooltip {...optionsToggleTooltip}>
            <Toggle
              size="medium"
              variant="link"
              margin="0 0 0 auto"
              icon={popperProps.isOpen ? 'PDFOptionsBlue' : 'PDFOptionsGrey'}
              isToggled={popperProps.isOpen}
              testId={`${testId}-moreOptions`}
              {...referenceProps}
            />
          </Tooltip>
          <Dropdown {...popperProps} testId={`${testId}-options-dropdown`}>
            <Dropdown.Item
              onClick={onStartEdit}
              disabled={!canEditReply(reply.id)}
              testId={`${testId}-moreOptions-edit`}
            >
              <FormattedMessage id="global.edit" />
            </Dropdown.Item>
            <Dropdown.Item
              onClick={handleDeleteReply}
              disabled={!canDeleteReply(reply.id)}
              testId={`${testId}-moreOptions-delete`}
            >
              <FormattedMessage id="global.delete" />
            </Dropdown.Item>
          </Dropdown>
        </div>
      </Card.Header>
      <Card.Body>
        {reply.application === 'docx' && (
          <div className={styles.imported}>
            <FormattedMessage id="IMPORTED_FROM_WORD_DOCUMENT" />
          </div>
        )}
        <ViewRichTextEditor
          initialValue={JSON.stringify(reply.content.content)}
          overwrittenStyles={DEFAULT_CARD_STYLES_PANEL}
          dependencies={[reply.content]}
          testId={testId}
        />
      </Card.Body>
      <Card.Footer size="large">
        <div className={styles.footer}>
          <Tooltip {...likeToggleTooltip}>
            <LikeToggle
              votes={reply.votes ?? []}
              replyId={reply.id}
              target="reply"
              disabled={!canVoteReply(reply.id)}
              onVote={voteReply}
              testId={testId}
              legacyModal={legacyModal}
            />
          </Tooltip>
        </div>
      </Card.Footer>
    </div>
  );
};

export default ViewReplyCard;
