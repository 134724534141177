import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { setSelectedCard, setCreating, setShowNotes } from 'Presentation/PresentationSlice';
import { useDispatch, useIEnvision, useSelector } from '_common/hooks';

const useCards = () => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const pulseData = useSelector((state) => state.onboarding.pulseData);
  const actionsCompleted = useSelector((state) => state.onboarding.actionsCompleted);
  const isIEnvision = useIEnvision();

  const totalPhases = isIEnvision ? 2 : 3;

  const visibleSpace: Record<string, NonNullable<Onboarding.VisibleSpace>[number]> = useMemo(
    () => ({
      toolbar: {
        top: '63px',
        height: `48px`,
        left: '0px',
        width: '100%',
      },
      footer: {
        bottom: '0px',
        height: '48px',
        left: '0px',
        width: '100%',
      },
      all: {
        top: '0px',
        height: '100%',
        left: '0px',
        width: '100%',
      },
    }),
    [],
  );

  const getProgress = (totalPhases: number, phase: number, phasePercent: number) => {
    const progressBarWidth = 344;
    const spacingWidth = 4;

    //Onboarding split progress bars are not all the same size, so static values are used instead
    let firstPhaseWidth;
    let otherPhasesWidth;
    switch (totalPhases) {
      case 4:
        firstPhaseWidth = 86;
        otherPhasesWidth = 82;
        break;
      case 3:
        firstPhaseWidth = 115;
        otherPhasesWidth = 110;
        break;
      case 2:
        firstPhaseWidth = 172;
        otherPhasesWidth = 168;
        break;
      default: //1
        firstPhaseWidth = 344;
        otherPhasesWidth = 0;
        break;
    }

    const previousPhaseFiller =
      phase > 1
        ? firstPhaseWidth + spacingWidth + (phase - 2) * (otherPhasesWidth + spacingWidth)
        : 0;
    const currentPhaseFiller = Math.max(
      ((phase === 1 ? firstPhaseWidth : otherPhasesWidth) * phasePercent) / 100,
      8,
    );

    return ((previousPhaseFiller + currentPhaseFiller) * 100) / progressBarWidth;
  };

  const initialCards = useMemo<Onboarding.ExtendedCard[]>(() => {
    return [
      {
        id: 'beginning',
        currentPhase: 0,
        progress: 2.33,
        interactions: ['presentation_allow_zoom'],
        title: intl.formatMessage({ id: 'ONBOARDING_CARD_EXPLORER_1_HEADER' }),
        description: intl.formatMessage({ id: 'ONBOARDING_STANDALONE_PRESENTATION_STARTED' }),
      },
    ];
  }, []);

  const commentsCards = useMemo<Onboarding.ExtendedCard[]>(() => {
    return [
      {
        id: 'comment_location',
        currentPhase: 1,
        progress: getProgress(totalPhases, 1, 0),
        visibleSpace: [visibleSpace.toolbar],
        boxTarget: ['toolbar'],
        interactions: ['presentation_allow_zoom'],
        description: intl.formatMessage({ id: 'ONBOARDING_PRESENTATION_COMMENTS_LOCATION' }),
      },
      {
        id: 'comment_description',
        currentPhase: 1,
        progress: getProgress(totalPhases, 1, 0),
        visibleSpace: [visibleSpace.toolbar],
        boxTarget: ['toolbar_comment'],
        interactions: ['presentation_allow_zoom'],
        description: intl.formatMessage(
          { id: 'ONBOARDING_PRESENTATION_COMMENTS_DESCRIPTION' },
          { br: <br /> },
        ),
      },
      {
        id: 'comment_create',
        showPhases: false,
        currentPhase: 1,
        subPhase: 1,
        progress: getProgress(totalPhases, 1, 0),
        visibleSpace: [visibleSpace.all],
        pulseTarget: ['toolbar_comment'],
        deps: ['presentation_comments_openComment'],
        interactions: ['presentation_allow_zoom', 'presentation_toolbar_comment'],
        phaseTitle: intl.formatMessage({ id: 'CREATE_A_SLIDE_COMMENT' }),
        description: intl.formatMessage({ id: 'ONBOARDING_PRESENTATION_COMMENTS_CREATE' }),
        before: () => {
          dispatch(setSelectedCard(null));
          dispatch(setCreating(null));
        },
      },
      {
        id: 'comment_save',
        showPhases: false,
        currentPhase: 1,
        subPhase: 1,
        progress: getProgress(totalPhases, 1, 0),
        visibleSpace: [visibleSpace.all],
        deps: ['presentation_comments_createComment'],
        interactions: ['presentation_allow_zoom', 'presentation_comment_save'],
        phaseTitle: intl.formatMessage({ id: 'CREATE_A_SLIDE_COMMENT' }),
        description: intl.formatMessage({ id: 'ONBOARDING_STANDALONE_PRESENTATION_COMMENTS_SAVE' }),
        before: () => {
          const { commentId } = pulseData;

          dispatch(setSelectedCard(commentId ?? null));
        },
      },
      {
        id: 'comment_replyMention',
        showPhases: false,
        currentPhase: 1,
        subPhase: 2,
        progress: getProgress(totalPhases, 1, 50),
        position: pulseData.commentRepliesToggled ? 'bottom-left' : undefined,
        visibleSpace: [visibleSpace.all],
        pulseTarget: ['commentReply'],
        deps: ['presentation_comments_mentionInCommentReply'],
        interactions: ['presentation_allow_zoom', 'presentation_comment_reply'],
        phaseTitle: intl.formatMessage({ id: 'ADD_A_REPLY' }),
        description: intl.formatMessage({ id: 'ONBOARDING_PDF_COMMENTS_REPLY_COMMENT' }),
        tipValue: intl.formatMessage({ id: 'ONBOARDING_PDF_COMMENTS_REPLY_COMMENT_TIP' }),
      },
      {
        id: 'comment_completed',
        currentPhase: 1,
        phaseCompleted: 1,
        subPhase: 2,
        progress: getProgress(totalPhases, 1, 100),
        interactions: ['presentation_allow_zoom'],
        phaseTitle: intl.formatMessage({ id: 'ADD_A_REPLY' }),
        description: intl.formatMessage({ id: 'ONBOARDING_PRESENTATION_COMMENTS_COMPLETED' }),
        before: () => {
          dispatch(setShowNotes(false));
        },
      },
    ];
  }, [pulseData]);

  const tasksCards = useMemo<Onboarding.ExtendedCard[]>(() => {
    return [
      {
        id: 'task_description',
        currentPhase: 2,
        progress: getProgress(totalPhases, 2, 0),
        visibleSpace: [visibleSpace.toolbar],
        boxTarget: ['toolbar_task'],
        interactions: ['presentation_allow_zoom'],
        before: () => {
          dispatch(setSelectedCard(null));
        },
        description: intl.formatMessage({ id: 'ONBOARDING_PRESENTATION_TASKS_DESCRIPTION' }),
      },
      {
        id: 'task_create',
        showPhases: false,
        currentPhase: 2,
        subPhase: 1,
        progress: getProgress(totalPhases, 2, 0),
        visibleSpace: [visibleSpace.all],
        pulseTarget: ['contextMenu_task'],
        deps: ['presentation_tasks_openTask'],
        interactions: !actionsCompleted.presentation_tasks_openTask
          ? [
              'presentation_allow_zoom',
              'presentation_slide_layer',
              'presentation_slide_contextMenu_task',
            ]
          : undefined,
        phaseTitle: intl.formatMessage({ id: 'CREATE_INLINE_TASK' }),
        description: intl.formatMessage({ id: 'ONBOARDING_PRESENTATION_TASKS_CREATE' }),
        before: () => {
          dispatch(setSelectedCard(null));
          dispatch(setCreating(null));
        },
      },
      {
        id: 'task_fillTask',
        showPhases: false,
        currentPhase: 2,
        subPhase: 1,
        position: 'bottom-left',
        progress: getProgress(totalPhases, 2, 0),
        visibleSpace: [visibleSpace.all],
        pulseTarget: ['taskDescription', 'taskAssigned', 'taskDueDate'],
        deps: ['presentation_tasks_createTask'],
        interactions: ['presentation_allow_zoom', 'presentation_task_fill'],
        phaseTitle: intl.formatMessage({ id: 'CREATE_INLINE_TASK' }),
        description: intl.formatMessage({ id: 'ONBOARDING_STANDALONE_PDF_TASKS_FILL' }),
      },
      {
        id: 'task_completed',
        currentPhase: 2,
        subPhase: 1,
        phaseCompleted: 2,
        progress: getProgress(totalPhases, 2, 100),
        interactions: ['presentation_allow_zoom'],
        phaseTitle: intl.formatMessage({ id: 'CREATE_INLINE_TASK' }),
        description: intl.formatMessage({ id: 'ONBOARDING_PRESENTATION_TASKS_COMPLETED' }),
        before: () => {
          dispatch(setShowNotes(false));
        },
      },
    ];
  }, [actionsCompleted]);

  const notesCards = useMemo<Onboarding.ExtendedCard[]>(() => {
    const currentPhase = isIEnvision ? 2 : 3;

    return [
      {
        id: 'note_location',
        currentPhase,
        progress: getProgress(totalPhases, currentPhase, 0),
        visibleSpace: [visibleSpace.footer],
        boxTarget: ['footer'],
        interactions: ['presentation_allow_zoom'],
        onSkip: () => {
          dispatch(setShowNotes(true));
        },
        before: () => {
          dispatch(setShowNotes(true));
          dispatch(setSelectedCard(null));
        },
        description: intl.formatMessage({ id: 'ONBOARDING_PRESENTATION_NOTES_LOCATION' }),
      },
      {
        id: 'note_description',
        currentPhase,
        progress: getProgress(totalPhases, currentPhase, 0),
        visibleSpace: [visibleSpace.footer],
        boxTarget: ['footer_notes'],
        interactions: ['presentation_allow_zoom'],
        onSkip: () => {
          dispatch(setShowNotes(true));
        },
        description: intl.formatMessage(
          { id: 'ONBOARDING_PRESENTATION_NOTES_DESCRIPTION' },
          { br: <br /> },
        ),
      },
      {
        id: 'note_view',
        currentPhase,
        subPhase: 1,
        showPhases: false,
        phaseTitle: intl.formatMessage({ id: 'SEE_NOTES' }),
        progress: getProgress(totalPhases, currentPhase, 0),
        visibleSpace: [visibleSpace.all],
        boxTarget: ['notes_panel'],
        interactions: ['presentation_allow_zoom'],
        onSkip: () => {
          dispatch(setShowNotes(true));
        },
        description: intl.formatMessage(
          { id: 'ONBOARDING_PRESENTATION_NOTES_VIEW' },
          { br: <br /> },
        ),
      },
      {
        id: 'note_completed',
        currentPhase,
        subPhase: 1,
        phaseCompleted: 3,
        phaseTitle: intl.formatMessage({ id: 'SEE_NOTES' }),
        progress: getProgress(totalPhases, currentPhase, 100),
        interactions: ['presentation_allow_zoom'],
        onSkip: () => {
          dispatch(setShowNotes(true));
        },
        description: intl.formatMessage(
          { id: 'ONBOARDING_PRESENTATION_NOTES_COMPLETED' },
          { br: <br /> },
        ),
      },
    ];
  }, []);

  const endingCards = useMemo<Onboarding.ExtendedCard[]>(() => {
    return [
      {
        id: 'ending',
        showPhases: false,
        currentPhase: 0,
        progress: 100,
        interactions: ['presentation_allow_zoom'],
        title: intl.formatMessage({ id: 'AND_THATS_JUST_THE_BEGINNING' }),
        description: intl.formatMessage(
          { id: 'ONBOARDING_PRESENTATION_COMPLETED' },
          { br: <br /> },
        ),
        nextLabel: intl.formatMessage({ id: 'GO_TO_PRESENTATION' }),
      },
    ];
  }, []);

  return [
    ...initialCards,
    ...commentsCards,
    ...(isIEnvision ? [] : tasksCards),
    ...notesCards,
    ...endingCards,
  ];
};

export default useCards;
