import { ReactNode, useEffect, useState } from 'react';
import { ProgressBar, Table as BaseTable } from 'dodoc-design-system';

import { useDispatch, useSelector } from '_common/hooks';
import { setSelected } from '_common/components/Table/TableSlice';

import Footer from './Footer/Footer';

import styles from './Table.module.scss';

import type { TableProps as BaseTableProps } from 'dodoc-design-system/build/types/Components/Table/Table';

export const LOADING_STATE = {
  INITIAL: 'INITIAL',
  ORDER: 'ORDER',
  FILTER: 'FILTER',
};

export type TableProps<Value extends { id: string }[] = { id: string }[]> = {
  // Table identity in order to differenciate content from the other tables
  identity: Table.Identity;
  selectable?: boolean;
  renderEmptyState?: () => ReactNode;
  renderFooter?: () => ReactNode;
  lazyLoading?: { hasNextPage: boolean; offset: number; fetchNextPage: () => void };
  requestParams?: {
    params: Request.FilterParams & Request.OrderParams;
    setParams: (params: Request.FilterParams & Request.OrderParams) => void;
  };
  isLoading: boolean;
  loadingLabel: string;
  virtualized?: boolean;
} & BaseTableProps<Value>;

const Table = <Value extends { id: string }[]>({
  identity,
  selectable = true,
  lazyLoading,
  requestParams,
  renderEmptyState,
  header = true,
  renderFooter = () => <Footer />,
  isLoading,
  loadingLabel,
  virtualized = true,
  ...props
}: TableProps<Value>) => {
  const dispatch = useDispatch();

  const selected = useSelector((state) => state.table.selected);
  const [selection, setSelection] = useState<{ id: string }[]>([]);
  const [anchor, setAnchor] = useState(0);

  useEffect(() => {
    setSelection(
      Object.keys(selected).map((selectedId) => {
        return { id: selectedId };
      }),
    );
  }, [selected]);

  const handleOrderUpdate = (newOrder: Request.OrderParams) => {
    if (props.onOrder) {
      props.onOrder(newOrder);
    } else {
      if (requestParams) {
        requestParams.setParams({ ...requestParams.params, ...newOrder });
      }
    }
  };

  return (
    <div className={styles.root}>
      {isLoading && (lazyLoading?.offset === 0 || !lazyLoading) && (
        <div className={styles.loading}>
          <ProgressBar label={loadingLabel} testId={`table-${identity}-progressBar`} />
        </div>
      )}

      {(!props?.value || props.value.length < 1) && !isLoading && renderEmptyState?.()}

      <div className={styles.content}>
        {(!isLoading || !lazyLoading || lazyLoading.offset > 0) &&
          props?.value &&
          props.value.length > 0 && (
            <BaseTable
              selectionMode={selectable ? 'multiple' : undefined}
              selection={selection}
              {...props}
              onCheckboxClick={
                selectable
                  ? (_, rowData) => {
                      let selected = [];
                      const indexInSelection = selection.findIndex((row) => row.id === rowData.id);
                      const indexOfRow =
                        props.value?.findIndex((row) => row.id === rowData.id) ?? 0;
                      if (indexInSelection === -1) {
                        selected = [...selection, rowData];
                        setAnchor(indexOfRow);
                      } else {
                        const newSelection = [...selection];
                        newSelection.splice(indexInSelection, 1);
                        selected = newSelection;
                        setAnchor(0);
                      }
                      setSelection(selected);
                      const selectedIds: Record<string, boolean> = {};
                      selected.forEach((row) => {
                        selectedIds[row.id] = true;
                      });
                      dispatch(setSelected(selectedIds));
                    }
                  : undefined
              }
              onRowClick={(e) => {
                if (
                  props.value &&
                  selectable &&
                  (props.selectionMode === 'multiple' || props.selectionMode === undefined)
                ) {
                  let newSelection: typeof selection = [];
                  const { originalEvent, data } = e;
                  const indexOfRow = props.value?.findIndex((row) => row.id === data.id) ?? 0;

                  if (originalEvent.shiftKey) {
                    newSelection = newSelection.concat(selection);
                    for (
                      let i = Math.min(anchor, indexOfRow);
                      i <= Math.max(anchor, indexOfRow);
                      i++
                    ) {
                      if (!newSelection.find((row) => row.id === props.value?.[i].id)) {
                        newSelection.push(props.value[i]);
                      }
                    }
                  } else if (originalEvent.ctrlKey) {
                    const indexInSelection = selection.findIndex((row) => row.id === data.id);
                    newSelection = newSelection.concat(selection);

                    if (indexInSelection > -1) {
                      newSelection.splice(indexInSelection, 1);
                    } else {
                      newSelection.push(data);
                    }
                    setAnchor(indexOfRow);
                  } else {
                    newSelection.push(data);
                    setAnchor(indexOfRow);
                  }

                  const selectedIds: Record<string, boolean> = {};
                  newSelection.forEach((row) => {
                    selectedIds[row.id] = true;
                  });
                  dispatch(setSelected(selectedIds));
                  setSelection(newSelection);
                }
                if (props.onRowClick) {
                  props.onRowClick(e);
                }
              }}
              onSelectionChange={
                selectable
                  ? (e) => {
                      const selectedIds: Record<string, boolean> = {};
                      e.value.forEach((row: { id: string }) => {
                        selectedIds[row.id] = true;
                      });

                      if (props.selectionMode === 'multiple' || props.selectionMode === undefined) {
                        if (e.type !== 'row') {
                          setSelection(e.value);
                          dispatch(setSelected(selectedIds));
                        }
                      } else {
                        if (Array.isArray(e.value)) {
                          setSelection(e.value);
                        } else {
                          setSelection([e.value]);
                        }
                        dispatch(setSelected(selectedIds));
                      }
                    }
                  : undefined
              }
              virtualScrollerOptions={
                process.env.NODE_ENV !== 'test' && virtualized
                  ? {
                      onLazyLoad: (e) => {
                        const { last } = e;
                        if (lazyLoading?.hasNextPage && last === props.value?.length) {
                          lazyLoading.fetchNextPage();
                        }
                      },
                      itemSize: 48,
                      lazy: lazyLoading != null,
                      ...props.virtualScrollerOptions,
                    }
                  : undefined
              }
              onOrder={handleOrderUpdate}
              order={
                props.order ??
                (requestParams
                  ? {
                      order_field: requestParams.params.order_field,
                      order_type: requestParams.params.order_type,
                    }
                  : undefined)
              }
            />
          )}
      </div>

      {!isLoading && renderFooter()}
    </div>
  );
};

export default Table;
