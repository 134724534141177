import { cloneDeep } from 'lodash';
import tippy, { Instance } from 'tippy.js';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Intl from 'Intl/Intl';
import { store } from '_common/redux';
import UserCard from '_common/components/UserCard/UserCard';
import ActionContext from 'Editor/services/EditionManager/EditionModes/_Common/models/ActionContext';
import EventsManager from 'Editor/services/EventsManager';
import { ParseMapper } from 'Editor/services/Parsers';
import StylesUtils from 'Editor/services/Styles/Utils/StylesUtils';
import { ELEMENTS, DISPLAY_TYPES, DEFAULT_TABLE_BORDERS } from 'Editor/services/consts';
import { TableCellElement } from '../..';
import { EditorDOMUtils } from 'Editor/services/_Common/DOM';
import DOMElementFactory from 'Editor/services/DOMUtilities/DOMElementFactory/DOMElementFactory';
import { BlockViewModel } from 'Editor/services/VisualizerManager/ViewModels';
import EditorManager from 'Editor/services/EditorManager';
import './TableElement.module.scss';
import { EditorSelectionUtils } from 'Editor/services/_Common/Selection';

type SelectionResult = {
  rows: number[];
  columns: number[];
  fullRows: number[];
  fullColumns: number[];
};

export class TableElement extends HTMLTableElement {
  viewModel?: BlockViewModel;
  Visualizer?: Editor.Visualizer.State;

  static EXTENDS = 'table';
  private isLocked: boolean | string;
  protected tooltipElement?: HTMLElement;
  protected tippyInstance?: Instance | null = null;

  private _styleElement?: HTMLElement;
  private _cellBorders?: Editor.Data.Node.Borders;
  private _cellPaddings?: Editor.Data.Node.Padding;

  private cellSelection: {
    start: TableCellElement | null;
    end: TableCellElement | null;
    previousStart: TableCellElement | null;
    previousEnd: TableCellElement | null;
  };

  private timeoutBuildStyle?: NodeJS.Timeout;
  private timeoutRebuildTableOptions?: NodeJS.Timeout;

  private isMouseDown: boolean = false;

  static get observedAttributes() {
    return ['id', 'data-left-indentation', 'data-width', 'data-background-color', 'lock'];
  }

  constructor() {
    super();

    this.isLocked = false;

    this.cellSelection = {
      previousStart: null,
      previousEnd: null,
      start: null,
      end: null,
    };

    // this.onClickOptionsElement = this.onClickOptionsElement.bind(this);

    this.handleCellMouseDown = this.handleCellMouseDown.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.shortcutInsert = this.shortcutInsert.bind(this);
    this.shortcutRemove = this.shortcutRemove.bind(this);
    this.shortcutSelect = this.shortcutSelect.bind(this);
    this.resize = this.resize.bind(this);
    this.resizeElement = this.resizeElement.bind(this);
  }

  get identifier() {
    return ELEMENTS.TableElement.IDENTIFIER;
  }

  set vm(model: BlockViewModel | undefined) {
    if (model != null) {
      this.viewModel = model;
    }
  }

  get vm() {
    return this.viewModel;
  }

  connectedCallback() {}

  preRender(isBaseElement: boolean = false, pageWidth?: number) {
    this.handleDataAttributes(isBaseElement, pageWidth);
    this.buildStyleElement();
  }

  disconnectedCallback() {
    // if (this.optionsElement) {
    //   this.optionsElement.removeEventListener('click', this.onClickOptionsElement);
    // }

    if (this.tippyInstance) {
      this.tippyInstance.destroy();
      this.tippyInstance = null;
    }

    if (this.timeoutBuildStyle) {
      clearTimeout(this.timeoutBuildStyle);
    }

    if (this.timeoutRebuildTableOptions) {
      clearTimeout(this.timeoutRebuildTableOptions);
    }

    this.removeEventListener('mouseover', this.handleMouseOver);
    EventsManager.getInstance().off('DOCUMENT_MOUSE_UP', this.handleMouseUp);
  }

  attributeChangedCallback(attribute: string, oldValue: string, newValue: string) {
    if (oldValue !== newValue) {
      switch (attribute) {
        case 'id':
          if (oldValue != null) {
            this.scheduleBuildElements();
          }
          break;
        case 'data-left-indentation':
          this.handleLeftIndentation(+newValue);
          break;
        case 'data-width': {
          this.handleTableWidth(!!(this.vm?.id === this.id), this.getPageWidth());
          break;
        }
        case 'data-background-color': {
          this.handleBackgroundColor();
          break;
        }

        case 'lock':
          this.handleLockStatus();
          break;
        default:
          break;
      }
    }
  }

  private getPageWidth() {
    let pageWidth;
    if (this.vm) {
      pageWidth = this.vm.Data?.sections.getPageWidthForBlockId(this.vm.id);
    }

    return pageWidth;
  }

  hasContent() {
    return !EditorDOMUtils.isEmptyElement(this);
  }

  get tag() {
    return this.tagName as Editor.Elements.ElementTagsType;
  }

  hasTasks() {
    return this.hasAttribute('task');
  }

  getTasks() {
    if (this.hasAttribute('task')) {
      return this.getAttribute('task')?.split(',') ?? [];
    } else {
      return [];
    }
  }

  set selectedTask(value: boolean) {
    if (value) {
      this.setAttribute('selectedTask', 'true');
    } else {
      this.removeAttribute('selectedTask');
    }
  }

  selectTask() {
    this.selectedTask = true;
  }

  deselectTask() {
    this.selectedTask = false;
  }

  get displayType() {
    return DISPLAY_TYPES.BLOCK;
  }

  get isInline() {
    return false;
  }

  get isBlock() {
    return true;
  }

  get isEditable() {
    return !this.isLocked;
  }

  get isDeletable() {
    return !this.isLocked;
  }

  get isSplitable() {
    return false;
  }

  get isSelectable() {
    return false;
  }

  get isContainerElement() {
    return false;
  }

  get selectableContent() {
    return null;
  }

  private handleDataAttributes(isBaseElement: boolean = false, pageWidth?: number) {
    if (this.dataset.width) {
      this.handleTableWidth(isBaseElement, pageWidth);
    }

    if (this.dataset.leftIndentation) {
      this.handleLeftIndentation(+this.dataset.leftIndentation);
    }

    this.handleBackgroundColor();
  }

  private handleTableWidth(
    isBaseElement: boolean,
    pageWidth: number | undefined = this.getPageWidth(),
  ) {
    if (this.dataset.width != null) {
      const [type, value] = this.dataset.width.split(',');
      const layoutType = this.Visualizer?.getLayoutType?.() || 'WEB';

      switch (type as Editor.Data.Node.TableWidthTypes) {
        case 'pct':
          if (layoutType === 'WEB' && isBaseElement && pageWidth) {
            this.style.setProperty('--tableWidth', `${pageWidth * +value}pt`);
          } else {
            this.style.setProperty('--tableWidth', `${+value * 100}%`);
          }
          break;
        case 'abs':
          this.style.setProperty('--tableWidth', `${value}pt`);
          break;
        case 'auto':
        case 'nil':
        default:
          this.style.setProperty('--tableWidth', 'auto');
          // if (layoutType === 'WEB' && isBaseElement && pageWidth) {
          //   this.style.maxWidth = `${pageWidth}pt`;
          // }
          break;
      }
    }
  }

  private handleLeftIndentation(value: number) {
    if ((this.dataset.alignment === 'left' || this.dataset.alignment === undefined) && value) {
      let margin = 0;
      if (value > 0) {
        margin = Math.min(StylesUtils.INDENTATION_LIMITS.LEFT.RENDER_MAX, value);
      } else {
        margin = Math.max(StylesUtils.INDENTATION_LIMITS.LEFT.RENDER_MIN, value);
      }

      this.style.marginLeft = `${margin}pt`;
    } else {
      this.style.removeProperty('margin-left');
    }
  }

  private handleBackgroundColor() {
    const bg = this.dataset.backgroundColor;
    if (bg != null && bg !== 'null') {
      if (bg === 'rgba(0, 0, 0, 0)' || bg === 'transparent' || bg === 'false') {
        this.style.backgroundColor = 'rgba(0,0,0,0)';
      } else if (bg.includes('rgb') || bg.includes('#')) {
        this.style.backgroundColor = bg;
      } else {
        this.style.backgroundColor = `#${bg}`;
      }
    } else {
      this.style.backgroundColor = 'var(--suggestionBackgroundColor)';
    }
  }

  private handleLockStatus() {
    const lock = this.getAttribute('lock');

    if (lock != null && lock !== 'false') {
      this.isLocked = true;

      if (typeof lock === 'string' && lock !== 'true') {
        this.tooltipElement = DOMElementFactory.buildElement('div');
        this.tippyInstance = tippy(this, { content: this.tooltipElement, duration: 1 }) as Instance;
        ReactDOM.unmountComponentAtNode(this.tooltipElement);
        ReactDOM.render(
          <Provider store={store}>
            <Intl>
              <UserCard userId={`${lock}`} />
            </Intl>
          </Provider>,
          this.tooltipElement,
        );
      } else if (this.tippyInstance) {
        this.tippyInstance.destroy();
        delete this.tippyInstance;
      }
    } else {
      this.isLocked = false;
      if (this.tippyInstance) {
        this.tippyInstance.destroy();
        delete this.tippyInstance;
      }
    }
  }

  get styleElement() {
    return this._styleElement || (this.querySelector(':scope > style') as HTMLElement);
  }

  set styleElement(styleElement: HTMLElement) {
    const styleElements = this.querySelectorAll(':scope > style');

    let i = 0;
    for (i = 0; i < styleElements.length; i++) {
      this.removeChild(styleElements[i]);
    }

    this._styleElement = styleElement;
    if (this.firstChild) {
      this.insertBefore(styleElement, this.firstChild);
    } else {
      this.appendChild(styleElement);
    }
  }

  // --------------------------------------------------
  //                Style Attributes
  // --------------------------------------------------

  get ALLOWED_STYLE_ATTRIBUTES(): Editor.Styles.Styles[] {
    return StylesUtils.ALLOWED_BLOCK_ATTRIBUTES_BY_ELEMENT[this.tag] || [];
  }

  getStyleAttribute(attribute: Editor.Styles.Styles) {
    if (attribute != null && this.hasStyleAttribute(attribute)) {
      switch (attribute) {
        case StylesUtils.STYLES.VANISH: {
          const result = Array.from((this.tBodies[0] as HTMLTableSectionElement).rows).reduce(
            (resultAttribute: undefined | string, row) => {
              if (resultAttribute == null) {
                resultAttribute = row.dataset[attribute];
              } else if (resultAttribute !== row.dataset[attribute]) {
                resultAttribute = '';
              }
              return resultAttribute;
            },
            undefined,
          );
          return result;
        }
        default:
          break;
      }
    }
    return null;
  }

  getStyleAttributes() {
    const styleAttributes: Editor.Styles.StylesApplied = {};

    let i;
    for (i = 0; i < this.ALLOWED_STYLE_ATTRIBUTES.length; i++) {
      const styleKey = this.ALLOWED_STYLE_ATTRIBUTES[i];
      const attribute = this.getStyleAttribute(styleKey);
      if (attribute != null) {
        styleAttributes[styleKey] = attribute;
      }
    }

    return styleAttributes;
  }

  hasStyleAttribute(attribute: Editor.Styles.Styles) {
    if (attribute != null && this.ALLOWED_STYLE_ATTRIBUTES.includes(attribute)) {
      switch (attribute) {
        case StylesUtils.STYLES.VANISH: {
          const result = Array.from(this.tBodies[0].rows).reduce((hasValue, row) => {
            return hasValue && row.dataset[attribute] != null && row.dataset[attribute] !== 'null';
          }, true);
          return result;
        }
        default:
          break;
      }
    }
    return false;
  }

  removeStyleAttribute(attribute: Editor.Styles.Styles, force: boolean = false) {
    if (attribute != null && (force || this.hasStyleAttribute(attribute))) {
      delete this.dataset[attribute];
      switch (attribute) {
        case StylesUtils.STYLES.VANISH: {
          let i;
          for (i = 0; i < this.tBodies[0].rows.length; i++) {
            delete this.tBodies[0].rows[i].dataset[attribute];
          }
          break;
        }
        default:
          break;
      }
    }
  }

  removeStyleAttributes(attributes = []) {
    if (Array.isArray(attributes)) {
      let i;
      for (i = 0; i < attributes.length; i++) {
        this.removeStyleAttribute(attributes[i]);
      }
    }
  }

  addStyleAttribute(attribute: Editor.Styles.Styles, value: string | boolean | null) {
    if (attribute != null && this.ALLOWED_STYLE_ATTRIBUTES.includes(attribute)) {
      if (value != null) {
        switch (attribute) {
          case StylesUtils.STYLES.VANISH: {
            let i;
            for (i = 0; i < this.tBodies[0].rows.length; i++) {
              this.tBodies[0].rows[i].dataset[attribute] = `${value}`;
            }
            break;
          }
          default:
            break;
        }
      } else {
        this.removeStyleAttribute(attribute, true);
      }
    }
  }

  // --------------------------------------------------
  // --------------------------------------------------
  get tHead() {
    return this.querySelector(':scope > thead') as HTMLTableSectionElement;
  }

  //@ts-expect-error
  get tBodies() {
    return this.querySelectorAll(':scope > tbody') as NodeListOf<HTMLTableSectionElement>;
  }

  get cellPaddings() {
    if (!this._cellPaddings) {
      this._cellPaddings = {};
    }

    return this._cellPaddings;
  }

  set cellPaddings(paddings: Editor.Data.Node.Padding) {
    this._cellPaddings = { ...paddings };
    this.scheduleBuildStyleElement();
  }

  set cellBorders(borders) {
    this._cellBorders = { ...borders };
    this.scheduleBuildStyleElement();
  }

  get cellBorders() {
    if (!this._cellBorders) {
      this._cellBorders = cloneDeep(DEFAULT_TABLE_BORDERS);
    }
    return this._cellBorders;
  }

  getColumnWidths() {
    const columnWidths: Editor.Data.Node.TableWidth[] = [];

    const rows = this.tBodies[0].rows;

    for (let r = 0; r < rows.length; r++) {
      const row = rows[r];

      for (let c = 0; c < row.childNodes.length; c++) {
        const cell = row.childNodes[c] as TableCellElement;

        let cellWidth: Editor.Data.Node.TableWidth;
        if (cell.dataset.width) {
          const [type, value] = cell.dataset.width.split(',');
          cellWidth = {
            t: type as Editor.Data.Node.TableWidthTypes,
            v: +value,
          };
        } else {
          cellWidth = {
            t: 'auto',
            v: 0,
          };
        }

        if (columnWidths[c] != null) {
          if (
            (columnWidths[c].t !== 'abs' && cellWidth.t === 'abs') ||
            (columnWidths[c].t === cellWidth.t && columnWidths[c].v < cellWidth.v)
          ) {
            columnWidths[c].t = cellWidth.t;
            columnWidths[c].v = cellWidth.v;
          }
        } else {
          columnWidths.push(cellWidth);
        }
      }
    }

    return columnWidths;
  }

  /**
   * measures in pixels(px)
   * @returns number[]
   */
  getComputedColumnWidths() {
    const columnWidths: number[] = [];
    const mergeColumnWidths: { [index: number]: number } = {};

    const rows = this.tBodies[0].rows;

    for (let i = 0; i < rows.length; i++) {
      const cells = rows[i].cells;

      for (let c = 0; c < cells.length; c++) {
        let compWidth = 0;

        if (cells[c]) {
          compWidth = cells[c].getBoundingClientRect().width;

          if (compWidth != null) {
            if (cells[c].colSpan === 1) {
              if (columnWidths[c] != null) {
                if (compWidth > columnWidths[c]) {
                  columnWidths[c] = compWidth;
                }
              } else {
                columnWidths.push(compWidth);
              }
            } else if (cells[c].colSpan > 1) {
              const mergedWidth = compWidth / cells[c].colSpan;

              for (let s = 0; s < cells[c].colSpan; s++) {
                if (cells[c + s]) {
                  if (columnWidths[c + s] == null) {
                    columnWidths.push(0);
                  }

                  if (mergeColumnWidths[c + s] != null) {
                    if (mergedWidth > mergeColumnWidths[c + s]) {
                      mergeColumnWidths[c + s] = mergedWidth;
                    }
                  } else {
                    mergeColumnWidths[c + s] = mergedWidth;
                  }
                }
              }
            }
          }
        }
      }
    }

    // handle merged cells
    const indexes = Object.keys(mergeColumnWidths);
    for (let i = 0; i < indexes.length; i++) {
      const index = +indexes[i];

      if (columnWidths[index] === 0) {
        columnWidths[index] = mergeColumnWidths[index];
      }
    }

    return columnWidths;
  }

  /**
   * measures in Points(PT)
   * @returns number[]
   */
  getRowHeights() {
    const rowHeights: number[] = [];
    const rows = this.tBodies[0].rows;

    for (let i = 0; i < rows.length; i++) {
      let datasetRh = rows[i].dataset.rh;
      let height: number = 0;

      if (typeof datasetRh === 'string') {
        const rh = datasetRh.split(','); // row height with -> [type, value]
        height = rh[1] ? +rh[1] : 0;
      } else {
        height = +(rows[i].dataset.rh || 0);
      }

      rowHeights.push(height);
    }

    return rowHeights;
  }

  /**
   * measures in pixels(px)
   * @returns number[]
   */
  getComputedRowHeights() {
    const rowHeights: number[] = [];

    const rows = this.tBodies[0].rows;

    for (let i = 0; i < rows.length; i++) {
      const boundingRect = rows[i].getBoundingClientRect();
      if (boundingRect.height != null) {
        rowHeights.push(boundingRect.height);
      }
    }

    return rowHeights;
  }

  getNumberHeaderRows(): number {
    return this.getHeaderRows().length;
  }

  getHeaderRows(): HTMLTableRowElement[] {
    const headerRows: HTMLTableRowElement[] = [];

    const rows = this.tBodies[0].rows;
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].dataset.hr === 'true') {
        headerRows.push(rows[i]);
      }
    }

    return headerRows;
  }

  private buildCssRule() {
    const id = this.getAttribute('id');
    let cssRule = `${ELEMENTS.TableElement.TAG.toLowerCase()}[id="${id}"] > ${ELEMENTS.TableElement.ELEMENTS.TABLE_BODY.TAG.toLowerCase()} > ${ELEMENTS.TableElement.ELEMENTS.TABLE_ROW.TAG.toLowerCase()} > ${ELEMENTS.TableCellElement.TAG.toLowerCase()} {`;

    if (this.cellBorders) {
      // top border
      if (this.cellBorders.t) {
        if (this.cellBorders.t.w != null) {
          cssRule += `border-top-width: ${EditorDOMUtils.convertUnitTo(
            this.cellBorders.t.w,
            'pt',
            'px',
            3,
          )}px; `;
        }

        if (this.cellBorders.t.s) {
          cssRule += `border-top-style: ${ParseMapper.borderStyleMapper.render(
            this.cellBorders.t.s,
          )}; `;
        }

        if (this.cellBorders.t.c) {
          cssRule += `border-top-color: #${this.cellBorders.t.c}; `;
        }
      }

      // bottom border
      if (this.cellBorders.b) {
        if (this.cellBorders.b.w != null) {
          cssRule += `border-bottom-width: ${EditorDOMUtils.convertUnitTo(
            this.cellBorders.b.w,
            'pt',
            'px',
            3,
          )}px; `;
        }

        if (this.cellBorders.b.s) {
          cssRule += `border-bottom-style: ${ParseMapper.borderStyleMapper.render(
            this.cellBorders.b.s,
          )}; `;
        }

        if (this.cellBorders.b.c) {
          cssRule += `border-bottom-color: #${this.cellBorders.b.c}; `;
        }
      }

      // left border
      if (this.cellBorders.l) {
        if (this.cellBorders.l.w != null) {
          cssRule += `border-left-width: ${EditorDOMUtils.convertUnitTo(
            this.cellBorders.l.w,
            'pt',
            'px',
            3,
          )}px; `;
        }

        if (this.cellBorders.l.s) {
          cssRule += `border-left-style: ${ParseMapper.borderStyleMapper.render(
            this.cellBorders.l.s,
          )}; `;
        }

        if (this.cellBorders.l.c) {
          cssRule += `border-left-color: #${this.cellBorders.l.c}; `;
        }
      }

      // right border
      if (this.cellBorders.r) {
        if (this.cellBorders.r.w != null) {
          cssRule += `border-right-width: ${EditorDOMUtils.convertUnitTo(
            this.cellBorders.r.w,
            'pt',
            'px',
            3,
          )}px; `;
        }

        if (this.cellBorders.r.s) {
          cssRule += `border-right-style: ${ParseMapper.borderStyleMapper.render(
            this.cellBorders.r.s,
          )}; `;
        }

        if (this.cellBorders.r.c) {
          cssRule += `border-right-color: #${this.cellBorders.r.c}; `;
        }
      }
    }

    if (this.cellPaddings) {
      if (this.cellPaddings.t) {
        cssRule += `padding-top: ${EditorDOMUtils.convertUnitTo(
          this.cellPaddings.t,
          'pt',
          'px',
          3,
        )}px;`;
      }
      if (this.cellPaddings.b) {
        cssRule += `padding-bottom: ${EditorDOMUtils.convertUnitTo(
          this.cellPaddings.b,
          'pt',
          'px',
          3,
        )}px;`;
      }
      if (this.cellPaddings.l) {
        cssRule += `padding-left: ${EditorDOMUtils.convertUnitTo(
          this.cellPaddings.l,
          'pt',
          'px',
          3,
        )}px;`;
      }
      if (this.cellPaddings.r) {
        cssRule += `padding-right: ${EditorDOMUtils.convertUnitTo(
          this.cellPaddings.r,
          'pt',
          'px',
          3,
        )}px;`;
      }
    }

    cssRule += '}';

    return cssRule;
  }

  scheduleBuildElements() {
    if (this.timeoutBuildStyle) {
      clearTimeout(this.timeoutBuildStyle);
    }

    this.timeoutBuildStyle = setTimeout(() => {
      this.buildStyleElement();
    }, 0);
  }

  scheduleBuildStyleElement() {
    if (this.timeoutBuildStyle) {
      clearTimeout(this.timeoutBuildStyle);
    }

    this.timeoutBuildStyle = setTimeout(() => {
      this.buildStyleElement();
    }, 0);
  }

  buildStyleElement() {
    if (!this.styleElement || !this.contains(this.styleElement)) {
      const styleElement = document.createElement('style');
      const id = this.getAttribute('id');
      styleElement.type = 'text/css';
      if (id) {
        styleElement.setAttribute('parent_id', id);
      }

      // append child
      this.styleElement = styleElement;
    }

    if (this.styleElement.firstChild) {
      this.styleElement.removeChild(this.styleElement.firstChild);
    }

    const cssRule = this.buildCssRule();

    this.styleElement.appendChild(document.createTextNode(cssRule));
  }

  scheduleRebuildTableOptions() {
    if (this.timeoutRebuildTableOptions) {
      clearTimeout(this.timeoutRebuildTableOptions);
    }

    this.timeoutRebuildTableOptions = setTimeout(() => this.rebuildTableOptions(), 25);
  }

  rebuildTableOptions() {
    const selectionData = this.selectCells(
      this.cellSelection.previousStart,
      this.cellSelection.previousEnd,
    );

    this.buildTableOptions(selectionData);
  }

  buildTableOptions(selectionResult?: SelectionResult | null) {
    this.Visualizer?.widgets?.addWidget('tableOptions', this, {
      selection: {
        columns: selectionResult?.columns || [],
        rows: selectionResult?.rows || [],
        fullySelected: {
          row: selectionResult?.fullRows || [],
          column: selectionResult?.fullColumns || [],
        },
      },
    });

    this.Visualizer?.widgets?.addWidget('resizable', this, {});
  }

  removeTableOptions() {
    this.deselectAllCells();

    this.Visualizer?.widgets?.removeWidget('resizable', this.id);
    this.Visualizer?.widgets?.removeWidget('tableOptions', this.id);
  }

  shortcutInsert(index: number, type: Editor.Elements.Table.Selection) {
    if (type === 'row') {
      EditorManager.getInstance().insertRowAtIndex([index]);
    } else if (type === 'column') {
      EditorManager.getInstance().insertColumnAtIndex([index]);
    }
  }

  shortcutRemove(indexes: number[], type: Editor.Elements.Table.Selection) {
    if (type === 'row') {
      EditorManager.getInstance().deleteRowAtIndex(indexes);
    } else if (type === 'column') {
      EditorManager.getInstance().deleteColumnAtIndex(indexes);
    }
  }

  shortcutSelect(index: number, type: Editor.Elements.Table.Selection) {
    let start: HTMLTableCellElement | undefined;
    let end: HTMLTableCellElement | undefined;

    if (type === 'row') {
      let length = this.tBodies[0].rows[index]?.cells.length;
      start = this.tBodies[0].rows[index]?.cells[0];
      end = this.tBodies[0].rows[index]?.cells[length - 1];
    } else if (type === 'column') {
      let length = this.tBodies[0].rows.length;
      start = this.tBodies[0].rows[0]?.cells[index];
      end = this.tBodies[0].rows[length - 1]?.cells[index];
    }

    if (start instanceof TableCellElement && end instanceof TableCellElement) {
      this.cellSelection.start = start;
      this.cellSelection.end = end;
      const selectionResult = this.selectCells(start, end, true);

      if (selectionResult) {
        this.buildTableOptions(selectionResult);
      }
    }
  }

  resize(
    type: Editor.Elements.Table.Selection,
    measures: { [index: number]: { current: number; delta: number } },
  ) {
    const indexes = Object.keys(measures);

    if (!indexes.length) {
      return;
    }

    if (type === 'row') {
      let heights = this.getRowHeights();

      for (let i = 0; i < indexes.length; i++) {
        const index = +indexes[i];
        const value = EditorDOMUtils.convertUnitTo(measures[index].current, 'px', 'pt', 3);
        if (heights[index] != null && value != null) {
          heights[index] = value;
        }
      }

      if (this.Visualizer?.editionManager) {
        this.Visualizer?.editionManager?.handleUpdateRowHeigths(this, heights);
      }
    } else if (type === 'column') {
      const columnWidths = this.getColumnWidths();

      let tableWidthType: Editor.Data.Node.TableWidthTypes = 'auto';
      if (this.dataset.width != null) {
        const values = this.dataset.width.split(',');
        tableWidthType = values[0] as Editor.Data.Node.TableWidthTypes;
      }

      let changedWidths: {
        [index: number]: { current: Editor.Data.Node.TableWidth; delta: number };
      } = {};

      for (let i = 0; i < indexes.length; i++) {
        const index = +indexes[i];
        const oldWidth = columnWidths[index];

        if (oldWidth.t === 'pct' && tableWidthType !== 'auto' && tableWidthType !== 'nil') {
          const absTableWidth = this.offsetWidth;
          const widthPercent = (measures[index].current * 100) / absTableWidth;

          if (!isNaN(widthPercent)) {
            changedWidths[index] = {
              current: {
                t: 'pct',
                v: +(widthPercent / 100).toFixed(3),
              },
              delta: EditorDOMUtils.convertUnitTo(measures[index].delta, 'px', 'pt', 3) || 0,
            };
          }
        } else {
          const value = EditorDOMUtils.convertUnitTo(measures[index].current, 'px', 'pt', 3);
          if (value != null) {
            changedWidths[index] = {
              current: {
                t: 'abs',
                v: value,
              },
              delta: EditorDOMUtils.convertUnitTo(measures[index].delta, 'px', 'pt', 3) || 0,
            };
          }
        }
      }

      if (this.Visualizer?.editionManager) {
        this.Visualizer.editionManager.handleUpdateColumnWidths(this, changedWidths);
      }
    }
  }

  resizeElement(width: number = 0, height: number = 0) {
    let tableWidthType: Editor.Data.Node.TableWidthTypes = 'auto';
    let tableWidthValue: number = 0;
    if (this.dataset.width != null) {
      const values = this.dataset.width.split(',');
      tableWidthType = values[0] as Editor.Data.Node.TableWidthTypes;
      tableWidthValue = Number(values[1]);
    }

    let w: Editor.Data.Node.TableWidth;

    if (tableWidthType === 'pct' && !isNaN(tableWidthValue)) {
      const oldAbsWidth = this.offsetWidth;
      const widthPercent = (width * tableWidthValue) / oldAbsWidth;

      w = {
        t: 'pct',
        v: widthPercent,
      };
    } else {
      w = {
        t: 'abs',
        v: EditorDOMUtils.convertUnitTo(width, 'px', 'pt', 3),
      };
    }

    let h = EditorDOMUtils.convertUnitTo(height, 'px', 'pt', 3);

    if (this.Visualizer?.editionManager && w != null && h != null) {
      this.Visualizer.editionManager.handleUpdateTableSize(this, h, w);
    }
  }

  // --------------------------------------------------
  //                Paste Stuff
  // --------------------------------------------------
  prepareForPaste(actionContext: ActionContext) {
    if (!this.id) {
      this.id = EditorDOMUtils.generateRandomNodeId();
    }

    const cellBorders = this.getAttribute('cp_cell_borders');
    const cellPaddings = this.getAttribute('cp_cell_paddings');

    if (cellBorders) {
      this.cellBorders = JSON.parse(cellBorders);
      this.removeAttribute('cp_cell_borders');
    }

    if (cellPaddings) {
      this.cellPaddings = JSON.parse(cellPaddings);
      this.removeAttribute('cp_cell_paddings');
    }

    const headCells = this.querySelectorAll('*[head-id-reference]');
    if (headCells.length > 0) {
      headCells.forEach((headCell) => {
        if (!headCell.id) {
          headCell.id = EditorDOMUtils.generateRandomNodeId();
        }

        const reference = headCell.getAttribute('head-id-reference');
        const cells = this.querySelectorAll(`*[head-id="${reference}"]`);
        cells.forEach((cell) => {
          if (!cell.id) {
            cell.id = EditorDOMUtils.generateRandomNodeId();
          }

          cell.setAttribute('head-id', headCell.id);
        });
        headCell.removeAttribute('head-id-reference');
      });
    }
    this.scheduleBuildElements();
    actionContext.addChangeUpdatedNode(this);
  }

  // --------------------------------------------------
  //                Cell Selection
  // --------------------------------------------------
  get cells() {
    const cells: TableCellElement[] = [];
    const rows = this.tBodies[0].rows;

    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      for (let j = 0; j < row.cells.length; j++) {
        cells.push(row.cells[j] as TableCellElement);
      }
    }
    return cells;
  }
  get selectedRows() {
    return this.getSelectedRows();
  }

  getSelectedRows(selectedCells = this.getSelectedCells()) {
    const selectedRows: HTMLTableRowElement[] = [];

    for (let i = 0; i < selectedCells.length; i++) {
      const cell = selectedCells[i];
      const row = cell.parentNode;

      if (row instanceof HTMLTableRowElement && !selectedRows.includes(row)) {
        selectedRows.push(row);
      }
    }

    return selectedRows;
  }

  get selectedCells() {
    return this.getSelectedCells();
  }

  getSelectedCellsInfo(): Editor.Elements.Table.CellInfo[] {
    const cells = [];
    const rows = this.tBodies[0].rows;

    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      for (let j = 0; j < row.cells.length; j++) {
        const cell = row.cells[j];
        if (cell instanceof TableCellElement && cell.isSelected) {
          cells.push(cell.getCellInfo());
        }
      }
    }
    return cells;
  }

  getSelectedCells() {
    const cells = [];
    const rows = this.tBodies[0].rows;

    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      for (let j = 0; j < row.cells.length; j++) {
        const cell = row.cells[j];
        if (cell instanceof TableCellElement && cell.isSelected) {
          cells.push(cell);
        }
      }
    }
    return cells;
  }

  getSelectedCellsIds() {
    const cells: string[] = [];
    const rows = this.tBodies[0].rows;

    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      for (let j = 0; j < row.cells.length; j++) {
        const cell = row.cells[j];
        if (cell instanceof TableCellElement && cell.isSelected) {
          cells.push(cell.id);
        }
      }
    }
    return cells;
  }

  getSelectionInfo() {
    const data: {
      selectedCells: Editor.Elements.Table.CellInfo[];
      fullySelected: boolean;
    } = {
      selectedCells: [],
      fullySelected: true,
    };

    const rows = this.tBodies[0].rows;

    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      for (let j = 0; j < row.cells.length; j++) {
        const cell = row.cells[j];
        if (cell instanceof TableCellElement && cell.getHeadCellId() == null) {
          if (cell.isSelected) {
            data.fullySelected = data.fullySelected && true;
            data.selectedCells.push(cell.getCellInfo());
          } else {
            data.fullySelected = false;
          }
        }
      }
    }
    return data;
  }

  isFullySelected() {
    let fullySelected = false;
    const rows = this.tBodies[0].rows;

    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      for (let j = 0; j < row.cells.length; j++) {
        const cell = row.cells[j];
        if (cell instanceof TableCellElement && cell.getHeadCellId() == null) {
          if (cell.isSelected) {
            fullySelected = true;
          } else {
            return false;
          }
        }
      }
    }
    return fullySelected;
  }

  getCellsPolygonAreas(
    cells: TableCellElement[] = this.getSelectedCells(),
  ): Editor.Elements.Table.CellPolygonArea[] {
    const tbody = this.tBodies[0];
    const tbodyRect = tbody.getBoundingClientRect();

    const cellsVisited: Record<string, boolean> = {};
    const cellAreas: Editor.Elements.Table.CellPolygonArea[] = [];

    for (let c = 0; c < cells.length; c++) {
      let headCell: HTMLElement | null = null;
      const headCellId = cells[c].getHeadCellId();

      if (!headCellId || !cellsVisited[headCellId]) {
        // SELECTION V3
        const workingCell = headCell || cells[c];
        const boundingRect = workingCell.getBoundingClientRect();

        const area: [
          Editor.Elements.Table.Point,
          Editor.Elements.Table.Point,
          Editor.Elements.Table.Point,
          Editor.Elements.Table.Point,
        ] = [
          {
            x: boundingRect.left - tbodyRect.left,
            y: boundingRect.top - tbodyRect.top,
          },
          {
            x: boundingRect.right - tbodyRect.left,
            y: boundingRect.top - tbodyRect.top,
          },
          {
            x: boundingRect.right - tbodyRect.left,
            y: boundingRect.bottom - tbodyRect.top,
          },
          {
            x: boundingRect.left - tbodyRect.left,
            y: boundingRect.bottom - tbodyRect.top,
          },
        ];

        cellAreas.push(area);

        if (headCellId) {
          cellsVisited[headCellId] = true;
        } else {
          cellsVisited[cells[c].id] = true;
        }
      }
    }

    return cellAreas;
  }

  deselectAllCells() {
    const selectedCells = this.getSelectedCells();

    for (let i = 0; i < selectedCells.length; i++) {
      selectedCells[i].deselectCell();
    }
  }

  selectCells(
    start: TableCellElement | null,
    end: TableCellElement | null,
    shortcutSelect: boolean = false,
  ): SelectionResult {
    const selectionData: SelectionResult = {
      rows: [],
      columns: [],
      fullColumns: [],
      fullRows: [],
    };

    if (start == null || end == null) {
      return selectionData;
    }

    if (start !== end /* avoid same cell when is a merged cell*/) {

      EditorSelectionUtils.removeAllRanges();
      const tbody = this.tBodies[0];

      const startRow = start.parentNode;
      const endRow = end.parentNode;

      if (startRow instanceof HTMLTableRowElement && endRow instanceof HTMLTableRowElement) {
        let firstRow, currentRow;

        if (startRow.sectionRowIndex < endRow.sectionRowIndex + end.rowSpan - 1) {
          firstRow = startRow.sectionRowIndex;
          if (shortcutSelect) {
            currentRow = endRow.sectionRowIndex;
          } else {
            currentRow = endRow.sectionRowIndex + end.rowSpan - 1;
          }
        } else {
          if (shortcutSelect) {
            firstRow = startRow.sectionRowIndex;
          } else {
            firstRow = startRow.sectionRowIndex + start.rowSpan - 1;
          }
          currentRow = endRow.sectionRowIndex;
        }

        let firstColumn, currentColumn;

        if (start.cellIndex < end.cellIndex + end.colSpan - 1) {
          firstColumn = start.cellIndex;
          if (shortcutSelect) {
            currentColumn = end.cellIndex;
          } else {
            currentColumn = end.cellIndex + end.colSpan - 1;
          }
        } else {
          if (shortcutSelect) {
            firstColumn = start.cellIndex;
          } else {
            firstColumn = start.cellIndex + start.colSpan - 1;
          }
          currentColumn = end.cellIndex;
        }

        const minRow = Math.min(firstRow, currentRow);
        let maxRow = Math.max(firstRow, currentRow);

        const minColumn = Math.min(firstColumn, currentColumn);
        let maxColumn = Math.max(firstColumn, currentColumn);

        // if (minRow !== maxRow || minColumn !== maxColumn) {

        const cellsPerColumn: { [index: number | string]: number } = {};
        const cellsPerRow: { [index: number | string]: number } = {};

        const rowsLength = tbody.rows.length;
        for (let i = 0; i < rowsLength; i += 1) {
          const cells = tbody.rows[i].cells;
          const cellsLength = tbody.rows[i].cells.length;

          for (let j = 0; j < cellsLength; j += 1) {
            // count cells per row to validate irregular tables
            if (cellsPerRow[i] != null) {
              cellsPerRow[i] = cellsPerRow[i] + 1;
            } else {
              cellsPerRow[i] = 1;
            }

            // count cells per column to validate irregular tables
            if (cellsPerColumn[j] != null) {
              cellsPerColumn[j] = cellsPerColumn[j] + 1;
            } else {
              cellsPerColumn[j] = 1;
            }

            const cell = cells[j];
            if (cell instanceof TableCellElement) {
              if (i >= minRow && i <= maxRow && j >= minColumn && j <= maxColumn) {
                if (!selectionData.rows.includes(i)) {
                  selectionData.rows.push(i);
                }

                if (!selectionData.columns.includes(j)) {
                  selectionData.columns.push(j);
                }

                let headCell: HTMLElement | null = null;

                const headCellId = cell.getHeadCellId();

                // set attribute selected
                cell.selectCell();

                if (headCellId) {
                  headCell = document.getElementById(headCellId);
                  if (headCell instanceof TableCellElement) {
                    headCell.selectCell();
                  }
                }
              } else {
                cell.deselectCell();
              }
            }
          }
        }

        // check fully selected columns
        for (let c = 0; c < selectionData.columns.length; c++) {
          const cellsNum = cellsPerColumn[selectionData.columns[c]];

          if (minRow === 0 && selectionData.rows.length >= cellsNum) {
            selectionData.fullColumns.push(selectionData.columns[c]);
          }
        }

        // check fully selected rows
        for (let r = 0; r < selectionData.rows.length; r++) {
          const cellsNum = cellsPerRow[selectionData.rows[r]];

          if (minColumn === 0 && selectionData.columns.length >= cellsNum) {
            selectionData.fullRows.push(selectionData.rows[r]);
          }
        }

        // // update selection status
        // this.editorContext.visualizerManager?.selection.debounceSelectionChanged();
        this.cellSelection.previousStart = start;
        this.cellSelection.previousEnd = end;
        // } else {
        //   this.deselectAllCells();
        // }
      }
    } else {
      this.deselectAllCells();
      start.selectCell();
    }

    return selectionData;
  }

  selectionChanged(start: TableCellElement | null, end: TableCellElement | null) {
    const selectedCells = this.getSelectedCells();

    let includesStart = false;
    if (start != null && selectedCells.includes(start)) {
      includesStart = true;
    }

    let includesEnd = false;
    if (end != null && selectedCells.includes(end)) {
      includesEnd = true;
    }

    if (!this.isMouseDown && !includesStart && !includesEnd) {
      this.cellSelection.start = start;
      this.cellSelection.end = end;

      const selectionData = this.selectCells(start, end);

      this.buildTableOptions(selectionData);
    }
  }

  handleMouseOver(event: Event) {
    if (event.target instanceof Node) {
      let td = EditorDOMUtils.closest(
        event.target,
        ELEMENTS.TableCellElement.TAG,
      ) as TableCellElement;

      // validation for tables inside tables
      while (td?.parentNode && td.parentNode.parentNode !== this.tBodies[0]) {
        td = EditorDOMUtils.closest(
          td.parentNode,
          ELEMENTS.TableCellElement.TAG,
        ) as TableCellElement;
      }

      this.cellSelection.end = td;

      const selectionData = this.selectCells(this.cellSelection.start, this.cellSelection.end);

      this.buildTableOptions(selectionData);
    }
  }

  handleMouseUp(event: Event) {
    this.tBodies[0].removeEventListener('mouseover', this.handleMouseOver);
    EventsManager.getInstance().off('DOCUMENT_MOUSE_UP', this.handleMouseUp);

    if (event.target instanceof Node) {
      let td = EditorDOMUtils.closest(
        event.target,
        ELEMENTS.TableCellElement.TAG,
      ) as TableCellElement;

      // validation for tables inside tables
      while (td?.parentNode && td.parentNode.parentNode !== this.tBodies[0]) {
        td = EditorDOMUtils.closest(
          td.parentNode,
          ELEMENTS.TableCellElement.TAG,
        ) as TableCellElement;
      }

      this.isMouseDown = false;

      if (td != null) {
        this.cellSelection.end = td;

        const selectionData = this.selectCells(this.cellSelection.start, this.cellSelection.end);

        this.buildTableOptions(selectionData);
      }
    }
  }

  handleCellMouseDown(event: Event, cell: TableCellElement) {
    this.cellSelection.start = cell;
    this.cellSelection.end = null;
    this.cellSelection.previousStart = null;
    this.cellSelection.previousEnd = null;

    this.deselectAllCells();

    cell.selectCell();

    this.isMouseDown = true;

    this.tBodies[0].addEventListener('mouseover', this.handleMouseOver);
    EventsManager.getInstance().on('DOCUMENT_MOUSE_UP', this.handleMouseUp);
  }
}

// register element
if (!window.customElements.get(ELEMENTS.TableElement.IDENTIFIER)) {
  window.customElements.define(ELEMENTS.TableElement.IDENTIFIER, TableElement, {
    extends: TableElement.EXTENDS,
  });
}
