import { useMemo } from 'react';
import { Accordion, DatePicker, RadioButton } from 'dodoc-design-system';
import { FormattedMessage, useIntl } from 'react-intl';

import { useDispatch, useSelector } from '_common/hooks';
import { Logger } from '_common/services';

import { setFilter } from '_common/components/Filters/FilterSlice';
import {
  FilterIdentity,
  FilterName,
  PresetCreationDate,
  TRANSLATIONS,
} from '_common/components/Filters/FilterController';

import { useGetCurrentUserQuery } from '_common/services/api/authority';
import { DATE_FORMATS } from '_common/components/FormattedDate/FormattedDate';

import styles from './PresetDateHandler.module.scss';

const DATE_OPTIONS: (Pick<PresetCreationDate, 'value'> & TranslationMessage)[] = [
  { value: 'last_week', id: 'LAST_WEEK' },
  { value: 'last_month', id: 'LAST_MONTH' },
  { value: '3_month_ago', id: 'NUMBER_MONTH_AGO', values: { number: '3' } },
  { value: '6_month_ago', id: 'NUMBER_MONTH_AGO', values: { number: '6' } },
  { value: 'specific_date_range', id: 'SPECIFIC_DATE_RANGE' },
];

const PresetDateHandler = ({
  identity,
  filter,
  filterQuantity,
  testId,
}: {
  identity: FilterIdentity;
  filter: FilterName;
  filterQuantity: number;
  testId: string;
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const currentValue = useSelector((state) => state.filters[identity]?.presetCreationDate);
  const { data: currentUser } = useGetCurrentUserQuery();

  const dateFormat = useMemo(() => {
    return currentUser?.profile.date_format ?? 'dm';
  }, [currentUser]);

  const datePlaceholder = useMemo(() => {
    return DATE_FORMATS[dateFormat].short.toLowerCase();
  }, [dateFormat]);

  const translatedOptions = useMemo(
    () =>
      DATE_OPTIONS.map((option) => ({
        ...option,
        label: intl.formatMessage({ id: option.id }, option.values),
      })),
    [],
  );

  if (!translatedOptions) {
    Logger.error('[RadioButtonHandler] - No options provided, unable to render filter: ', filter);
    return null;
  }

  const handleToggleValue = (value: PresetCreationDate['value']) => {
    dispatch(
      setFilter({
        identity,
        filter,
        value: {
          value,
          label: translatedOptions.find((option) => option.value === value)?.label ?? '',
        },
      }),
    );
  };

  return (
    <Accordion
      size="medium"
      title={intl.formatMessage({ id: TRANSLATIONS[filter] })}
      initialCollapsed={!currentValue}
      collapsable={filterQuantity > 1}
      testId={`${testId}-accordion`}
    >
      {translatedOptions?.map((option, index) => (
        <RadioButton
          key={`${filter}-${option.value}`}
          checked={currentValue?.value === option.value}
          onChange={() => handleToggleValue(option.value)}
          testId={`${testId}-accordion-${index}`}
          size="x-small"
        >
          {option.label}
        </RadioButton>
      ))}
      <div className={styles.labelContainer}>
        <div
          className={`${styles.label} ${
            !currentValue?.value.includes('specific_date_range') && styles.disabled
          }`}
          style={{ marginRight: '1.5rem' }}
        >
          <FormattedMessage id="START_DATE" />
        </div>
        <div
          className={`${styles.label} ${
            !currentValue?.value.includes('specific_date_range') && styles.disabled
          }`}
        >
          <FormattedMessage id="END_DATE" />
        </div>
      </div>
      <DatePicker
        direction="horizontal"
        onStartChange={(date) => {
          if (!currentValue) {
            return;
          }

          dispatch(
            setFilter({
              identity,
              filter,
              value: {
                value: currentValue.value,
                label:
                  translatedOptions.find((option) => option.value === currentValue.value)?.label ??
                  '',
                dateValue: {
                  startISO: date?.toISOString(),
                  endISO: currentValue.dateValue?.endISO,
                },
              },
            }),
          );
        }}
        onEndChange={(date) => {
          if (!currentValue) {
            return;
          }

          dispatch(
            setFilter({
              identity,
              filter,
              value: {
                value: currentValue.value,
                label:
                  translatedOptions.find((option) => option.value === currentValue.value)?.label ??
                  '',
                dateValue: {
                  startISO: currentValue?.dateValue?.startISO,
                  endISO: date?.toString(),
                },
              },
            }),
          );
        }}
        dateRange
        placeholderStart={datePlaceholder}
        placeholderEnd={datePlaceholder}
        dateFormat={dateFormat}
        testId="date-picker"
        startDate={
          currentValue?.dateValue?.startISO ? new Date(currentValue.dateValue.startISO) : undefined
        }
        endDate={
          currentValue?.dateValue?.endISO ? new Date(currentValue.dateValue.endISO) : undefined
        }
        disabled={!currentValue?.value.includes('specific_date_range')}
      />
    </Accordion>
  );
};

export default PresetDateHandler;
