import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from '_common/hooks';

import { AuthService } from '_common/services';
import { listObjects } from '_common/components/Table/TableSlice';

import Empty from '_common/modals/UserManagement/UserTokensModal/TokenList/Empty/Empty';

import type { EmptyProps } from '_common/modals/UserManagement/UserTokensModal/TokenList/Empty/Empty';

import styles from './TokenList.module.scss';
import { ThunksTable } from '_common/components/Table';
import type { ColumnProps } from 'dodoc-design-system/build/types/Components/Table/Table';
import { FormattedMessage, useIntl } from 'react-intl';
import Cell from '_common/components/Table/Cells/Cell';
import { dayjs } from 'utils';
import { Icon, Tooltip } from 'dodoc-design-system';
import { removeUserActiveSession } from 'Settings/pages/TenantSettingsPage/Users/redux/userManagementSlice';

const IDENTITY: Table.Identity = 'userManagement/tokens';

type TokenListProps = {
  emptyState: { title: EmptyProps['title']['id']; body: EmptyProps['body']['id'] };
  loadingState: { message: string };
  filters: Request.FilterParams;
};

const TokenList = ({ emptyState, loadingState, filters }: TokenListProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const user = useSelector((state) => state.modals.UserTokensModal.user);
  const list = useSelector((state) => state.table.identity['userManagement/tokens'].list);
  const tokens = useSelector((state) => state.userManagement.tokens);
  const selectedTab = useSelector((state) => state.tabMenu.userTokenHistory);

  const handleRemoveSession = (tokenId: string) => {
    dispatch(removeUserActiveSession({ userId: user.id, tokenId }));
  };

  //#region Table object listing handlers
  const fetchObjects = useCallback(
    async (parameters: any) => {
      const response = await new AuthService().getUserTokens(user.id, {
        ...parameters,
        ...filters,
      });

      return {
        ...response,
        data: {
          nodes: response.data,
          dataStorage: 'tenantTokens',
        },
      };
    },
    [filters],
  );

  useEffect(() => {
    dispatch(
      listObjects({
        identity: IDENTITY,
        fetch: fetchObjects,
        cause: 'INITIAL',
        request: { offset: 0 },
      }),
    );
  }, []);

  // No useEffectOnUpdate since fetchObjects doesn't have any dependencies
  // Add if dependencies are added to fetchObjects

  //#endregion

  const renderEmptyState = () => {
    return (
      <div className={`${styles.center} ${styles.empty}`}>
        <Empty title={{ id: emptyState.title }} body={{ id: emptyState.body }} />
      </div>
    );
  };

  const columns = useMemo<ColumnProps[]>(() => {
    return [
      {
        id: 'browser',
        header: intl.formatMessage({ id: 'BROWSER' }),
        orderable: true,
        width: 336,
        flex: true,
      },
      {
        id: 'expires',
        header: intl.formatMessage({ id: 'settings.security.expires' }),
        orderable: true,
        width: 318,
        flex: true,
      },
    ];
  }, []);

  const value = useMemo(() => {
    return list.map((tokenId) => {
      const token = tokens[tokenId];
      return {
        id: token.pk,
        browser: (
          <Cell testId={`${tokenId}-browser-column`} ellipsis>
            {token.browser ?? (
              <div className={styles.unkown}>
                <FormattedMessage id="UNKOWN_BROWSER" />
              </div>
            )}
          </Cell>
        ),
        expires: (
          <Cell testId={`${tokenId}-expires-column`}>
            {dayjs(token.expires).format('DD/MM/YYYY hh:mm A')}
          </Cell>
        ),
        suffix:
          selectedTab === 'activeSession' ? (
            <Cell testId={`${tokenId}-suffix-column`}>
              <Tooltip
                content={intl.formatMessage({
                  id: 'REMOVE_ACTIVE_SESSION',
                })}
                placement="bottom"
                testId={`token-row-${tokenId}-remove-session`}
              >
                <div
                  className={styles.icon}
                  onClick={() => {
                    handleRemoveSession(tokenId);
                  }}
                  data-testid={'remove-session-icon'}
                >
                  <Icon icon="Delete" size={24} />
                </div>
              </Tooltip>
            </Cell>
          ) : undefined,
      };
    });
  }, [list, tokens]);

  return (
    <div className={styles.list}>
      <ThunksTable
        identity={IDENTITY}
        columns={columns}
        value={value}
        fetchObjects={fetchObjects}
        selectable={false}
        renderFooter={() => null}
        renderEmptyState={renderEmptyState}
        loadingLabel={intl.formatMessage({
          id: loadingState.message,
        })}
        testId="tokens"
      />
    </div>
  );
};

export default TokenList;
