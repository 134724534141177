import { useSuiteApp } from '_common/suite/SuiteContext/SuiteContext';
import { PresentationData } from './DataProvider';

const TaskWatchersModalWraper = () => {
  const app = useSuiteApp();
  switch (app) {
    case 'document':
      return null;
    case 'dopdf':
      return null;
    case 'presentation':
      return <PresentationData />;
  }
};

export default TaskWatchersModalWraper;
