import { useEffect, useState, useMemo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Dropdown, EmptyState } from 'dodoc-design-system';

import { navigateToObject } from 'router/history';
import { useDispatch, useSelector } from '_common/hooks';
import { listObjects } from '_common/components/Table/TableSlice';
import { setCurrentAppPage } from 'App/redux/appSlice';
import { toggleInfoPanel } from 'Storage/pages/SpacesListPage/redux/spacesListPageSlice';
import { setActiveTab, setTriggerPage } from 'Search/redux/SearchPageSlice';
import { openAndUpdateModal } from '_common/modals/ModalsSlice';

import { InformationPanel, InteractionController, IntlErrorBoundary } from '_common/components';
import { ThunksTable } from '_common/components/Table';
import Cell from '_common/components/Table/Cells';
import Footer from '_common/components/Table/Footer/Footer';
import SelectionCounter from '_common/components/Table/Footer/SelectionCounter';
import Header from './Header';

import styles from './SpacesListPage.module.scss';

import type {
  ColumnProps,
  TableProps,
} from 'dodoc-design-system/build/types/Components/Table/Table';

import type { paths } from '_types/api';
import { InstanceService } from '_common/services';
import { completeAction } from 'App/redux/onboardingSlice';

export const PAGE_IDENTITY = 'spaces';

/**
 * Spaces Page
 */
const SpacesListPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const appData = useSelector((state) => state.app.data);
  const list = useSelector((state) => state.table.identity.spaces.list);
  const infoPanelOpen = useSelector((state) => state.spaces.infoPanelOpen);
  const loading = useSelector((state) => state.app.loading.isOpen);

  const fetchObjects = useCallback(async (parameters: any) => {
    const res = await new InstanceService().listSpaces(parameters);
    setPermissions(res.data.permissions);
    return res;
  }, []);

  const [permissions, setPermissions] =
    useState<
      paths['/api/object/space/list']['get']['responses']['200']['content']['application/json']['permissions']
    >();

  useEffect(() => {
    dispatch(setCurrentAppPage('/storage/spaces'));
    dispatch(setTriggerPage('spaces'));
    dispatch(setActiveTab('spaces'));
    dispatch(
      listObjects({
        identity: PAGE_IDENTITY,
        fetch: fetchObjects,
        cause: 'INITIAL',
        request: { offset: 0 },
      }),
    );
  }, []);

  const handleOpenDeleteSpaceModal = (object: Objekt) => {
    dispatch(
      openAndUpdateModal({
        modal: 'ConfirmationModal',
        data: {
          title: 'DELETE_ITEM_QUANTITY',
          titleValues: { total: 1 },
          message: 'DELETING_THE_SELECTED_ITEM_QUANTITY_WILL_PERMANENTLY_REMOVE_CONFIRM',
          messageValues: { total: 1 },
          confirmButtonTextId: 'global.delete',
          confirmButtonType: 'danger',
          cancelButtonTextId: 'global.cancel',
          actionCode: 'deleteObjects',
          actionValue: { objectList: [object], identity: 'spaces' },
          headerType: 'error',
          cancelButtonShow: true,
        },
      }),
    );
  };

  const columns = useMemo<ColumnProps[]>(() => {
    return [
      {
        id: 'name',
        header: intl.formatMessage({ id: 'storage.browserHeader.name' }),
        orderable: true,
        width: 296,
        flex: true,
      },
      {
        id: 'time.modification',
        header: intl.formatMessage({ id: 'storage.browserHeader.dateModified' }),
        orderable: true,
        width: 192,
        body: (data) => {
          return data['time.modification'];
        },
      },
      {
        id: 'sharedWith',
        header: intl.formatMessage({ id: 'storage.browserHeader.sharedWith' }),
        width: 160,
      },
    ];
  }, []);

  const value = useMemo(() => {
    return list?.map((objectId) => {
      const object = appData[objectId];
      return {
        id: object.id,
        name: (
          <Cell testId={`${objectId}-name-column`} ellipsis>
            <Cell.ObjectName object={object} testId={`spaces-${object.id}-object-name`} />
          </Cell>
        ),
        'time.modification': (
          <Cell testId={`${objectId}-date-modified-column`}>
            <Cell.DateTime
              date={{ date: object.time.modification }}
              time={{ time: object.time.modification }}
            />
          </Cell>
        ),
        sharedWith: (
          <Cell testId={`${objectId}-shared-column`}>
            <Cell.Collaborators object={object} personalLabel />
          </Cell>
        ),
        suffix: (
          <Cell testId={`${objectId}-suffix-column`}>
            <Cell.Options
              disabled={object.personal}
              items={
                <Dropdown.Item
                  size="large"
                  prefixIcon="Delete"
                  key="delete"
                  onClick={() => {
                    handleOpenDeleteSpaceModal(object);
                  }}
                  testId="delete"
                >
                  <FormattedMessage id="spaces.deleteSpace" />
                </Dropdown.Item>
              }
              testId={`spaces-${object.id}`}
            />
          </Cell>
        ),
      };
    });
  }, [list, appData]);

  const handleCloseInfoPanel = () => {
    dispatch(toggleInfoPanel());
  };

  const handleRowDoubleClick: TableProps<{ id: string }[]>['onRowDoubleClick'] = ({ data }) => {
    navigateToObject('space', data?.id);
    dispatch(completeAction('explorer_open_space'));
  };

  const renderEmptyView = () => {
    if (!loading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
          <EmptyState
            size="large"
            icon="NoSpaces"
            title={intl.formatMessage({ id: 'storage.browserHeader.noSpaces' })}
            testId="spaces-empty-list"
          >
            {''}
          </EmptyState>
        </div>
      );
    }
    return null;
  };

  const renderContentView = () => {
    // TODO: render content loading state
    return (
      <div className={styles.content}>
        <div className={styles.tableWrapper}>
          <InteractionController
            environment="explorer"
            rules={[{ interaction: 'explorer_spaces_space_click' }]}
            style={{ width: '100%', height: '100%' }}
          >
            <ThunksTable
              identity={PAGE_IDENTITY}
              fetchObjects={fetchObjects}
              columns={columns}
              value={value}
              lazyLoading
              loadingLabel={intl.formatMessage({ id: 'LOADING_ELEMENTS' })}
              onRowDoubleClick={handleRowDoubleClick}
              renderEmptyState={renderEmptyView}
              renderFooter={() => (
                <Footer>
                  <SelectionCounter identity={PAGE_IDENTITY} />
                </Footer>
              )}
              testId="spaces"
            />
          </InteractionController>
        </div>

        <InformationPanel
          isOpen={infoPanelOpen}
          onClose={handleCloseInfoPanel}
          testId="spaces-information-panel"
        />
      </div>
    );
  };

  return (
    <div className={styles.root} data-testid="spaces-root">
      <div className={styles.header}>
        <IntlErrorBoundary size="small" margin="0 0 0 7rem">
          <Header permissions={permissions} />
        </IntlErrorBoundary>
      </div>

      {renderContentView()}
    </div>
  );
};

export default SpacesListPage;
