import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from '_common/hooks';
import { Divider, EmptyState, Icon, Tooltip } from 'dodoc-design-system';

import { useEffectOnUpdate } from '_common/hooks';
import { InstanceService, SharedService } from '_common/services';
import { updateModal } from '_common/modals/ModalsSlice';
import Cell from '_common/components/Table/Cells';

import { ThunksTable } from '_common/components/Table';

import Menu from './Menu/Menu';
import Header from './Header';
import { listObjects, singleSelection } from '../../../components/Table/TableSlice';

import styles from './NavigateToDoDOC.module.scss';
import { getDocumentObject } from 'Editor/redux/EditorStatusSlice';
import { IconTypes } from 'dodoc-design-system/build/types/Components/Icon/Icon';
import { ColumnProps, TableProps } from 'dodoc-design-system/build/types/Components/Table/Table';

type NavigateToDoDOCProps = {
  menuOptions: {
    identity?: string;
    label?: string;
    icon: IconTypes['24'];
  }[];
  menuSelected: {
    identity?: string;
    label?: string;
    icon: IconTypes['24'];
  };
  title: string;
};

const NavigateToDoDOC = ({ menuOptions, menuSelected, title }: NavigateToDoDOCProps) => {
  const data = useSelector((state) => state.app.data);
  const intl = useIntl();
  const selected = Object.keys(useSelector((state) => state.table.selected));
  const object = data[selected[0]];
  const current = useSelector((state) => state.modals.CitationsModal.navigation?.current);
  const identity = useSelector((state) => state.modals.CitationsModal.navigation?.identity);
  const dispatch = useDispatch();
  const [parent, setParent] = useState<Objekt | null>(null);
  const advancedFilters = useSelector((state) => state.search.advancedFilters);
  const document = useSelector(getDocumentObject);
  const list = useSelector((state) => state.table.identity[identity as Table.Identity].list);

  //#region Table object listing handlers
  const fetchObjects = useCallback(
    async (parameters: any) => {
      if (identity === 'storage') {
        const instanceService = new InstanceService();
        if (!object && !data[current]) {
          const { data } = await instanceService.getPersonalSpaceInfo();
          dispatch(
            updateModal({
              modal: 'CitationsModal',
              // @ts-expect-error Missing endpoint type "/api/object/space/get"
              data: { navigation: { current: data.id, identity: 'storage' } },
            }),
          );
          // @ts-expect-error Missing endpoint type "/api/object/space/get"
          setParent(data);
          // @ts-expect-error Missing endpoint type "/api/object/space/get"
          const resp = await instanceService.listObject(data.id, data.type, parameters);
          // @ts-expect-error Generic endpoint
          resp.data.nodes.map((node) => {
            if (node.id === document.id) {
              node.citations = document.citations;
              return node;
            } else {
              return node;
            }
          });
          return resp;
        } else {
          const selectedObject = data[current];
          dispatch(
            updateModal({
              modal: 'CitationsModal',
              data: { navigation: { current, identity: 'storage' } },
            }),
          );
          const resp = await instanceService.listObject(
            selectedObject.id,
            selectedObject.type,
            parameters,
          );
          // @ts-expect-error Generic endpoint
          resp.data.nodes.map((node) => {
            if (node.id === document.id) {
              node.citations = document.citations;
              return node;
            } else {
              return node;
            }
          });

          return resp;
        }
      } else if (identity === 'spaces') {
        const res = await new InstanceService().listSpaces(parameters);
        return res;
      } else if (identity === 'shared') {
        const resp = await new SharedService().listShared(parameters);
        // @ts-expect-error Missing endpoint type "/api/object/share/list"
        resp.data.nodes.map((node) => {
          if (node.id === document.id) {
            node.citations = document.citations;
            return node;
          } else {
            return node;
          }
        });
        return resp;
      } else {
        const resp = await new InstanceService().searchObject(
          [],
          parameters,
          advancedFilters.initialFilter?.query,
        );
        // @ts-expect-error Generic endpoint
        resp.data.nodes.map((node) => {
          if (node.id === document.id) {
            node.citations = document.citations;
            return node;
          } else {
            return node;
          }
        });

        return resp;
      }
    },
    [identity, current],
  );

  useEffect(() => {
    dispatch(
      listObjects({
        identity,
        fetch: fetchObjects,
        cause: 'INITIAL',
        request: { offset: 0 },
      }),
    );
  }, []);

  useEffectOnUpdate(() => {
    dispatch(
      listObjects({
        identity,
        fetch: fetchObjects,
        cause: 'FILTER',
        request: { offset: 0 },
      }),
    );
  }, [fetchObjects]);
  //#endregion

  const handleDoubleClick: TableProps<{ id: string }[]>['onRowDoubleClick'] = ({
    data: object,
  }) => {
    const currentObject = data[object.id];

    if (currentObject.status === 'processing' || currentObject.status === 'broken') {
      return;
    }

    let type = currentObject.type;
    let id = currentObject.id;
    switch (type) {
      case 'space':
      case 'folder':
        dispatch(
          updateModal({
            modal: 'CitationsModal',
            data: {
              navigation: { current: id, identity: 'storage' },
            },
          }),
        );
        break;
      default:
        break;
    }
  };

  const handleClick: TableProps<{ id: string }[]>['onRowDoubleClick'] = ({ data: object }) => {
    const isDisabled =
      data[object.id].type !== 'folder' &&
      data[object.id].type !== 'space' &&
      data[object.id].mime?.type !== 'text/xml' &&
      data[object.id].mime?.type !== 'text/plain';
    if (!isDisabled) {
      dispatch(singleSelection({ identity, objectId: object.id }));
    }
  };

  const columns = useMemo<ColumnProps[]>(() => {
    return [
      {
        id: 'name',
        width: 296,
        flex: true,
      },
      {
        id: 'status',
        width: 136,
      },
    ];
  }, []);

  const value = useMemo(() => {
    return list?.map((objectId: string) => {
      const object = data[objectId];
      const isDisabled =
        object.type !== 'folder' &&
        object.type !== 'space' &&
        object.mime?.type !== 'text/xml' &&
        object.mime?.type !== 'text/plain';
      return {
        id: object.id,
        type: object.type,
        object_status: object.status,
        name: (
          <Cell testId={`${objectId}-name-column`} disabled={isDisabled}>
            <Cell.ObjectName object={object} testId={`${objectId}-object-name`} />
          </Cell>
        ),
        status: (
          <Cell testId={`${objectId}-status-column`} disabled={isDisabled}>
            <span className={styles.colStatus}>
              <Cell.ObjectStatus object={object} testId="object-status" />
            </span>
          </Cell>
        ),
        suffix: isDisabled ? (
          <Cell testId={`${objectId}-suffix-column`}>
            <Tooltip
              content={intl.formatMessage({ id: 'INVALID_FILE_FORMAT' })}
              testId={`object-${objectId}-invalid-file-format-tooltip`}
            >
              <Icon icon="InformationBlue" size={16} />
            </Tooltip>
          </Cell>
        ) : null,
      };
    });
  }, [list, data]);

  const renderEmptyView = () => {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
        <EmptyState
          size="large"
          icon="EmptyDocument"
          title={intl.formatMessage({ id: 'storage.browserHeader.spaceEmpty' })}
          testId="empty-folder"
        >
          {intl.formatMessage({ id: 'EMPTY_FOLDER' })}
        </EmptyState>
      </div>
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.title}>{title}</div>
      <div className={styles.container}>
        <Menu list={menuOptions} selected={menuSelected} />
        <Divider vertical />
        <div>
          <Header data={data} current={current} identity={identity} parent={parent} />
          <div className={styles.list}>
            <ThunksTable
              identity={identity}
              onRowDoubleClick={handleDoubleClick}
              onRowClick={handleClick}
              withHeader={false}
              selectable={false}
              columns={columns}
              value={value}
              lazyLoading
              loadingLabel={intl.formatMessage({ id: 'LOADING_ELEMENTS' })}
              renderFooter={() => null}
              renderEmptyState={renderEmptyView}
              fetchObjects={fetchObjects}
              testId="navigate-to-dodoc-table"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavigateToDoDOC;
