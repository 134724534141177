import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ColumnProps } from 'dodoc-design-system/build/types/Components/Table/Table';

import { useSelector } from '_common/hooks';
import { useGetReferenceStylesListQuery } from '../../ReferenceStylesApi';
import { useFilterSelector } from '_common/components/Filters/FilterSlice';

import { EmptyFilteredState } from '_common/components';
import { Table } from '_common/components/Table';
import Cell from '_common/components/Table/Cells';

import RefStyleEmptyState from './RefStyleEmptyState/RefStyleEmptyState';
import ActionsCell from './Cells/ActionsCell/ActionsCell';
import NameCell from './Cells/NameCell/NameCell';

import styles from './RefStyleTable.module.scss';

type RefStyleTableProps = {
  category: Template['category'];
  search: string;
};

const RefStyleTable = ({ category, search }: RefStyleTableProps) => {
  const intl = useIntl();

  const { data, isFetching } = useGetReferenceStylesListQuery();

  const { selectFilterParams } = useFilterSelector();
  const filterParams = useSelector((state) =>
    selectFilterParams(state, state.filters[`referenceStyles-${category}`]),
  );

  const filteredRefStyles = useMemo(() => {
    if (!data) {
      return [];
    }
    let filteredRefStyles = data.order;

    // Filters by name
    if (search !== '') {
      filteredRefStyles = filteredRefStyles.filter((refStyleId) =>
        data.extensions[refStyleId].name.toLowerCase().includes(search.toLowerCase()),
      );
    }

    // Filter by category
    filteredRefStyles = filteredRefStyles.filter(
      (refStyleId) => data.extensions[refStyleId].category === category,
    );

    // Filters by status if any filter is active
    return filterParams.filter_fields.length > 0
      ? filteredRefStyles.filter((refStyleId) =>
          filterParams.filter_fields.includes(data.extensions[refStyleId].status),
        )
      : filteredRefStyles;
  }, [data, search, filterParams]);

  const columns = useMemo<ColumnProps[]>(
    () => [
      {
        id: 'name',
        header: intl.formatMessage({ id: 'global.name' }),
        width: 344,
        flex: true,
        orderable: true,
      },
      {
        id: 'description',
        header: intl.formatMessage({ id: 'global.description' }),
        width: 366,
      },
      {
        id: 'actions',
        width: 198,
      },
    ],
    [],
  );

  const value = useMemo(
    () =>
      data
        ? filteredRefStyles.map((refStyleId) => {
            const refStyle = data.extensions[refStyleId];
            return {
              id: refStyle.id,
              name: (
                <Cell testId={`${refStyleId}-name-column`} ellipsis>
                  <NameCell refStyleId={refStyleId} />
                </Cell>
              ),
              description: (
                <Cell testId={`${refStyleId}-description-column`} ellipsis>
                  {refStyle.description}
                </Cell>
              ),
              actions: (
                <Cell testId={`${refStyleId}-actions-column`}>
                  <ActionsCell refStyleId={refStyleId} />
                </Cell>
              ),
            };
          })
        : [],
    [filteredRefStyles, data],
  );

  if (filteredRefStyles.length === 0) {
    return filterParams.filter_fields.length > 0 ? (
      <EmptyFilteredState identity={`referenceStyles-${category}`} margin="8rem 0" />
    ) : (
      <RefStyleEmptyState />
    );
  }

  return (
    <div className={styles.root}>
      <Table
        identity="refStyles"
        columns={columns}
        value={value}
        renderFooter={() => null}
        isLoading={isFetching}
        loadingLabel={intl.formatMessage({ id: 'LOADING_ELEMENTS' })}
        testId="refStyles"
        selectable={false}
        flex={false}
        withSuffix={false}
        virtualized={false}
      />
    </div>
  );
};

export default RefStyleTable;
